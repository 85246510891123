/**
 * Osm information.
 */

/**
 * Returns the name for a `surface` value.
 * <p>
 * Based on Source https://wiki.openstreetmap.org/wiki/DE:Key:surface.
 *
 * @param {*} surface the value of the OSM way tag with the key `surface`
 * @returns the name
 */
export const surfaceType = (surface) => {
  switch (surface) {
    case 'paved': return 'Versiegelte Oberfläche'
    case 'asphalt': return 'Asphalt'
    case 'chipseal': return 'dünne Bitumen- oder Asphaltschicht'
    case 'concrete': return 'Beton'
    case 'concrete:lanes': return 'Betonspurbahnen'
    case 'concrete:plates': return 'Betonplatten'
    case 'paving_stones': return 'Pflastersteinen'
    case 'sett': return 'behauenes Steinpflaster'
    case 'unhewn_cobblestone': return 'rohes Kopfsteinpflaster'
    case 'cobblestone': return 'Kopfsteinpflaster'
    case 'cobblestone:flattened': return 'Steinpflaster mit abgeflachten Steinen'
    case 'metal': return 'Metall'
    case 'wood': return 'Holz'
    case 'stepping_stones': return 'Trittsteinen'

    case 'unpaved': return 'Ohne Straßenbelag'
    case 'compacted': return 'verdichtete Deckschicht aus Natursteinbruch'
    case 'fine_gravel': return 'fester Splitt'
    case 'gravel': return 'loser Schotter'
    case 'rock': return 'Natursteinweg'
    case 'pebblestone': return 'looser Kies'
    case 'ground': return 'gewachsener, naturbelassener Oberfläche'
    case 'dirt': return 'Unbefestigte Straße'
    case 'earth': return 'unbedeckte Erde'
    case 'grass': return 'Gras/Rasen/Wiese'
    case 'grass_paver': return 'Rasengittersteine'
    case 'mud': return 'Matsch/Morast/Schlamm/Schlick'
    case 'sand': return 'Loser Sand'
    case 'woodchips': return 'Hackschnitzel'
    case 'snow': return 'Schnee'
    case 'ice': return 'Eisstraße'
    case 'salt': return 'Salzseen'

    case 'clay': return 'Sandplatz für Sportplatz'
    case 'tartan': return 'Tartan- oder Kunststoffbelag'
    case 'artificial_turf': return 'Kunstrasen'
    case 'acrylic': return 'Acrylharzoberfläche'
    case 'metal_grid': return 'Metallgitter'
    case 'carpet': return 'Teppich'

    default:
      console.log('Unknown surface value: ' + surface)
      return 'unbekannte Baustoffklasse'
  }
}

/**
     * Returns the name for a `highway` value.
     * <p>
     * Based on Source https://wiki.openstreetmap.org/wiki/DE:Key:highway.
     *
     * @param {*} highway the value of the OSM way tag with the key `highway`
     * @returns the name
     */
export const highwayType = (highway) => {
  switch (highway) {
    case 'motorway': return 'Autobahn'
    case 'trunk': return 'Kraftfahrstraße'
    case 'primary': return 'Bundesstraße'
    case 'secondary': return 'Landes-/Staatsstraße'
    case 'tertiary': return 'Kreisstraße oder Innerstädtische Vorfahrsstraße'
    case 'unclassified': return 'Erschließungsstraße/kleinere Kreisstraße'
    case 'residential': return 'Straße in/an Wohngebiet'

    case 'motorway_link': return 'Autobahn-Zubringer/-anschlussstelle'
    case 'trunk_link': return 'Anschluss-/Verbindungsrampe einer Schnellstraße'
    case 'primary_link': return 'Anschlussstelle an Bundesstraße'
    case 'secondary_link': return 'Auf-/Abfahrt auf/von Landes-/Staats-/gut ausgebauter' +
        'Kreisstraße'
    case 'tertiary_link': return 'Auf-/Abfahrt auf/von einfacher Kreisstraße/gut ausgebauter ' +
        'Gemeindeverbindungsstraße'

    case 'living_street': return 'Verkehrsberuhigter Bereich'
    case 'service': return 'Erschließungsweg'
    case 'pedestrian': return 'Fußgängerzone/Weg oder Platz für Fußgänger'
    case 'track': return 'Wirtschafts-/Feld-/Waldweg'
    case 'bus_guideway': return 'Spurbus-Strecke'
    case 'escape': return 'Notbremsweg'
    case 'raceway': return 'Rennstrecke'
    case 'road': return 'Straße unbekannter Klassifikation'
    case 'busway': return 'Fahrbahn für Schnellbussysteme'

    case 'footway': return 'Gehweg'
    case 'bridleway': return 'Reitweg'
    case 'steps': return 'Treppen auf Fuß-/Wanderweg'
    case 'corridor': return 'Gang im Inneren eines Gebäudes'
    case 'path': return 'Weg/Pfad ohne vorgegebene Nutzungsart für Motorfahrzeuge'

    case 'cycleway': return 'Radweg'

    case 'bus_stop': return 'Bushaltestelle'
    case 'crossing': return 'Fußgängerüberweg'
    case 'elevator': return 'Aufzug'
    case 'emergency_bay': return 'Notrufnische/Nothaltebucht'
    case 'emergency_access_point': return 'Rettungspunkt'
    case 'give_way': return 'Vorfahrt Achten'
    case 'milestone': return 'Kilometrierung von Verkehrswegen'
    case 'mini_roundabout': return 'Mini-Kreisverkehr'
    case 'motorway_junction': return 'Autobahnausfahrt'
    case 'passing_place': return 'Ausweichstelle'
    case 'platform': return 'Bussteig'
    case 'rest_area': return 'Rastplatz'
    case 'speed_camera': return 'Geschwindigkeitsmessanlage'
    case 'street_lamp': return 'Straßenlaterne'
    case 'services': return 'Raststätte'
    case 'stop': return 'Stopp-Schild'
    case 'traffic_mirror': return 'Verkehrsspiegel'
    case 'traffic_signals': return 'Ampel'
    case 'trailhead': return 'Beginn/Ende eines Wanderweges'
    case 'turning_circle': return 'Wendestelle'
    case 'turning_loop': return 'Wendeschleife'
    case 'toll_gantry': return 'Schilderbrücke'
    default:
      console.log('Unknown highway value: ' + highway)
      return 'Unbekannte Straßenklasse'
  }
}
