import normalize from '@mapbox/geojson-normalize'
import { DEFAULT_FILTER } from './sidebar/MapBoxFilters'
import { Sources } from './constants/Sources'
import rightArrowImage from '../resources/icon_arrow_right.png'
import leftArrowImage from '../resources/icon_arrow_left.png'

/**
 * Zoom 10 selected so that segments are still rendered when the viewport is set to the bounding box
 * where a large city like Berlin can be seen as a whole.
 */
export const OVERVIEW_ZOOM_LEVEL = 10

/**
 * Commonly used functions for MapBox API.
 *
 * @author Oguz Oztoprak
 */

/**
   * Adds a symbol layer to the map.
   * The symbols are arrows which show directions.
   *
   * @param h3Data The geojson object of h3 data
   * @returns geojson object that contains only the overview data
   */
export const overviewDataFromH3 = (h3Data) => {
  return {
    type: 'FeatureCollection',
    features: h3Data.features.filter(feature => feature.properties.overview)
  }
}

export const addExportLayer = (map, features) => {
  map.addSource(Sources.Export, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  })

  map.addLayer({
    id: Sources.Export,
    type: 'fill',
    source: Sources.Export,
    layout: {},
    paint: {}
  })

  map.setLayoutProperty(Sources.Export, 'visibility', 'none')
}

export const addDatasetsLayer = (map, features) => {
  map.addSource(Sources.Datasets, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features
    }
  })

  map.addLayer({
    id: Sources.Datasets,
    type: 'fill',
    source: Sources.Datasets,
    layout: {},
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': 0.5
    }
  })

  map.setLayoutProperty(Sources.Datasets, 'visibility', 'visible')
}

/**
   * Adds a symbol layer to the map.
   * The symbols are arrows which show directions.
   *
   * @param map The map object to be updated
   * @param features the GeoJson features to add
   * @param image the image to be used as symbol
   * @param sourceId the id of the source to be added
   */
export const addArrowLayer = (map, features, image, sourceId) => {
  // (!) We do not create a deep copy. This way the segments and arrows use the same feature set.
  // const deepDataCopy = cloneDeep(demoData)

  // Add data sources to arrow-layers
  map.addSource(sourceId, {
    type: 'geojson',
    data: normalize({
      type: 'FeatureCollection',
      features
    })
  })

  // Wait until image is loaded into map
  map.loadImage(image, (error, image) => {
    if (error) {
      throw Error(error)
    }
    // Add image to map
    const imageId = sourceId // the id under which the image should be added to the map
    map.addImage(imageId, image)

    // Add Layer to map
    const layerId = sourceId
    map.addLayer({
      minzoom: OVERVIEW_ZOOM_LEVEL,
      id: layerId,
      type: 'symbol',
      source: sourceId,
      layout: {
        'icon-image': imageId,
        'icon-size': { stops: [[15, 0.0], [24, 3]] },
        'symbol-placement': 'line-center',
        'symbol-avoid-edges': true, // unsure if needed
        visibility: 'none' // imageId === 'arrowLeft' ? 'visible' : 'none'
      },
      paint: {
        'icon-opacity': { stops: [[15, 0], [16, 0.7]] },
        'icon-opacity-transition': { duration: 5, delay: 5 }
      }
    })
  })
}

/**
   * Adds a layer with line-shaped features to the map.
   *
   * @param map The map object to be updated
   * @param demoData the data to add in geoJson format
   */
export const addSegmentLayer = (map, features) => {
  // (!) We do not create a deep copy. This way the segments and arrows use the same feature set.
  // const deepDataCopy = cloneDeep(demoData)

  // Add arrow layers but as "invisible"
  const forwardData = features.filter(feature => feature.properties.forward_moving)
  const backwardData = features.filter(feature => !feature.properties.forward_moving)
  addArrowLayer(map, forwardData, rightArrowImage, Sources.ForwardArrows)
  addArrowLayer(map, backwardData, leftArrowImage, Sources.BackwardArrows)

  // Add segment data to map
  const baseWidth = 0.8
  const baseZoom = 13
  map.addSource(Sources.Segment, {
    type: 'geojson',
    data: normalize({
      type: 'FeatureCollection',
      features
    }),
    // Setting the tolerance ensures the source data does not dissapear on low zoom levels.
    // This disables the Mapbox source "simplification" and can impacts performance badly.
    // We use this workaround until we notice performance problems in hope that by then, clustering
    // is supported for other source types other than points, to avoid having to manage
    // a second source dataset which needs to be generated by client or server [DAT-1314].
    // The value 0.001 was selected as this is the sweet spot where the segment source data
    // does not dissapear on any zoom levels (especially zoomed out to level 10...0).
    tolerance: 0.001
  })

  // Add segment layer to map
  map.addLayer({
    minzoom: OVERVIEW_ZOOM_LEVEL,
    id: Sources.Segment,
    type: 'line',
    source: Sources.Segment,
    layout: { 'line-join': 'round', 'line-cap': 'round' },
    paint: {
      'line-width': {
        type: 'exponential',
        base: 2,
        stops: [
          [0, baseWidth * Math.pow(2, (0 - baseZoom))],
          [24, baseWidth * Math.pow(2, (24 - baseZoom))]
        ]
      },
      'line-color': [
        'case', ['boolean', ['feature-state', 'clicked'], false],
        '#990099',
        [
          'case', ['boolean', ['feature-state', 'hovered'], false],
          '#990099', ['get', 'color'] // get color of Feature
        ]
      ],
      'line-opacity': { stops: [[15, 1], [16, 0.5]] }
      // 'line-offset': 10 - in combination with interpolate maybe for dual
    }
  })
  map.addLayer({
    maxzoom: OVERVIEW_ZOOM_LEVEL,
    id: Sources.Segment + '_overview',
    type: 'circle',
    source: Sources.Segment, // TODO: Rename source to something like
    paint: {
      'circle-color': 'green',
      'circle-radius': [
        'interpolate', ['linear'], ['zoom'], 0, 4.5, 9, 5.5
      ]
    }
  })
  map.setLayoutProperty(Sources.Segment, 'visibility', 'none')
  map.setLayoutProperty(Sources.Segment + '_overview', 'visibility', 'none')

  // Set default filter
  map.setFilter(Sources.Segment, DEFAULT_FILTER)
}

/**
   * Adds a layer with circle-shaped features to the map.
   *
   * @param map The map object to be updated
   * @param data the HTTP response to be converted
   */
export const addPointLayer = (map, data) => {
  map.addSource(Sources.H3, {
    type: 'geojson',
    data,
    tolerance: 0.12,
    // See "adjusting the buffer" https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/
    buffer: 0
  })

  const overviewData = overviewDataFromH3(data)

  // Separete source for overview data
  map.addSource(Sources.H3Overview, {
    type: 'geojson',
    data: overviewData,
    // Setting the tolerance ensures the source data does not dissapear on low zoom levels.
    // This disables the Mapbox source "simplification" and can impacts performance badly.
    // We use this workaround until we notice performance problems in hope that by then, clustering
    // is supported for other source types other than points, to avoid having to manage
    // a second source dataset which needs to be generated by client or server [DAT-1314].
    // The value 0.0005 was selected as this is the sweet spot where the h3 source data
    // does not dissapear on any zoom levels (especially zoomed out to level 10...2).
    // Zoom levels smaller than 2 are not supported as they already show more than
    // half of the world.
    tolerance: 0.0005,
    // See "adjusting the buffer" https://docs.mapbox.com/help/troubleshooting/working-with-large-geojson-data/
    buffer: 0
  })

  map.addLayer({
    minzoom: OVERVIEW_ZOOM_LEVEL,
    id: Sources.H3,
    type: 'fill',
    source: Sources.H3, // reference the data source
    layout: {},
    paint: {
      'fill-color': ['get', 'color'], /* For a color scale: {
        property: 'mean_ninety_percentile',
        stops: [
          [0, colorScale[0]],
          [0.5, colorScale[1]],
          [1, colorScale[2]]
        ]
      } */
      'fill-opacity': 0.5
    }
  })

  map.addLayer({
    maxzoom: OVERVIEW_ZOOM_LEVEL,
    id: Sources.H3 + '_overview',
    type: 'circle',
    source: Sources.H3Overview,
    paint: {
      'circle-color': 'green',
      'circle-radius': [
        'interpolate', ['linear'], ['zoom'], 0, 4.5, 9, 5.5
      ]
    }
  })

  map.setLayoutProperty(Sources.H3, 'visibility', 'none')
  map.setLayoutProperty(Sources.H3 + '_overview', 'visibility', 'none')
}
