import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ControlButton from '../ControlButton'

/**
 * A button which allows the user to log out.
 *
 * @author Armin Schnabel
 */
class LogoutButton extends Component {
  /**
   * The element injected into the container
   */
  render () {
    return (
      <ControlButton
        text="Ausloggen"
        onClick={ this.props.logout }
        active={false}
        icon="power_settings_new"
        tooltip="Abmelden" />
    )
  }
}

/**
 * Validates props' types
 */
LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired

  // Redux injections
}

export default LogoutButton
