import { updateObject } from './utils.js'
import { createSlice } from '@reduxjs/toolkit'
import { Formats, Types } from '../components/constants/ExportConstants'

/**
 * Reducer code specific to the `exportView`.
 *
 * @author Armin Schnabel
 */

/**
 * The current state of this part of the app.
 *
 * e.g. which data, elements and filters are selected.
 */
const initialState = {
  type: Types.Segments,
  format: Formats.Shapefile,

  // The "time" filter selected in "datasetsView"
  time: {
    active: false,
    from: null,
    to: null
  }
}

const exportViewSlicer = createSlice({
  name: 'exportView',
  initialState,
  reducers: {
    updateExportView: (state, action) => {
      return updateObject(state, action.payload)
    }
  }
})

export const { updateExportView } = exportViewSlicer.actions

export default exportViewSlicer.reducer
