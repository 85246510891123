/**
 * Commonly used functions to help with tags.
 *
 * @author Oguz Oztoprak
 */
export const areTagsEqual = (tag1, tag2) => {
  return Object.keys(tag1)[0] === Object.keys(tag2)[0] &&
         Object.values(tag1)[0] === Object.values(tag2)[0]
}

export const areTagKeysEqual = (tag1, tag2) => {
  return Object.keys(tag1)[0] === Object.keys(tag2)[0]
}

export const tagName = (tag) => {
  return Object.keys(tag)[0] + Object.values(tag)[0]
}

export const tagSortCompare = (tag1, tag2) => {
  const comparisonString1 = Object.keys(tag1)[0] + Object.values(tag1)[0]
  const comparisonString2 = Object.keys(tag2)[0] + Object.values(tag2)[0]

  return comparisonString1 < comparisonString2 ? -1 : 1
}
