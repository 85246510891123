import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Icon } from 'react-materialize'
import ControlButton from '../../ControlButton'
import { isStatusOngoing, Status } from '../../JobHelpers.js'
import { States } from '../../../reducers/datasetsView'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { addJobs, selectJobsById } from '../../../reducers/jobs'
import { selectMeasurementsById, addJobsToMeasurements } from '../../../reducers/measurements'
import { createJob } from '../../DataApi.js'

/**
 * The Pipelines which can be passed to the `jobs` endpoint.
 */
export const Pipelines = {
  Surface: 'surface',
  Delete: 'delete',
  MapMatching: 'map-matching'
}

/**
 * The Modes which can be passed to the `jobs` endpoint.
 */
export const Modes = {
  H3: 'h3',
  Segment: 'segment',
  SegmentHistory: 'measurement_based_segment',
  Measurement: 'measurement'
}

/**
 * This component handles the analysis selection.
 *
 * @author Oguz Oztoprak
 */
const DatasetsAnalysis = (props) => {
  // Oberflächenanalyse, either surface or map-matching
  const [pipeline, setPipeline] = useState(null)
  // Ausgabeformat, relevant when pipelineValue is surface. It is either segment or h3.
  const [mode, setMode] = useState(null)

  const state = useSelector(state => state)
  const jobsById = useCallback(id => selectJobsById(state, id), [state])
  const measurementsById = useCallback(id => selectMeasurementsById(state, id), [state])

  const dispatch = useDispatch()

  const { active, selected, setActive, logout, clearSelected } = props

  /**
   * Filters the given selected items array to only process unique jobs,
   * i.e. no two unfinished job with the same pipeline, mode and dataset should
   * be in process at the same time.
   *
   * @param selectedIds The ids of the items to filter.
   * @return The filtered items.
   */
  const processibleItems = useCallback((selectedIds) => {
    const processibleItems = selectedIds.filter(id => {
      const measurement = measurementsById(id)
      const jobs = measurement.jobs.map(jobId => jobsById(jobId))
      const hasRunningJobs = jobs.some(job => {
        const nonfinalStatus = isStatusOngoing(job.status)
        return job.pipeline === pipeline && job.mode === mode && nonfinalStatus
      })
      return !hasRunningJobs
    })
    return processibleItems
  }, [measurementsById, jobsById, pipeline, mode])

  /**
   * A factory that generates a callback function that creates a job given dataset id.
   * Used to generate the callback function when looping over the ids.
   * It can create different callback functions for Modes.SegmentHistory and Modes.Segment.
   *
   * @param mode The mode of the job.
   * @return A function that takes a dataset id, posts job request and returns the job.
   */
  const createJobForID = useCallback((selectedMode) => {
    return async (id) => {
      const measurement = measurementsById(id)
      const response = await createJob(
        dispatch, defaultErrorHandling, logout, measurement, pipeline, selectedMode
      )
      return {
        id: response.jobId,
        deviceId: measurement.deviceId,
        measurementId: parseInt(measurement.measurementId),
        status: Status.Created,
        created: new Date().toISOString(),
        pipeline,
        mode: selectedMode
      }
    }
  }, [measurementsById, pipeline, logout, dispatch])

  /**
   * Sends async requests to create jobs, depending on the pipeline mode.
   *
   * @param ids The ids of the selected measurements.
   * @return An array of 1 or 2 arrays with promises which resolve to the created jobs.
   */
  const sendJobCreationRequests = useCallback(async (ids) => {
    const isSegmentMode = mode === Modes.Segment

    // Return both the segment and segment history jobs.
    if (isSegmentMode) {
      // Resolves to [ [$segmentJobs], [$segmentHistoryJobs] ]
      return await Promise.all([
        Promise.all(ids.map(createJobForID(mode))),
        Promise.all(ids.map(createJobForID(Modes.SegmentHistory)))
      ])
      // Return mode related jobs.
    } else {
      return [await Promise.all(ids.map(createJobForID(mode)))]
    }
  }, [mode, createJobForID])

  /**
   * Send job creation requests to API.
   *
   * @param {*} ids The ids of the items to create jobs for.
   */
  const createJobs = useCallback(async (ids) => {
    try {
      // Add created jobs instead of waiting for job updates for a reactive UIX
      const createdJobsArrays = await sendJobCreationRequests(ids)

      createdJobsArrays.forEach(createdJobs => {
        const createdJobsIds = createdJobs.map(j => j.id)
        dispatch(addJobs(createdJobs))
        dispatch(addJobsToMeasurements(ids, createdJobsIds))
      })
    } catch (error) {
      defaultErrorHandling(error, logout)
    } finally {
      // Leave "dataset analysis" mode where the user can select items to analyse
      setActive(States.ShowDatasets)
    }
  }, [sendJobCreationRequests, dispatch, setActive, logout])

  /**
   * Handler which is called when selected items are sent to be analyzed.
   */
  const onSubmit = useCallback(async () => {
    setActive(States.SubmittingAnalyzeJobs)
    const ids = processibleItems(selected)
    clearSelected()
    await createJobs(ids)
  }, [selected, setActive, clearSelected, createJobs, processibleItems])

  /**
   * Changes the selected pipeline and mode.
   *
   * @param {*} pipeline The pipeline the user selected.
   * @param {*} mode The pipeline the user selected.
   */
  const select = useCallback((pipeline, mode) => {
    setPipeline(pipeline)
    setMode(mode)
    setActive(States.SelectAnalyzeDatasets)
  }, [setActive])

  // These functions below are not defined inline so that they
  // are not redefined at each render and cause child components to
  // also re-render. See RFR-309.
  const onClickSelectSegmentAnalysis = useCallback(() => {
    select(Pipelines.Surface, Modes.Segment)
  }, [select])

  const onClickSelectH3Analysis = useCallback(() => {
    select(Pipelines.Surface, Modes.H3)
  }, [select])

  /**
   * Handler which is called when the analysis selection mode is disabled.
   */
  const onClickCancel = useCallback(() => {
    setActive(States.ShowDatasets)
    clearSelected()
  }, [setActive, clearSelected])

  return (
    <div>
      { /* Choose analysis modes buttons */ }
      { active === States.ShowDatasets || active === States.SelectRemoveDatasets ||
        active === States.DeleteDatasets
        ? (
        <div>
        <label>Straßenqualität analysieren</label><br />
          <ControlButton
            text="Segmente"
            icon="reorder"
            onClick={onClickSelectSegmentAnalysis}
            disabled={active === States.SelectAnalyzeDatasets ||
              active === States.SelectRemoveDatasets || active === States.DeleteDatasets} />
          <ControlButton
            text="Heatmap"
            icon="blur_on"
            onClick={onClickSelectH3Analysis}
            disabled={active === States.SelectAnalyzeDatasets ||
              active === States.SelectRemoveDatasets || active === States.DeleteDatasets} />
        </div>
          )
        : '' }

      { /* Submit/abort datasets selection */ }
      <div style={{
        display: active === States.SelectAnalyzeDatasets ||
          active === States.SubmittingAnalyzeJobs
          ? 'inline'
          : 'none'
      }}>
        <Button
          onClick={() => onSubmit()}
          node="button"
          small
          waves="light"
          style={ { marginRight: '5px', marginBottom: '5px', backgroundColor: '#3F8730' } }
          disabled={ selected.length === 0 || active === States.SubmittingAnalyzeJobs } >
          { (mode === Modes.H3 ? 'Heatmap' : 'Segmente') + ' beauftragen' }
          <Icon style={{ marginRight: '5px' }} left>
            send
          </Icon>
        </Button>
        <Button
          onClick={() => onClickCancel()}
          node="button"
          small
          waves="light"
          style={ { marginRight: '5px', marginBottom: '5px', backgroundColor: '#999999' } }
          disabled={ active === States.SubmittingAnalyzeJobs }
        >Abbrechen</Button>
      </div>
    </div>
  )
}

DatasetsAnalysis.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  clearSelected: PropTypes.func.isRequired
}

export default DatasetsAnalysis
