import React, { Component } from 'react'
import { updateExportView } from '../../../reducers/exportView'
import { Formats, Types } from '../../constants/ExportConstants'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { getLocalStorage, getApiUrl, setLocalStorage, showToast, supportEmail, asyncRefreshTokenIfRequired } from '../../login/utils.js'
import { Views } from '../../constants/Views'
import ControlButton from '../../ControlButton'
import ExportFilter from './ExportFilter'
import { LocalStorage } from '../../constants/LocalStorage'

const STATUS_SUBMITTING = 'SUBMITTING'

/**
 * Control elements shown in the `Views.Export` mode where the user can export data.
 *
 * @author Armin Schnabel
 */
class ExportView extends Component {
  state = {
    status: null
  }

  /**
   * Defines the element injected into the container
   */
  render () {
    const { ui, exportView } = this.props
    const { view } = ui
    const submitting = this.state.status === STATUS_SUBMITTING

    return (
      <Container view={view}>

        {this.props.viewControls}

          <label>Filter</label>
          <ExportFilter
            time={exportView.time}
            setTime={this.setTime}/>

          {this.typeSelection()}

          {this.formatSelection()}

          <label>Aktionen</label><br />
          <ControlButton
            onClick={this.submit}
            text={submitting ? 'Exportiere Daten ...' : 'exportieren'}
            disabled={submitting} />

          <a id="hidden_export_link" style={{ display: 'none' }} />

      </Container>
    )
  }

  typeSelection = () => {
    const { type } = this.props.exportView
    return (
      <div>
        <label>Daten</label><br />
        <ControlButton
          text="Tracks"
          onClick={this.onClickSelectType(Types.Locations)}
          active={type === Types.Locations} />
        <ControlButton
          text="Segmente"
          onClick={this.onClickSelectType(Types.Segments)}
          active={type === Types.Segments} />
      </div>
    )
  }

  formatSelection = () => {
    const { type, format } = this.props.exportView
    return (
      <div>
        <label>Ausgabeformat</label><br />
        <ControlButton
          text="CSV"
          onClick={this.onClickSelectFormat(Formats.Csv)}
          active={format === Formats.Csv}
          visible={type === Types.Locations} />
        <ControlButton
          text="JSON"
          onClick={this.onClickSelectFormat(Formats.Json)}
          active={format === Formats.Json}
          visible={type === Types.Locations} />
        <ControlButton
          text="Shapefile"
          onClick={this.onClickSelectFormat(Formats.Shapefile)}
          active={format === Formats.Shapefile}
          visible={type === Types.Segments} />
      </div>
    )
  }

  selectType = (type) => {
    const { exportView, updateExportView } = this.props
    if (exportView.type === type) {
      return
    }
    if (type === '' || (type !== Types.Locations && type !== Types.Segments)) {
      throw new Error('Unknown type: ' + type)
    }

    updateExportView({ type, format: type === Types.Locations ? Formats.Csv : Formats.Shapefile })
  }

  selectFormat = (format) => {
    const { exportView, updateExportView } = this.props
    if (exportView.format === format) {
      return
    }
    if (format === '' ||
      (format !== Formats.Csv && format !== Formats.Json && format !== Formats.Shapefile)) {
      throw new Error('Unknown format: ' + format)
    }

    updateExportView({ format })
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({ status: STATUS_SUBMITTING })
    const { exportView } = this.props

    const endpoint = exportView.type.toLowerCase()
    const format = exportView.format.toLowerCase()
    const startTime = exportView.time.from
    const endTime = exportView.time.to
    this.downloadCSV(endpoint, format, startTime, endTime)
  }

  downloadCSV = async (endpoint, format, startTime, endTime) => {
    await asyncRefreshTokenIfRequired()
    const accessToken = getLocalStorage(LocalStorage.AccessToken)
    const url = getApiUrl() + 'export/' + endpoint

    const req = new XMLHttpRequest()
    req.open('GET', url, true)
    req.responseType = 'blob'
    req.setRequestHeader('Authorization', 'Bearer ' + accessToken)
    req.setRequestHeader('format', format)

    if (startTime !== null) {
      req.setRequestHeader('start-time', this.localISOString(startTime))
    }
    if (endTime !== null) {
      req.setRequestHeader('end-time', this.localISOString(endTime))
    }

    // From https://stackoverflow.com/a/49674385/5815054
    req.onload = function (event) {
      if (req.readyState === 4) {
        if (req.status !== 200) {
          const status = req.status
          switch (status) {
            case 401:
              showToast('Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')

              // Logout
              localStorage.clear()
              setLocalStorage(LocalStorage.TermsAccepted, true)
              this.props.navigate('/')
              break
            case 403:
              showToast('Fehlende Berechtigung.')
              break
            default:
              showToast('Fehler: ' + status + '. Bitte kontaktieren Sie ' + supportEmail())
          }
          this.setState({ status: null })
          return
        }
      }

      const blob = req.response
      let fileName = null
      const contentType = req.getResponseHeader('content-type')

      // IE/EDGE seems not returning some response header
      if (req.getResponseHeader('content-disposition')) {
        const contentDisposition = req.getResponseHeader('content-disposition')
        fileName = contentDisposition.substring(contentDisposition.indexOf('=') + 1)
      } else {
        fileName = 'unnamed.' + contentType.substring(contentType.indexOf('/') + 1)
      }

      if (window.navigator.msSaveOrOpenBlob) {
        // Internet Explorer
        window.navigator.msSaveOrOpenBlob(new Blob([blob], { type: contentType }), fileName)
      } else {
        const el = document.getElementById('hidden_export_link')
        el.href = window.URL.createObjectURL(blob)
        el.download = fileName
        el.click()
      }
      this.setState({ status: null })
    }.bind(this) /* bind injects the context `this` into the function */
    req.send()

    /* This method does not work for the shapefile export, only a corrupt file is downloaded
    const headers = {
      Authorization: 'Bearer ' + token, // FIXME: use apiSlice to inject fresh token
      format
    }
    if (startTime.length > 0) {
      headers['start-time'] = startTime
    }
    if (endTime.length > 0) {
      headers['end-time'] = endTime
    }
    axios.get(url, { headers })
      .then(res => {
        const blob = new Blob([res.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.style = 'display: none'
        document.body.appendChild(link)
        const url = window.URL.createObjectURL(blob)
        link.href = url

        let fileName = null
        const contentType = res.headers['content-type']
        // IE/EDGE seems not returning some response header
        if (res.headers['content-disposition']) {
          const contentDisposition = res.headers['content-disposition']
          fileName = contentDisposition.substring(contentDisposition.indexOf('=') + 1)
        } else {
          fileName = 'unnamed.' + contentType.substring(contentType.indexOf('/') + 1)
        }
        link.download = fileName

        link.click()
        window.URL.revokeObjectURL(url)
        this.setState({ status: null })
      })
      .catch(err => {
        const errorCode = err.response.status
        switch (errorCode) {
          case 401:
            showToast('Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')

            // Logout
            localStorage.clear()
            setLocalStorage(LocalStorage.TermsAccepted, true) // Avoid having to re-accept terms
            this.props.navigate('/')
            break
          case 403:
            showToast('Fehlende Berechtigung.')
            break
          default:
            showToast('Fehler: ' + errorCode + '. Bitte kontaktieren Sie den Support.')
        }
        this.setState({ status: null })
        return null
      }) */

    return this.getCSV
  }

  localISOString = (timestamp) => {
    const offsetMillis = (new Date()).getTimezoneOffset() * 60_000
    return new Date(timestamp - offsetMillis).toISOString()
  }

  // These functions below are not defined inline so that they
  // are not redefined at each render and cause child components to
  // also re-render. See RFR-309.
  setTime = (time) => {
    const { updateExportView } = this.props
    updateExportView({ time })
  }

  onClickSelectType = (type) => {
    return () => this.selectType(type)
  }

  onClickSelectFormat = (format) => {
    return () => this.selectFormat(format)
  }
}

const Container = styled.div`
  display: ${props => props.view === Views.Export ? 'block' : 'none'};

  height: 100%;
  padding: 0px 10px 0px 10px;
  overflow-y: auto;
`

/**
 * Validates props' types
 */
ExportView.propTypes = {
  viewControls: PropTypes.node.isRequired,
  navigate: PropTypes.func.isRequired,

  // Redux injections
  exportView: PropTypes.object.isRequired,
  updateExportView: PropTypes.func.isRequired,

  ui: PropTypes.object.isRequired
}

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
    exportView: state.exportView,
    ui: state.ui
  }
}

/**
 * Injects functions into the props which update a Redux state.
 *
 * @param {*} dispatch redux store dispatcher which accepts actions and dispatches them
 * to the registered reducer
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateExportView: (update) => { dispatch(updateExportView(update)) }
  }
}

// Workaround until this is refactored to use hooks (https://stackoverflow.com/a/63838801/5815054)
function WithNavigate (props) {
  const navigate = useNavigate()
  return <ExportView {...props} navigate={navigate} />
}

/**
 * Enhance this component with the higher-order-component 'connect' which binds the redux
 * data store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate)
