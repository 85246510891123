import React from 'react'
import { useParams } from 'react-router-dom'

/**
 * Handles routing errors.
 */
const RoutingError = () => {
  const param = useParams()

  /**
   * The element injected into the container
   */
  return (
      <div>
        <p>{`URL /${param.errorRoute} existiert nicht`}</p>
      </div>
  )
}

export default RoutingError
