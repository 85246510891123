import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

/**
 * Reducer code specific to the data returned by the `jobs` endpoint.
 *
 * The domain data is stored in the normalized shape:
 * https://redux.js.org/usage/structuring-reducers/normalizing-state-shape
 *
 * @author Armin Schnabel
 */

const jobsAdapter = createEntityAdapter()
const initialState = jobsAdapter.getInitialState()

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    addJobs: (state, action) => {
      // accepts an array of entities or an object and adds or replaces them
      jobsAdapter.setMany(state, action.payload)
    }
  }
})

export const {
  selectAll: selectAllJobs,
  selectById: selectJobsById
} = jobsAdapter.getSelectors((state) => state.jobs)

export const { addJobs } = jobsSlice.actions
export default jobsSlice.reducer
