import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'react-materialize'

/**
 * To turn different settings on or off.
 *
 * @author Armin Schnabel
 */
class ControlButton extends Component {
  /**
   * Generates the correctly aligned materialized icon element.
   *
   * @param icon the icon name of the materialize icon or {@code undefined}
   * @param text the button text if provided to arrange the icon correctly or {@code undefined}
   * @returns the {@code Icon} element or false if {@param icon} was {@code undefined}
   */
  makeIcon = (icon, text) => {
    if (icon === undefined) {
      return false
    }
    const hasText = text !== undefined
    return hasText ? <Icon left>{icon}</Icon> : <Icon>{icon}</Icon>
  }

  getWidth = (width) => {
    if (typeof (width) === 'undefined') {
      return '' // wrap content
    } else {
      return width
    }
  }

  getMargin = (margin, width) => {
    if (typeof (margin) === 'undefined') {
      return this.getWidth(width) === '100%' ? '0px' : '0px 5px 5px 0px'
    } else {
      return margin
    }
  }

  /**
   * The element injected into the container
   */
  render () {
    const {
      text, icon, onClick, active, disabled, type, width, tooltip, margin, visible,
      backgroundColor = '#3F8730'
    } = this.props
    return visible === undefined || visible
      ? (
      <Button
        style={{
          backgroundColor: active === undefined
            ? backgroundColor
            : active
              ? backgroundColor
              : '#999999',
          color: '#FFFFFF',
          width: this.getWidth(width),
          margin: this.getMargin(margin, width)
        }}
        onClick={onClick}
        disabled={disabled}
        node="button"
        waves="light"
        small
        icon={this.makeIcon(icon, text)}
        type={type}
        tooltip={tooltip}
        tooltipOptions={{ enterDelay: 1000 }}>
        {text}
      </Button>
        )
      : ''
  }
}

/**
 * Validates props' types
 */
ControlButton.propTypes = {
  visible: PropTypes.bool,
  // undefined if no text just an icon is wanted
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string, // undefined if no icon is wanted
  onClick: PropTypes.func, // undefined for dummy buttons
  disabled: PropTypes.bool,
  type: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  active: PropTypes.bool, // undefined for buttons who cannot be "active"
  tooltip: PropTypes.string,
  backgroundColor: PropTypes.string
}

export default ControlButton
