import { isProductionEnvironment, debug, showToast, supportEmail } from './login/utils.js'
import * as Sentry from '@sentry/react'

/**
 * Commonly used functions helping with job execution process.
 *
 * @author Armin Schnabel
 * @author Oguz Oztoprak
 */

/**
  * Default handler for unsuccessful HTTP responses.
  *
  * @param {object} error The error to be handled.
  */
export const defaultErrorHandling = async (error, logout) => {
  if (isProductionEnvironment()) {
    Sentry.captureException(error)
  }
  if (debug()) console.log(error)

  // Unauthorized
  if (error.response !== undefined && error.response.status === 401) {
    showToast('Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')
    logout()
  } else {
    const details = error.response !== undefined ? error.response.status : 'undefined'
    showToast('Daten konnten nicht geladen werden (Fehler ' + details +
      ').<br />Bitte kontaktieren Sie ' + supportEmail() + ' falls der Fehler wiederholt auftritt.')
  }
}

/**
  * Default handler for final Websocket events like close or error.
  *
  * @param {object} error The error to be handled.
  */
export const defaultWebsocketErrorHandling = async (statusCode, logout, softErrorHandler) => {
  if (isProductionEnvironment()) {
    Sentry.captureMessage('Websocket error: ' + statusCode)
  }
  if (debug()) console.log('Websocket error, code: ' + statusCode)

  // Unauthorized
  if (statusCode === 3000) {
    showToast('Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')
    logout()
  } else {
    showToast('Daten konnten nicht empfangen werden (Fehler ' + statusCode +
      ').<br />Bitte kontaktieren Sie ' + supportEmail() + ' falls der Fehler wiederholt auftritt.')
    softErrorHandler()
  }
}
