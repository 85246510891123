import { updateObject } from './utils.js'
import { Directions } from '../components/constants/Directions'
import { Views } from '../components/constants/Views'
import { Sources } from '../components/constants/Sources'
import { createSlice } from '@reduxjs/toolkit'
import { FILTER_MODALITY, FILTER_FORWARD, FILTER_BACKWARD } from '../components/sidebar/MapBoxFilters'

/**
 * Reducer code specific to the ui state.
 *
 * The states in this class all influence the data shown in the Mapbox map.
 *
 * @author Armin Schnabel
 */

/**
 * The current state of the UI.
 */
const initialState = {
  // Sidebar visibility (when in mobile view)
  sidebar: false,

  embedded: false,

  // The currently active view
  view: Views.Datasets,

  // The UI state of the Mapbox map, i.e. which layer is currently shown.
  visibleLayerId: Sources.Datasets,

  // The UI state of the Mapbox map, i.e. which feature is currently selected by the user.
  selectedFeature: {
    id: null, // road segment selected (via click) on the map
    layerId: null, // The layer which contains the selected feature
    sourceId: null // The source data which contains the selected feature
  },

  // Indicates which segment direction is shown. Start with Unknown when no arrows are shown
  // in the beginning.
  direction: Directions.Unknown
}

/**
 * Handler for an entire slices of a state ("slice reducers")
 *
 * Sets the initial state & connects the actions with the reducer functions.
 */

const uiSlicer = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateUi: (state, action) => {
      if (action.payload.direction !== undefined) {
        throw new Error('Invalid argument: direction. Use setVisibleLayerId action!')
      }
      if (action.payload.visibleLayerId !== undefined) {
        throw new Error('Invalid argument: visibleLayerId. Use setVisibleLayerId action!')
      }
      return updateObject(state, action.payload)
    },
    setVisibleLayerId: {
      reducer: (state, action) => {
        return updateObject(state, action.payload)
      },
      prepare: (
        map, visibleLayerId, previousLayerId, direction, previousDirection, modality) => {
        if (previousLayerId === undefined || previousDirection === undefined ||
            map === undefined ||
            modality === undefined) {
          throw Error('A property is undefined')
        }

        if (previousLayerId !== visibleLayerId) {
          // Hide currently shown layers
          if (previousLayerId !== null) {
            map.setLayoutProperty(previousLayerId, 'visibility', 'none')
            // Hide infrastructure's overview layers
            if (previousLayerId === Sources.H3 || previousLayerId === Sources.Segment) {
              map.setLayoutProperty(previousLayerId + '_overview', 'visibility', 'none')
            }
          }

          // Show new layers
          map.setLayoutProperty(visibleLayerId, 'visibility', 'visible')
          // Show infrastructure's overview layers
          if (visibleLayerId === Sources.H3 || visibleLayerId === Sources.Segment) {
            map.setLayoutProperty(visibleLayerId + '_overview', 'visibility', 'visible')
          }
        }

        // Hide previous arrow layer
        // TODO: arrow layers should be one layer and use the same filters as
        // the qualitySegements layer
        if (previousLayerId === Sources.Segment && previousDirection !== Directions.Unknown) {
          const arrowLayer = previousDirection === Directions.Forward
            ? Sources.ForwardArrows
            : Sources.BackwardArrows
          map.setLayoutProperty(arrowLayer, 'visibility', 'none')
        }

        // Show new arrow layer
        if (visibleLayerId === Sources.Segment) {
          const arrowLayer = direction === Directions.Forward
            ? Sources.ForwardArrows
            : Sources.BackwardArrows
          map.setLayoutProperty(arrowLayer, 'visibility', 'visible')

          // Update filter
          const MODALITY_FILTER = FILTER_MODALITY(modality)
          const COMBINED_FILTER = direction === Directions.Forward
            ? ['all', MODALITY_FILTER, FILTER_FORWARD]
            : ['all', MODALITY_FILTER, FILTER_BACKWARD]

          map.setFilter(Sources.Segment, COMBINED_FILTER)
          map.setFilter(Sources.Segment + '_overview', COMBINED_FILTER)
          map.setFilter(arrowLayer, MODALITY_FILTER)
        }

        // Update redux state
        return {
          payload: { visibleLayerId, direction }
        }
      }
    }

  }
})

export const { updateUi, setVisibleLayerId } = uiSlicer.actions

export default uiSlicer.reducer
