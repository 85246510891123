// import React from 'react'
import PropTypes from 'prop-types'
/* import { Badge } from 'react-materialize'
import { useSelector } from 'react-redux' */

/**
 * This function component displays a badge showing number of new, unseen jobs.
 *
 * @author Oguz Oztoprak
 */
const UnviewedJobsBadge = ({ countFunction, left }) => {
  // Get jobs from redux store.
  // const jobs = useSelector((state) => state.jobs.byId)
  // const count = countFunction(jobs)
  return ''/* count
    ? <Badge
      style={{
        fontSize: '0.7em',
        position: 'absolute',
        zIndex: '3',
        minWidth: 'auto',
        borderRadius: '50%',
        color: 'white',
        bottom: '100%',
        left,
        lineHeight: '20px',
        height: '20px'
      }}
      className="red">
      {count}
    </Badge>
    : '' */
}

/**
 * Validates props' types
 */
UnviewedJobsBadge.propTypes = {
  countFunction: PropTypes.func.isRequired,
  left: PropTypes.string.isRequired
}
export default UnviewedJobsBadge
