import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TermsOfUse from './login/TermsOfUse'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { updateUi } from '../reducers/ui'
import { setLocalStorage, showToast, termsAccepted, asyncFetchOAuthConfiguration } from './login/utils.js'
import { defaultErrorHandling } from './ErrorHandlingHelpers'
import { LocalStorage } from './constants/LocalStorage'

/**
 * This component manages the behaviour when the web-app used in an embedded context via
 * ..cyface.de/embedded?refreshToken=...&username=... URL
 *
 * @author Oguz Oztoprak
 */
const Embedded = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const refreshToken = searchParams.get(LocalStorage.RefreshToken)
  const username = searchParams.get(LocalStorage.Username)
  const origin = searchParams.get(LocalStorage.Origin)
  const dispatch = useDispatch()

  /**
   * Fetches the OAuth Configuration and forwards the user to the dashboard.
   */
  const fetchAuthConfigAndForward = useCallback(async () => {
    try {
      // This is also in this method to fetch the config in both, termsHandling and useEffect
      await asyncFetchOAuthConfiguration()

      dispatch(updateUi({ embedded: true }))
      navigate('/map')
    } catch (error) {
      console.log(error)
      defaultErrorHandling(error, () => {
        throw new Error('Fehler bei der Autorisierungsanfrage')
      })
    }
  }, [dispatch, navigate])

  /**
   * Handles the event when the user accepts or denies the terms of use.
   */
  const termsHandling = useCallback((termsAccepted, errorTrackingAccepted) => {
    if (termsAccepted) {
      setLocalStorage(LocalStorage.TermsAccepted, termsAccepted)
      setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)

      setLocalStorage(LocalStorage.RefreshToken, refreshToken)
      setLocalStorage(LocalStorage.Username, username)
      setLocalStorage(LocalStorage.Origin, origin)
      fetchAuthConfigAndForward()
    } else {
      showToast('Um diesen Service zu nutzen müssen Sie die die Datenschutzerklärung akzeptieren.')
    }
  }, [fetchAuthConfigAndForward, refreshToken, username, origin])

  /**
   * Handles the event when the refresh token is set.
   */
  useEffect(() => {
    if (refreshToken && username && termsAccepted()) {
      setLocalStorage(LocalStorage.RefreshToken, refreshToken)
      setLocalStorage(LocalStorage.Username, username)
      fetchAuthConfigAndForward()
    }
  }, [fetchAuthConfigAndForward, refreshToken, username])

  return refreshToken && username
    ? !termsAccepted()
        ? <TermsOfUse handleTerms={termsHandling} />
        : <p>{'Redirecting'}</p>
    : <p>{'URL existiert nicht'}</p>
}

export default Embedded
