import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TimeFilter from '../TimeFilter.js'
import ControlButton from '../../ControlButton.js'

const UNIQUE_PREFIX = 'exportFilter'

/**
 * Displays the filters in the ExportView.
 *
 * @author Oguz Oztoprak
 */
class ExportFilter extends Component {
  state = {
    // The currently selected from- and to-date
    fromDate: null, // none selected
    toDate: null // none selected
  }

  setFromDate = (fromDate) => this.setState({ fromDate })
  setToDate = (toDate) => this.setState({ toDate })

  render () {
    const { time } = this.props

    return (
      <div>
        <TimeFilter
          uniquePrefix={UNIQUE_PREFIX}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          setFromDate={this.setFromDate}
          setToDate={this.setToDate}
          onClickClear={this.onClearFilters}
          time={this.props.time}
          disabled={false}
          setTime={this.props.setTime} />

        <ControlButton
          icon={'filter_list_off'}
          onClick={this.onClearFilters}
          disabled={!time.active}
          tooltip='Filter zurücksetzen' />
      </div>
    )
  }

  onClearFilters = () => {
    this.onTimeFilterClear()
  }

  /**
   * Clears the time frame.
   *
   * @param e the triggered event from this handler.
   */
  onTimeFilterClear = (e) => {
    // Update internal state
    this.setState({
      fromDate: null,
      toDate: null
    })

    // Update UI manually
    document.getElementById(UNIQUE_PREFIX + 'FromDate').value = ''
    document.getElementById(UNIQUE_PREFIX + 'ToDate').value = ''

    // Update global state
    this.props.setTime({
      active: false,
      to: null,
      from: null
    })
  }
}

/**
 * Validates props' types
 */
ExportFilter.propTypes = {
  time: PropTypes.object.isRequired,
  setTime: PropTypes.func.isRequired
}

export default ExportFilter
