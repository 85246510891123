import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Views } from '../constants/Views'

/**
 * DetailsBox is a generic component to represent a box where
 * children components handles the logic to show dataset or segment details inside the box.
 *
 * @author Oguz Oztoprak
 */

const selectedDatasetId = (selected) => {
  return selected[0] !== undefined ? selected[0] : null
}

const DetailsBox = ({ mobileView, children, ui, selected }) => {
  const { view, selectedFeature } = ui
  const selectedId = view === Views.Infrastructure
    ? selectedFeature.id
    : view === Views.Datasets ? selectedDatasetId(selected) : null

  return <Container width={mobileView ? 'calc(100vw - 20px)' : 'calc((100vw - 400px) - 20px)'}
                    right={'10px'}
                    selectedId={selectedId} >
        {children}
    </Container>
}

/**
 * The style of the wrapper element which is a box at the bottom of the map.
 */
const Container = styled.div`
  position:fixed; // relative to the viewport
  bottom: 30px;
  right: ${props => props.right};
  z-index: 4;// one above Legend (3)

  width: ${props => props.width};

  transition: visibility 0s, opacity 0.05s linear;
  visibility: ${props => props.selectedId === null ? 'hidden' : 'visible'};
  opacity: ${props => props.selectedId === null ? '0' : '1'};

  background-color: #FFFFFF;

  border:solid;
  border-width:1px;
  border-color:lightgrey;

  padding: 0px;
  font-size: small;
  a {
    color: green !important;
  }
`

/**
 * Validates props' types
 */
DetailsBox.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  children: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired
}

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    selected: state.datasetsView.selected
  }
}

/**
 * Injects functions into the props which update a Redux state.
 *
 * @param {*} dispatch redux store dispatcher which accepts actions and dispatches them to the
 * registered reducer
 */
const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsBox)
