/**
 * Colors for classified points or segments.
 */
export const Colors = {
  BLUE: '#0055ff',
  GREEN: '#00ff59', // 4 categories
  // const BLUE_GREEN = '#00AA55' // 5 categories
  // const YELLOW_GREEN = '#90CC0C' // 5 categories
  YELLOW: '#FFDC00',
  RED: '#ff2600'
}
