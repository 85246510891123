import { addToArray } from './utils.js'
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

/**
 * Reducer code specific to the data returned by the `measurements` endpoint.
 *
 * The domain data is stored in the normalized shape:
 * https://redux.js.org/usage/structuring-reducers/normalizing-state-shape
 *
 * @author Armin Schnabel
 */

const measurementsAdapter = createEntityAdapter()
const initialState = measurementsAdapter.getInitialState()

const measurementsSlice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {
    setMeasurements: (state, action) => {
      // accepts an array of entities or an object and adds or replaces them
      measurementsAdapter.setMany(state, action.payload)
    },
    removeMeasurement: (state, action) => {
      measurementsAdapter.removeOne(state, action.payload)
    },
    addJobsToMeasurements: {
      reducer: (state, action) => {
        const { measurementIds, jobIds } = action.payload
        const updatedEntries = []
        measurementIds.forEach(measurementid => {
          // It's possible that jobs exist for already deleted measurements
          if (state.entities[measurementid] === undefined) {
            return
          }

          // Find position in measurementIds (which equals position in jobIds parameter)
          const index = measurementIds.findIndex(id => id === measurementid)
          updatedEntries.push({
            ...state.entities[measurementid],
            jobs: addToArray(state.entities[measurementid].jobs, jobIds[index])
          })
        })
        measurementsAdapter.setMany(state, updatedEntries)
      },
      // needed to allow the reducer to accept multiple payload parameters
      // https://stackoverflow.com/questions/71778940/redux-toolkit-reducer-with-multiple-parameters-redux-toolkit
      prepare: (measurementIds, jobIds) => {
        return {
          payload: { measurementIds, jobIds }
        }
      }
    },
    setTagsToMeasurements: {
      reducer: (state, action) => {
        const { measurementIds, tags } = action.payload
        const updatedEntries = []
        measurementIds.forEach(measurementid => {
          if (state.entities[measurementid] === undefined) {
            return
          }

          updatedEntries.push({
            ...state.entities[measurementid],
            tags
          })
        })
        measurementsAdapter.setMany(state, updatedEntries)
      },
      // needed to allow the reducer to accept multiple payload parameters
      // https://stackoverflow.com/questions/71778940/redux-toolkit-reducer-with-multiple-parameters-redux-toolkit
      prepare: (measurementIds, tags) => {
        return {
          payload: { measurementIds, tags }
        }
      }
    },
    addTagsToMeasurements: {
      reducer: (state, action) => {
        const { measurementIds, tag } = action.payload
        const updatedEntries = []
        measurementIds.forEach(measurementid => {
          if (state.entities[measurementid] === undefined) {
            return
          }

          updatedEntries.push({
            ...state.entities[measurementid],
            tags: { ...state.entities[measurementid].tags, ...tag }
          })
        })
        measurementsAdapter.setMany(state, updatedEntries)
      },
      // needed to allow the reducer to accept multiple payload parameters
      // https://stackoverflow.com/questions/71778940/redux-toolkit-reducer-with-multiple-parameters-redux-toolkit
      prepare: (measurementIds, tag) => {
        return {
          payload: { measurementIds, tag }
        }
      }
    }
  }
})

export const {
  selectAll: selectAllMeasurements,
  selectIds: allMeasurementIds,
  selectById: selectMeasurementsById
} = measurementsAdapter.getSelectors((state) => state.measurements)

export const {
  setMeasurements,
  removeMeasurement, addJobsToMeasurements, addTagsToMeasurements, setTagsToMeasurements
} = measurementsSlice.actions

export default measurementsSlice.reducer
