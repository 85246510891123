import { Modalities } from './Modality'

/**
 * Commonly used Mapbox GL JS filters.
 *
 * @author Oguz Oztoprak
 */
export const FILTER_FORWARD = ['==', 'forward_moving', true]
export const FILTER_BACKWARD = ['==', 'forward_moving', false]
export const DEFAULT_FILTER = ['==', 'forward_moving', true]

export const FILTER_MODALITY = (modality) => {
  return (modality === Modalities.All) ? ['!=', 'modality', modality] : ['==', 'modality', modality]
}
