/**
 * This file contains utility functions for the Redux reducers.
 *
 * See https://redux.js.org/usage/structuring-reducers/refactoring-reducer-example
 *
 * @author Armin Schnabel
 */

/**
 * Function which creates a reducer for a state object.
 *
 * @param {*} initialState The initial state of the state object, e.g. `[]` for `items`.
 * @param {*} handlers The "case" functions to be called to alter that state object (setter, etc.).
 * @returns
 */
export function createReducer (initialState, handlers) {
  return function reducer (state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

/**
 * Utility function which updates fields of an object.
 *
 * @param {*} oldObject The object to update, e.g. `state={items, ...}`.
 * @param {*} newValues The new parts of the object, e.g. `{items: items}`.
 * @returns The new object
 */
export function updateObject (oldObject, newValues) {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  return Object.assign({}, oldObject, newValues)
}

/**
 * Utility function which adds an element to an array.
 *
 * @param {*} array The array to update.
 * @param {*} newEntry The element to add to the array.
 * @returns The updated array.
 */
export function addToArray (array, newEntry) {
  return [...array, newEntry]
}

/**
 * Utility function which adds elements to an array.
 *
 * @param {*} array The array to update.
 * @param {*} newEntries The elements to add to the array.
 * @returns The updated array.
 */
export function addAllToArray (array, newEntries) {
  return [...array, ...newEntries]
}

/**
 * Utility function which removes an element from an array.
 *
 * @param {*} array The aray up update.
 * @param {*} entryId The array id of the element to remove.
 * @returns The updated array.
 */
export function removeFromArray (array, entryId) {
  return array.filter((id) => id !== entryId)
}

/*
function updateItemInArray (array, itemId, updateItemCallback) {
  const updatedItems = array.map(item => {
    if (item.id !== itemId) {
      // Since we only want to update one item, preserve all others as they are now
      return item
    }

    // Use the provided callback to create an updated item
    const updatedItem = updateItemCallback(item)
    return updatedItem
  })

  return updatedItems
}

Use like:
function editTodo(state, action) {
  const newTodos = updateItemInArray(state.todos, action.id, todo => {
    return updateObject(todo, { text: action.text })
  })

  return updateObject(state, { todos: newTodos })
}
*/
