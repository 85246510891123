/**
 * Commonly used constants in segment details box
 *
 * @author Oguz Oztoprak
 */

export const Categories = {
  ROUGH: 'Rau',
  MODERATE: 'Mäßig',
  NORMAL: 'Normal',
  SMOOTH: 'Glatt'
}

export const IMAGE_WIDTH_PIXEL = 300
export const MOBILE_IMAGE_WIDTH_PIXEL = window.innerWidth * 0.35
export const SMALL_MOBILE_IMAGE_WIDTH_PIXEL = 359
export const IMAGE_ASPECT_RATIO = 0.6 // height / width, e.g. 1200x720

// Names of the segment detail tabs.
export const streetDetailsName = 'Infos'
export const streetConditionName = 'Verlauf'
