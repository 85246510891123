import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { version } from '../version'

/**
 * The Copyright shown at the bottom of the web-app and at the bottom right of the map.
 *
 * @author Armin Schnabel
 * @author Oguz Oztoprak
 */
const Footer = ({ left, right }) => {
  /**
   * The element injected into the container
   */
  return (
    <Container
      $left={left !== undefined ? left : 'auto'} // transient prop
      $right={right !== undefined ? right : 'auto'}>
      <NoUnderline href="https://www.cyface.de/" target="_blank">
        © Cyface GmbH</NoUnderline>
      &nbsp;|&nbsp;
      <NoUnderline href="https://www.cyface.de/impressum/" target="_blank" rel="noopener">
        Impressum</NoUnderline>
      &nbsp;|&nbsp;
      <NoUnderline href="https://www.cyface.de/datenschutzbestimmung-der-app/" target="_blank" rel="noopener">
        Datenschutz</NoUnderline>
      { /* Instead of extracting the version from package.json the CI set it during publishing.
        Before we used `genversion`: https://cyface.atlassian.net/browse/RFR-258?focusedCommentId=35002 */ }
      <DefaultPointerContainer>
        &nbsp;|&nbsp;{version}
      </DefaultPointerContainer>
    </Container>
  )
}

const Container = styled.div`
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0px 0px 0px 5px;
  position: fixed; // relative to the viewport
  left: ${props => props.$left};
  right: ${props => props.$right};
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.5);
  line-height: 20px;
  z-index: 2;
  cursor: pointer;
`

const DefaultPointerContainer = styled.div`
  display: inline;
  cursor: default;
`

const NoUnderline = styled.a`
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

/**
 * Validates props' types
 */
Footer.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string
}

export default Footer
