import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import logo from '../../resources/logo_small_rfr.svg'
import Profile from './Profile'

/**
 * The top bar of the dashboard with the style of RFR.
 */
const Header = ({ logout }) => {
  /**
   * Defines the element injected into the container
   */
  return (
    <Container>
      <Line />

      <Center>
        <Logo src={ logo } alt='Logo' />
        <Link href='https://ready-for-robots.de'>
          Zurück zur Beteiligungsplattform
        </Link>
      </Center>

      <div style={{ position: 'absolute', top: '35px', right: '40px' }}>
        <Profile
          logout={logout}
          backgroundColor={'rgb(216, 216, 216)'}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: rgb(216, 216, 216);
`

const Line = styled.div`
  height: 0.5em;
  background: linear-gradient(to right, #278a5b, #278a5b 33%, #0082c8 66%, #0082c8);

  @media (max-width: 1200px) {
    height: 0.55rem;
  }
`

const Center = styled.div`
  display: flex;
  align-items: center; // horizontally
  margin: 0 auto; // center content
  width: 100%;
  height: 88.38px;

  @media (max-width: 768px) {
    height: 62px;
  }
  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1440px) {
    width: 1320px;
  }
`

const Logo = styled.img`
  height: 2.3rem;
  width: auto;
  margin-left: 5px;
  margin-right: 24px;
  padding-left: 7px;

  @media (min-width: 576px) {
    height: 2.3rem;
    padding-left: 0px;
  }
  @media (min-width: 576px) {
    height: 2.3rem;
  }
  @media (min-width: 768px) {
    height: 0rem;
  }
  @media (min-width: 992px) {
    height: 1rem;
  }
  @media (min-width: 1200px) {
    height: 2.65rem;
  }
`

const Link = styled.a`
  color: rgba(0, 0, 0, 0.55);
  &:hover { color: rgba(0, 0, 0, 0.7); };
  font-size: 15px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,
    Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  margin-top: 1px;

  @media (max-width: 768px) { // small screens
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
`

/**
 * Validates props' types
 */
Header.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
}

export default Header
