import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ControlButton from '../ControlButton'
import { Checkbox } from 'react-materialize'
import './TermsOfUse.css'

/**
 * Dialog which display the terms and allows the user to accept or decline them.
 *
 * It also contains the opt-in for error tracking.
 *
 * @author Armin Schnabel
 */
class TermsOfUse extends Component {
  state = {
    errorTrackingEnabled: false
  }

  /**
   * Defines the element injected into the container
   */
  render () {
    return (
      <Container>
        <Heading>Mapbox</Heading>
        <TextBlock>
          Wir benutzen auf unserer Seite Funktionen des Kartendienstes Mapbox.
          Angeboten werden diese Funktionen von der Firma Mapbox Inc. Bei der Nutzung von Mapbox
          Diensten wird eine Verbindung zu den Servern von Mapbox aufgebaut. Hierbei wird Ihre
          IP Adresse an Mapbox übertragen, gespeichert und verarbeitet. Diese Seite hat kein
          Einfluss auf die Übertragung dieser Daten an Mapbox.<br /><br />

          Weitere Informationen finden Sie in der&nbsp;
          <a href='https://www.mapbox.com/privacy/' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung von Mapbox</a>.
        </TextBlock>

        <Heading>Cookies</Heading>
        <TextBlock>
          Um sicher mit dem Cyface Server zu kommunizieren, speichern wir Einstellungen und
          Zugangscodes im lokalen Speicher Ihres Browsers.
        </TextBlock>

        <Heading>Fehler-Tracking durch Sentry</Heading>
        <ErrorTrackingTextBlock>
          Wir verbessern und entwickeln unsere App ständig weiter. Trotzdem können wir keinen
          fehlerfreien Betrieb der App garantieren. Um mögliche Programmierfehler so gering wie
          möglich zu halten, verwenden wir Sentry, ein Service von Functional Software Inc.,
          132 Hawthorne St, San Francisco, CA 94107, USA (nachfolgend &quot;Sentry&quot;).
          Sentry überwacht Fehler die innerhalb unserer App auftreten und informiert uns über
          diese. Im Falle eines Software-Fehlers werden automatisch folgende Informationen an
          Sentry übermittelt:
          <List>
            <li>Fehlermeldungen, die bei der Verwendung der App auftreten</li>
            <li>Informationen über die Verwendung der App unmittelbar vor dem Auftreten des
              Fehlers</li>
            <li>Datum und Uhrzeit der Fehler, Startzeitpunkt der App</li>
            <li>Geräteinformationen: Betriebssystem, App Version und Gerätemodell</li>
            <li>Kennung zur Identifizierung von Fehlern desselben Geräts</li>
            <li>Status des Geräts: Batterieladung und -temperatur, Ladezustand, Zeitpunkt des
              Gerätestarts, Arbeitsspeicher, Verbindungstyp (Wi-Fi/Mobil-Daten), Speicherplatz,
              verfügbarer Speicher, bevorzugte Sprache, Geräteausrichtung, Bildschirmauflösung
              und -dichte, Zeitzone</li>
            <li>IP-Adresse des Geräts (durch den Verbindungsaufbau)</li>
          </List>
          Die Informationen werden anonym erhoben, nicht für persönliche Zwecke oder zu
          Werbezwecken verwendet und nach einem Zeitraum von 90 Tagen gelöscht. Rechtsgrundlage
          für die vorgenannte Datenverarbeitung ist Art. 6, Abs. 1 lit. f der DSGVO. Zu diesem
          Zweck wurde mit der Functional Software Inc. ein Auftragsverarbeitungsvertrag
          (AV-Vertrag) abgeschlossen.
          <br /><br />
          Zweck der Verarbeitung ist ausschließlich die Verbesserung der Stabilität und
          Nutzbarkeit der App. Weitere Informationen bzgl. der Datenverarbeitung durch Sentry
          finden Sie in den <a href="https://sentry.io/privacy/">Datenschutzrichtlinien von Sentry</a>.
        </ErrorTrackingTextBlock>

        {/* React-materialize Checkbox component has no clear api to change the color of
        the check mark, manual css is used instead. */}
        <Checkbox
          className='cyface-terms-checkbox'
          label='Fehlermeldungen und App-Nutzung automatisch übermitteln'
          value=''
          onChange={this.onErrorTrackingChange}
          checked={this.state.errorTrackingEnabled} />

        <Buttons>
          <ControlButton
            text="Akzeptieren"
            onClick={() => { this.props.handleTerms(true, this.state.errorTrackingEnabled) }} />
          <ControlButton
            text="Ablehnen"
            onClick={() => { this.props.handleTerms(false, this.state.errorTrackingEnabled) }} />
        </Buttons>
      </Container>
    )
  }

  /**
   * Handler which is called when an this checkbox is changed.
   */
  onErrorTrackingChange = () => {
    const currentChecked = this.state.errorTrackingEnabled
    this.setState({ errorTrackingEnabled: !currentChecked })
  }
}

/**
 * This needs to be working with mobile and desktop view!
 */
const Container = styled.div`
  position: fixed; // relative to the viewport
  top: calc( (100vh - min(80vh, 800px)) / 2 );
  left: calc( (100vw - min(80vw, 700px)) / 2 );

  height:80vh;
  max-height:800px;
  width:80vw;
  max-width: 700px;
  overflow: auto;

  text-align:center;
  padding:20px;
  background-color: #fff;
  font-family: "Open Sans", Arial, sans-serif;

  border: solid #ccc;
  -webkit-box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  -moz-box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  border-width: thin;
  border-radius: 10px;
`

const Heading = styled.h2`
  text-align: center;
  font-weight: 500;
  line-height: 1em;
  font-size:26px;
  color: #333;
`

const TextBlock = styled.p`
  font-size:14px;
  color:#666;
  text-align:left;
`

const Buttons = styled.div`
  margin-top: 40px;
  left: calc((100% - 220px)/2);
`

const ErrorTrackingTextBlock = styled.div`
  font-size: 14px;
  color: #666;
  text-align: left;
  max-height: calc( min(30vh, 800px / 4) );
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10;

  li {
    position: relative;
    padding-left: 30px;

    &::before {
      content: "•";
      position: absolute;
      left: 0;
      color: black;
      font-size: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

/**
 * Validates props' types
 */
TermsOfUse.propTypes = {
  handleTerms: PropTypes.func.isRequired
}

export default TermsOfUse
