import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import ControlButton from '../ControlButton'
import { Modes, Pipelines } from './datasets/DatasetsAnalysis'
import { States } from '../../reducers/datasetsView'
import { Sources } from '../constants/Sources'
import UnviewedJobsBadge from './UnviewedJobsBadge'
import { Status } from '../JobHelpers'
import { selectAllMeasurements } from '../../reducers/measurements'
import { updateUi, setVisibleLayerId } from '../../reducers/ui'
import { Views } from '../constants/Views'

/**
 * To switch between the different views (datasets, infrastructure)
 *
 * @author Armin Schnabel
 */
class ViewControls extends Component {
  /**
   * Defines the element injected into the container
   */
  render () {
    const { ui, processedDataExists } = this.props
    const { view } = ui
    const noMeasurements = this.props.allMeasurements.length === 0
    const defaultToolTip = "Informationen zu dem ausgewählen Bereich finden Sie im 'Hilfe'-" +
      'Bereich unten links.'
    return (
      <Container>
        <ControlButton
          text="Daten"
          icon="storage"
          onClick={(_) => this.selectView(Views.Datasets)}
          active={view === Views.Datasets}
          tooltip={defaultToolTip} />

        {noMeasurements
          ? ''
          : !processedDataExists
              ? <ControlButton disabled={true} text='Beauftragen Sie eine Datensatz-Analyse' />
              : this.infrastructureButton(view, processedDataExists, defaultToolTip)
        }

        <ControlButton
          text="Export"
          icon="file_download"
          onClick={(_) => this.selectView(Views.Export)}
          active={view === Views.Export}
          tooltip={defaultToolTip} />
      </Container>
    )
  }

  infrastructureButton = (view, processedDataExists, defaultToolTip) => {
    const showBadge = view !== Views.Infrastructure
    return <ButtonWithBadge>
      <ControlButton
        text="Analysen"
        icon="insert_chart"
        onClick={(_) => this.selectView(Views.Infrastructure)}
        active={view === Views.Infrastructure}
        disabled={!processedDataExists}
        tooltip={defaultToolTip} />

      { showBadge
        ? <UnviewedJobsBadge
        left={'74%'}
        countFunction={(jobs) => {
          // Count the number of jobs satisfying the predicate.
          return Object.values(jobs).reduce((count, job) =>
            count + (job.pipeline === Pipelines.Surface &&
              job.mode !== Modes.SegmentHistory &&
              job.status === Status.Finished &&
              !('viewed' in job)),
          0)
        }}/>
        : '' }
    </ButtonWithBadge>
  }

  selectView = (newView) => {
    const { ui } = this.props
    const { view: previousView } = ui
    if (newView === previousView) {
      return
    }
    if (newView !== Views.Datasets && newView !== Views.Infrastructure &&
      newView !== Views.Export) {
      throw Error('Unknown view: ' + newView)
    }

    // Update view in Redux
    this.props.updateUi({ view: newView })

    this.switchLayers(previousView, newView)
  }

  // Switch dataset on map
  switchLayers = (previousView, newView) => {
    const { map, ui, datasetsView, infrastructureView, setVisibleLayerId } = this.props
    const { direction, visibleLayerId } = ui
    const { mode, modality } = infrastructureView

    // Hide previous view
    if (previousView === Views.Datasets) {
      this.hideDatasets(datasetsView, map)
    }

    // Draw new view
    switch (newView) {
      case Views.Datasets:
        setVisibleLayerId(map, Sources.Datasets, visibleLayerId, direction, direction, modality)
        this.redrawDatasets(datasetsView, map)
        break
      case Views.Infrastructure: {
        switch (mode) {
          case Modes.H3:
            setVisibleLayerId(map, Sources.H3, visibleLayerId, direction, direction, modality)
            break
          case
            Modes.Segment:
            setVisibleLayerId(map, Sources.Segment, visibleLayerId, direction, direction, modality)
            break
          default: throw new Error('Unknown mode: ' + mode)
        }
        break
      }
      case Views.Export: {
        // We currently don't show any data there but will in the future
        setVisibleLayerId(map, Sources.Export, visibleLayerId, direction, direction, modality)
        break
      }
      default:
        throw new Error('Unknown view: ' + newView)
    }
  }

  // Redraw the selected elements from previous datasets view
  redrawDatasets = (datasetsView, map) => {
    if (datasetsView.active === States.ShowDatasets && datasetsView.selected.length > 0) {
      datasetsView.selected.forEach(id => {
        // The layer might not be loaded yet.
        if (map.getLayer(id)) {
          map.setLayoutProperty(id, 'visibility', 'visible')
        }
      })
    }
  }

  // Hide the selected elements from previous datasets view
  hideDatasets = (datasetsView, map) => {
    if (datasetsView.active === States.ShowDatasets && datasetsView.selected.length > 0) {
      datasetsView.selected.forEach(id => {
        // The layer might not be loaded yet.
        if (map.getLayer(id)) {
          map.setLayoutProperty(id, 'visibility', 'none')
        }
      })
    }
  }
}

const Container = styled.header`
  position: static; // not relative
  padding: 10px 0px 10px 0px;
  `

const ButtonWithBadge = styled.header`
  position: relative;
  display: inline;
`

/**
 * Validates props' types
 */
ViewControls.propTypes = {
  map: PropTypes.object.isRequired,
  processedDataExists: PropTypes.bool.isRequired,

  // Redux injections
  allMeasurements: PropTypes.array.isRequired,

  datasetsView: PropTypes.object.isRequired,
  infrastructureView: PropTypes.object.isRequired,

  ui: PropTypes.object.isRequired,
  updateUi: PropTypes.func.isRequired,
  setVisibleLayerId: PropTypes.func.isRequired
}

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
    allMeasurements: selectAllMeasurements(state),

    datasetsView: state.datasetsView,
    infrastructureView: state.infrastructureView,

    ui: state.ui
  }
}

/**
 * Injects functions into the props which update a Redux state.
 *
 * @param {*} dispatch redux store dispatcher which accepts actions and dispatches them to the
 * registered reducer
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateUi: (update) => dispatch(updateUi(update)),
    setVisibleLayerId:
      (map, visibleLayerId, previousLayerId, direction, previousDirection, modality) => {
        dispatch(setVisibleLayerId(map, visibleLayerId, previousLayerId, direction,
          previousDirection, modality))
      }
  }
}

/**
 * Enhance this component with the higher-order-component 'connect' which binds the redux data
 * store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ViewControls)
