import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DatePicker as MaterializeDatePicker } from 'react-materialize'
import './DatePicker.css'

/**
 * To selecte a Date (day).
 *
 * @author Armin Schnabel
 */
class DatePicker extends Component {
  /**
   * The element injected into the container
   */
  render () {
    const { id, defaultDate, label, onChange, container } = this.props
    const options = {
      // `autoClose: true` Doesn't work with React 17 & `format: dd.mm.yyyy' [DAT-1344].
      // But it seems like the Datepicker also closes automatically with `autoClose: false`.
      autoClose: false,
      firstDay: 1,
      format: 'dd.mm.yyyy',
      i18n: {
        cancel: 'Abbrechen',
        clear: 'Zurücksetzen',
        done: 'Ok',
        months: [
          'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August',
          'September', 'Oktober', 'November', 'Dezember'],
        monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep',
          'Okt', 'Nov', 'Dez'],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag',
          'Samstag'],
        weekdaysAbbrev: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
        weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      },
      defaultDate: defaultDate === undefined ? null : this.props.defaultDate,
      setDefaultDate: this.props.defaultDate !== undefined,
      // Required when Datepicker parent is a small container like a Modal
      // or else the Datepicker is not fully visible
      container: container === undefined ? null : container
    }

    return (
      <MaterializeDatePicker
        id={ id } // used by DatasetsFilter to reset value
        label={label}
        options={ options }
        onChange={ onChange }
        readOnly
        />
    )
  }
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired, // used by DatasetsFilter to reset value
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  defaultDate: PropTypes.oneOfType([
    PropTypes.object, // Date object
    PropTypes.number // None is represented as -1
  ]),
  container: PropTypes.string
}

export default DatePicker
