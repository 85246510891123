import React, { useState, useCallback } from 'react'
import { MediaBox } from 'react-materialize'
import { IMAGE_WIDTH_PIXEL, MOBILE_IMAGE_WIDTH_PIXEL, IMAGE_ASPECT_RATIO } from './SegmentDetailsConstants'
import { useGetImageQuery } from '../../api/apiSlice'
import ControlButton from '../ControlButton'
import { Views } from '../constants/Views'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

/**
 * Details of segment-related images shown in the details box.
 *
 * @author Oguz Oztoprak
 */
const ImageDetails = ({ mobileView, featureId, view, properties }) => {
  const [imageIndex, setImageIndex] = useState(0)
  const imagesAttached = properties !== null && properties.images !== undefined
  const imageId = imagesAttached ? properties.images[imageIndex] : null
  const { data: imageData, isLoading } = useGetImageQuery(imageId, {
    skip: !imagesAttached || view !== Views.Infrastructure
  })

  const imageWidth = mobileView ? MOBILE_IMAGE_WIDTH_PIXEL : IMAGE_WIDTH_PIXEL
  const incrementImageIndex = useCallback(() => { setImageIndex(index => index + 1) }, [])
  const decrementImageIndex = useCallback(() => { setImageIndex(index => index - 1) }, [])

  return isLoading
    ? <ImageContainer mobileView={mobileView}>
          <Skeleton style={{ float: 'none' }}
                    width={imageWidth - 10} height={imageWidth * IMAGE_ASPECT_RATIO - 10} />
      </ImageContainer>
    : imagesAttached
      ? <ImageContainer mobileView={mobileView}>
      <MediaBox>
           <img
            src={ featureId === null ? '' : imageData }
             alt='Segment Foto'
             width={imageWidth } height={imageWidth * IMAGE_ASPECT_RATIO } />
         </MediaBox>
        <div className="center" style={{ marginTop: '20px' }}>
          <ControlButton
            id="segmentDetailsPreviousImageButton"
            icon="navigate_before"
            disabled={ imageIndex === 0 }
            onClick={decrementImageIndex} />
          <ControlButton
            id="segmentDetailsNextImageButton"
            icon="navigate_next"
            disabled={imageIndex === properties.images.length - 1 }
            onClick={incrementImageIndex} />
        </div>
      </ImageContainer>
      : ''
}

/**
 * Validates props' types
 */
ImageDetails.propTypes = {
  mobileView: PropTypes.bool,
  properties: PropTypes.object,
  view: PropTypes.string,
  imageId: PropTypes.string,
  featureId: PropTypes.number
}

const ImageContainer = styled.div`
  display: inline-block;
  max-height: 200px;
  max-width: ${IMAGE_WIDTH_PIXEL}px;
  margin: ${props => props.mobileView ? '20px 5px 15px 5px' : '20px 20px 15px 20px'};
  float: ${props => props.mobileView ? 'right' : 'none'};
`

export default ImageDetails
