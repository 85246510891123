import { createSlice } from '@reduxjs/toolkit'

/**
 * Reducer code specific to the `h3` data.
 *
 * @author Armin Schnabel
 */

/**
 * The GeoJson features returned by the `h3` endpoint.
 *
 * The data is not yet stored in the normalized `byId` format as we currently don't
 * reference the data from another data type.
 */

/**
 * Handler for an entire slices of a state ("slice reducers")
 *
 * Sets the initial state & connects the actions with the reducer functions.
 */
const h3Slice = createSlice({
  name: 'h3',
  initialState: [],
  reducers: {
    setH3: (_, action) => {
      return action.payload
    }
  }
})

/**
 * Handlers for specific cases ("case reducers")
 *
 * Attention!
 * - Never alter the original copy of the state or else re-rendering won't work
 */

export const { setH3 } = h3Slice.actions
export default h3Slice.reducer
