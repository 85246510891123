import React from 'react'
import { render } from 'react-dom'
import App from './App'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import rootReducer from './reducers/index'
import { Endpoints } from './components/constants/Endpoints'
import { apiSlice } from './api/apiSlice'
// The below import from createMockServer adds around 20 KiB to the production bundle
// (i.e. mock-server) and it is not trivial to get rid of it without introducing
// additional complexity. See RFR-416.
import { makeServer } from './createMockServer'

/**
 * Root file which binds the `App` React component to the `app` DOM element of `src/index.html`.
 *
 * @author Armin Schnabel
 */

/**
 * Central data store (Redux).
 */
const store = configureStore({
  reducer: rootReducer,
  // When storing large data in Redux (e.g. `segments` API data) it's recommended to disable
  // SerializableStateInvariantMiddleware and ImmutableStateInvariantMiddleware checks, which
  // are only active in development, for that data path.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // Ignore these paths in the state
    immutableCheck: { ignoredPaths: [Endpoints.H3, Endpoints.Segments] },
    serializableCheck: { ignoredPaths: [Endpoints.H3, Endpoints.Segments] }
  }).concat(apiSlice.middleware)
})

// Mirage has two environments : test and development. The test environment
// turns off Mirage's artificial latency, ignores any initial seeds(), and disables logging
// to the console. In development mode, the Mirage server will have a default response time
// of 400ms, logs all server responses to the console, and loads the development seeds.
//
// Can't use `isMockEnvironment()` from `utils.js` or define the function in here [RFR-217]
if (process.env.REACT_APP_ENVIRONMENT === 'development' ||
  process.env.REACT_APP_ENVIRONMENT === 'test') {
  const environment = process.env.REACT_APP_ENVIRONMENT
  makeServer({ environment })
}

/**
 * Binds the app to the DOM element from `public/index.html`.
 */
render(
  // Redux store injection
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
)
