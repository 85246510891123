import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { connect, useStore, useDispatch } from 'react-redux'
import Header from './header/Header'
import RFRHeader from './header/RFRHeader'
import ViewControls from './sidebar/ViewControls'
import Sidebar from './sidebar/Sidebar'
import Footer from './Footer'
import SegmentDetails from './details/SegmentDetails'
import DatasetDetails from './details/DatasetDetails'
import DetailsBox from './details/DetailsBox'
import Legend from './sidebar/infrastructure/Legend'
import {
  getLocalStorage, getWebsocketApiUrl, autoLogin, showToast, setLocalStorage,
  loggedIn, getConfig, asyncRefreshTokenIfRequired, mockAuth, errorTrackingAccepted
} from './login/utils.js'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { Preloader, ProgressBar } from 'react-materialize'
import { timeout } from './JobHelpers'
import { getSegments, getMeasurements, getH3, reloadProcessedDataIfNecessary } from './DataApi'
import { addSegmentLayer, addPointLayer, addDatasetsLayer, addExportLayer, OVERVIEW_ZOOM_LEVEL } from './MapBoxHelpers'
import DatasetsView from './sidebar/datasets/DatasetsView'
import InfrastructureView from './sidebar/infrastructure/InfrastructureView'
import ExportView from './sidebar/export/ExportView'
import { defaultErrorHandling, defaultWebsocketErrorHandling } from './ErrorHandlingHelpers'
import { Endpoints } from './constants/Endpoints'
import { Sources } from './constants/Sources'
import { Styles } from './constants/Styles'
import { Views } from './constants/Views'
import { Directions } from './constants/Directions'
import './Dashboard.css'
import { Modalities } from './sidebar/Modality'
import { tagSortCompare, areTagsEqual } from './TagHelpers'
import SidebarSkeleton from './sidebar/SidebarSkeleton'
import { setH3 } from '../reducers/h3'
import { setSegments } from '../reducers/segments'
import { addJobs } from '../reducers/jobs'
import { setMeasurements, addJobsToMeasurements } from '../reducers/measurements'
import { updateDatasetsView } from '../reducers/datasetsView'
import { updateUi, setVisibleLayerId } from '../reducers/ui'
import { LocalStorage } from './constants/LocalStorage'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

/**
 * The initial position of the map camera, in this case the center of Germany.
 */
const initialCenter = [10.44772068208375, 51.163391394120815]

/**
 * The initial zoom of the map which is a level where whole Germany is shown on mobile view.
 */
const initialZoom = 5

/**
 * The map which contains a Mapbox map and a sidebar.
 *
 * Using a functional component to be able to access the `sockets` state inside the
 * `Websocket.onclose` event, which would not be possible with a class component.
 */
function Dashboard (props) {
  // The open websockets, to be closed upon logout
  const [sockets, _setSockets] = useState([])
  const dispatch = useDispatch()
  // To be able to access the current version of `sockets` inside the ws.onclose event
  // Without this, `sockets` will still be [] after we close an open connection.
  const socketsRef = React.useRef(sockets)
  const setSockets = data => {
    socketsRef.current = data
    _setSockets(data)
  }
  // Mapbox instance, when loaded
  const [map, setMap] = useState(null)
  // The document to render the Mapbox map in
  const mapContainer = useRef(null)
  // to un-/highlight it
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null)
  // viewport width
  const [width, setWidth] = useState(0)
  // The current zoom level of the map
  const [zoomLevel, setZoomLevel] = useState(initialZoom)
  const zoomRef = useRef()
  zoomRef.current = zoomLevel

  // The progress of data loading from backend.
  const [h3Loaded, setH3Loaded] = useState(false)
  const [segmentsLoaded, setSegmentsLoaded] = useState(false)
  // Navigate hook, to forward the user, e.g. upon authentication error
  const navigate = useNavigate()

  // Store to access redux state inside handler functions.
  const store = useStore()

  const embedded = store.getState().ui.embedded
  const sidbarLess = props.style === Styles.RFR

  // To access the current version of hoveredFeatureId in handlers.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const hoveredFeatureIdContainer = useRef()
  hoveredFeatureIdContainer.current = hoveredFeatureId

  /**
   * Effects which depend on no state/prop (i.e. only executed on un-/mount).
   *
   * The first part is called when the component is inserted into the DOM.
   * The returned function is called when the component is removed from the DOM.
   *
   * Checks login, the mapbox-map setup including the onClick etc. handlers.
   * Loads and sets the API-QualitySegment data asynchronously.
   */
  // TODO: fix async useEffect.
  // eslint-disable-next-line
  useEffect(async () => {
    /**
     * Update internal viewport size state
     */
    function updateWindowDimensions () {
      setWidth(window.innerWidth)
    }

    // Register to viewport size changes
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    // Check if user is logged in otherwise redirect to Login Page
    if (!await autoLogin(navigate, '/map', '/')) {
      return
    }

    if (errorTrackingAccepted()) {
      initializeSentry()
    }

    // Load data from backend
    const [measurements, h3, segments] = await Promise.all([
      getMeasurements(dispatch, defaultErrorHandling, logout),
      getH3(dispatch, defaultErrorHandling, logout, setH3Loaded),
      getSegments(dispatch, defaultErrorHandling, logout, setSegmentsLoaded)])

    const availableTags = measurements.reduce((accumulator, measurement) => {
      if (measurement.tags !== undefined) {
        Object.entries(measurement.tags).forEach(tag => {
          const tagObj = Object.fromEntries([tag])
          if (accumulator.find(savedTag => areTagsEqual(tagObj, savedTag)) === undefined) {
            accumulator.push(tagObj)
          }
        })
      }
      return accumulator
    }, []).sort(tagSortCompare)

    // initialize availabletags from measurements.
    dispatch(updateDatasetsView({
      ...props.datasetsView,
      tags: {
        ...props.datasetsView.tags,
        availableTags
      }
    }))

    // Setup map
    mapboxgl.accessToken = props.accessToken
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: // FIXME: is dev environmen?
        // Using a local copy of the map style in dev mode enabled the app to run when offline.
        // You need to have the `mock-api` set-up and running in dev mode
        // We're serving the original style, i.e. the tiles are loaded from the mapbox server if
        // online.
        // ? 'mapbox/styles/light-v10'
        /*:*/ 'mapbox://styles/mapbox/light-v10?optimize=true',
        center: initialCenter,
        zoom: initialZoom // At this moment always the default value
      })

      // Add zoom control
      const navControl = new mapboxgl.NavigationControl()
      map.addControl(navControl, 'top-left')

      // Add search bar
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          language: 'de-DE',
          mapboxgl
        })
      )

      // Add Scale
      const mapScale = new mapboxgl.ScaleControl({
        maxWidth: 120,
        unit: 'metric'
      })
      map.addControl(mapScale)

      // Set ZoomSpeed
      map.scrollZoom.setWheelZoomRate(1 / 100)

      /**
       * Shows the user that no feature is hovered on the map.
       */
      const segmentMouseLeaveHandler = e => {
        map.getCanvas().style.cursor = ''
        map.setFeatureState({ source: Sources.Segment, id: hoveredFeatureIdContainer.current },
          { hovered: false })
      }

      /**
       * Shows the user which feature is hovered on the map.
       */
      const segmentMouseEnterHandler = e => {
        map.getCanvas().style.cursor = 'pointer'

        // Reset last hovered element
        if (hoveredFeatureIdContainer.current) {
          map.setFeatureState({ source: Sources.Segment, id: hoveredFeatureIdContainer.current },
            { hovered: false })
        }

        // Set new hovered element
        setHoveredFeatureId(e.features[0].id)
        map.setFeatureState({ source: Sources.Segment, id: hoveredFeatureIdContainer.current },
          { hovered: true })
      }

      /**
       * Resets currently selected map features.
       */
      const mapClickHandler = (e) => {
        const ui = store.getState().ui
        const featureId = ui.selectedFeature.id
        if (featureId) {
          map.setFeatureState({ source: Sources.Segment, id: featureId }, { clicked: false })

          // Update state which is used by Details component
          const newSelectedFeature = {
            id: null,
            layerId: null,
            sourceId: null,
            centerLat: null,
            centerLon: null
          }
          dispatch(updateUi({ selectedFeature: newSelectedFeature }))
        }
      }

      /**
       * Returns the 'radius' value of a number.
       *
       * @param {*} number to calculate the radius for
       * @returns the 'radius' value
       */
      const toRad = (number) => {
        return number * Math.PI / 180
      }

      /**
       * Returns the 'degree' value of a number.
       *
       * @param {*} number to calculate the degree value for
       * @returns the 'degree' value
       */
      const toDeg = (number) => {
        return number * (180 / Math.PI)
      }

      /**
       * Calculates the middle point between two coordinates
       * Source: http://www.movable-type.co.uk/scripts/latlong.html [MIT licence on github]
       *
       * @param {*} lng1 the longitude of the first coordinate
       * @param {*} lat1 the latitutde of the first coordinate
       * @param {*} lng2 the longitude of the second coordinate
       * @param {*} lat2 the latitutde of the second coordinate
       */
      const calculateCenter = (lng1, lat1, lng2, lat2) => {
        const lngDiff = toRad(lng2 - lng1)
        lat1 = toRad(lat1)
        lat2 = toRad(lat2)
        lng1 = toRad(lng1)
        const bY = Math.cos(lat2) * Math.sin(lngDiff)
        const bX = Math.cos(lat2) * Math.cos(lngDiff)
        const lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX)
        const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2),
          Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY))
        return [toDeg(lng3), toDeg(lat3)]
      }

      /**
       * Adds an image to the map which can then be used later on the map, e.g. as marker.
       * <p>
       * Attention: This is done asynchronously!
       *
       * @param map The map object to be updated
       * @param image the image to be used as symbol
       * @param imageId the id under which the image should be added to the map
       * @returns a {@code Promise} with a rejection(error) or resolve(void) on completion
       * /
    const asyncAddImage = (map, image, imageId, callback) => {
      return new Promise(function (resolve, reject) {
        map.loadImage(image, (error, image) => {
          if (error) {
            return reject(error)
          }
          // Add image to map
          map.addImage(imageId, image)
          // Resolve the promise
          return resolve()
        })
      })
    } */

      /**
       * Changes color of the clicked segment
       */
      const segmentClickHandler = (e) => {
        const ui = store.getState().ui
        const previousFeatureId = ui.selectedFeature.id
        if (previousFeatureId) {
          map.setFeatureState({ source: Sources.Segment, id: previousFeatureId },
            { clicked: false })
        }

        // Update state which is used by Details component
        const selectedFeature = e.features[0]
        const lon1 = selectedFeature.geometry.coordinates[0][0]
        const lat1 = selectedFeature.geometry.coordinates[0][1]
        const lon2 = selectedFeature.geometry.coordinates[1][0]
        const lat2 = selectedFeature.geometry.coordinates[1][1]
        const center = calculateCenter(lon1, lat1, lon2, lat2)
        const newSelectedFeature = {
          id: selectedFeature.id,
          layerId: Sources.Segment,
          sourceId: Sources.Segment,
          centerLon: center[0],
          centerLat: center[1]
        }
        dispatch(updateUi({ selectedFeature: newSelectedFeature }))

        // Update selected segment on map
        map.setFeatureState({ source: Sources.Segment, id: selectedFeature.id }, { clicked: true })
      }

      // Register handler
      map.on('click', mapClickHandler)
      map.on('click', Sources.Segment, segmentClickHandler)
      map.on('mouseenter', Sources.Segment, segmentMouseEnterHandler)
      map.on('mouseleave', Sources.Segment, segmentMouseLeaveHandler)

      map.on('zoomend', async () => {
        // Only update when zoom level crosses the overview zoom level
        if ((zoomRef.current < OVERVIEW_ZOOM_LEVEL && map.getZoom() >= OVERVIEW_ZOOM_LEVEL) ||
            (zoomRef.current > OVERVIEW_ZOOM_LEVEL && map.getZoom() <= OVERVIEW_ZOOM_LEVEL)) {
          setZoomLevel(map.getZoom())
        }
      })

      // Set map state when loaded + request segments to set as state data
      map.on('load', async (e) => {
        addDatasetsLayer(map, [])
        addPointLayer(map, h3)
        addSegmentLayer(map, segments.features)
        addExportLayer(map, [])

        // Change default view to H3
        if (embedded || sidbarLess) {
          dispatch(updateUi({ view: Views.Infrastructure }))
          dispatch(setVisibleLayerId(map, Sources.H3, Sources.Datasets,
            Directions.Unknown, Directions.Unknown, Modalities.All))
        }

        // Set the map after everything else is set up
        setMap(map)
        watchJobs(map)

        // see RFR-610
        dispatch(setMeasurements(measurements))
        dispatch(setH3(h3.features))
        dispatch(setSegments(segments.features))
      })
    }

    if (!map) initializeMap({ setMap, mapContainer })

    // Component did unmount
    return () => {
      console.log('componentDidUnmount')

      // Unregister to viewport size changes
      window.removeEventListener('resize', updateWindowDimensions)
    }
    // eslint-disable-next-line
  }, [/* map */]) // effect depends on no state/props: only run on un-/mount, not re-render

  /**
   * Initialize Sentry error tracking. Should happen as early as possible in the lifecycle.
   * See https://docs.sentry.io/platforms/javascript/guides/react/.
   *
   * This reports any uncaught exceptions triggered by our app to Sentry.
   *
   * Further configuration is possible, like filtering event data forwarded to sentry:
   * https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
   */
  const initializeSentry = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT
    // if (isProductionEnvironment() /* && process.env.REACT_APP_SENTRY_RELEASE */) {
    Sentry.init({
      // Sentry DSN. This ids the account but is not a secret (public key).
      dsn: 'https://63004f2e74e642f88d97579f917cdc3e@o418976.ingest.sentry.io/4504248642764800',

      // For more integrations like router, redux, etc. see
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/
      integrations: [new BrowserTracing()],

      // Should always be `production`. Sentry also adds the `url` to identify e.g. staging.
      environment,

      // This release name is also used in `sentry.js` to upload the source-map
      release: process.env.REACT_APP_SENTRY_RELEASE,

      // Reduce sample rates if we get close to free tier (50T errors, 100T transactions).
      // Captures 100% of errors
      sampleRate: 1.0,
      // Captures 0-100% of transactions for performance tracing.
      // Performance tracing is only enabled on staging right now.
      tracesSampler: () => {
        if (window.location.host === 'staging.cyface.de') {
          return 1.0
        } else {
          return 0.0
        }
      }
    })
    // }
  }

  /**
   * Subscribe to the current jobs state and future inserts and updates.
   *
   * In JS, we cannot add headers to the Websocket request.
   * We don't want to send it through the url parameters. (https://stackoverflow.com/a/26123316/5815054)
   * We send it through the sub-protocols header as commonly done.
   * Sending it as first message is possible, too, but needs more code to be maintained.
   *
   * The sub-protocols may not contain spaces which is why we send ['Bearer', 'token] instead
   * of ['sub-protocol', 'Bearer eyToken..]". The static sub-protocol 'Bearer' must be accepted
   * in the server response of the Websocket handshake.
   *
   * @param map The map to update after a job finished.
   * @param socketHandler A handler which is called when a new socket is created.
   */
  const watchJobs = async (map) => {
    await asyncRefreshTokenIfRequired()
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const socket = new WebSocket(getWebsocketApiUrl() + Endpoints.Jobs, subProtocols)

    socket.onmessage = async (event) => {
      const response = JSON.parse(event.data)
      if (response.type === 'heartbeat') {
        return
      }

      // Update jobs
      const updatedJobs = response.jobs
      dispatch(addJobs(updatedJobs))

      // Link jobs to measurements
      updatedJobs.forEach(job => {
        const measurementId = job.deviceId + ':' + job.measurementId
        dispatch(addJobsToMeasurements([measurementId], [job.id]))
      })

      reloadProcessedDataIfNecessary(response, map, logout, dispatch)
    }

    socket.onopen = async (event) => {
      // not mutating React state directly
      setSockets([...socketsRef.current, socket])
    }
    socket.onclose = async (event) => {
      // Remove socket reference (if open was successful)
      const current = socketsRef.current
      if (current.length > 0) {
        setSockets(current.filter(s => s !== socket))
      }

      // Only reconnect if user is still logged in
      if (loggedIn()) {
        defaultWebsocketErrorHandling(event.code, logout, async () => {
          await timeout(5_000)
          showToast('Verbindung unterbrochen, wird wiederhergestellt ...')
          watchJobs(map)
        })
      }
    }
    socket.onError = async (event) => {
      defaultWebsocketErrorHandling(event.code, logout, async () => {
        await timeout(5_000)
        showToast('Verbindung fehlgeschlagen, wird wiederhergestellt ...')
        watchJobs(map)
      })
    }

    return socket
  }

  /**
   * Handler for on-logout clicks by the user.
   */
  const logout = useCallback(() => {
    /**
     * ResetView start
     * Fixes a bug where the wrong map layers are shown when a different tab is selected before
     * logout.
     * <p>
     * The logic here is similar to the one in `ViewControl.onClick()`.
     */
    const defaultViewId = Views.Datasets
    if (defaultViewId !== props.ui.view) {
      dispatch(updateUi({ view: defaultViewId }))

      // Switch dataset on map
      dispatch(setVisibleLayerId(map, Sources.Datasets,
        props.ui.visibleLayerId, Directions.Forward, props.ui.direction,
        Modalities.All))
    }
    /**
     * ResetView end
     */

    sockets.forEach(socket => {
      socket.close()
    })
    // Without this a warning is shown "cant perform React state update" (in `ws.onclose`)
    setSockets([])

    // Clear local storage
    const errorTrackingAccepted = getLocalStorage(LocalStorage.ErrorTrackingAccepted)
    const endSessionUrl = getLocalStorage(LocalStorage.AuthServiceConfiguration).endSessionEndpoint
    const idToken = getLocalStorage(LocalStorage.IdToken)
    localStorage.clear()
    // Set terms-accepted flag so the used does not have to accept it again on the next login
    setLocalStorage(LocalStorage.TermsAccepted, true)
    setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)

    // Inform the auth server that the user wants to end the session
    if (mockAuth()) {
      console.log('Mocking logout ...')
      window.location.href = '/'
    } else {
      const redirectUri = getConfig().redirectUri
      const u = `${endSessionUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`
      window.location.href = u
      // The auth server will then redirect the user to `Callback` which navigates the user to `/`
    }

    // Prevent default handling of the link-click
    return false
  }, [dispatch, map, props.ui, sockets])

  /**
   * Defines the element injected into the container
   */
  // This threshold is coded into the Sidenav button, so far we were not able to overwrite it
  // In embedded mode, sidebar is not shown so similar to mobile view
  const mobileView = !embedded && !sidbarLess ? width < 993 : true
  const processedDataExists = props.h3.length > 0 || props.segments.length > 0
  // Injecting into the views or else the mobile view on screens < 400px width breaks [DAT-1466]
  const viewControls = useMemo(() => <ViewControls map={map}
  processedDataExists={processedDataExists} />,
  [map, processedDataExists])

  const { datasetLoading } = props
  const progress = h3Loaded * 50 + segmentsLoaded * 50
  const progressbar = <ProgressBarContainer mobileView={mobileView} width={width}
                                                                    height={window.innerHeight}>
                        <ProgressBar className="loading-progress-colors" progress={progress} />
                      </ProgressBarContainer>
  const datasetPreLoader = useMemo(() =>
            <DatasetPreloader mobileView={mobileView} width={width} height={window.innerHeight}>
              <Preloader
                active={datasetLoading}
                color="green"
                flashing={false}
                size="small" />
            </DatasetPreloader>, [datasetLoading, mobileView, width])

  const styledHeader = useMemo(() => props.style === Styles.Cyface
    ? <Header
        mobileView={mobileView}
        logout={logout}
      />
    : props.style === Styles.RFR
      ? <RFRHeader
        mobileView={mobileView}
        logout={logout}
      />
      : '' // style if null until it's loaded from config
  , [props.style, logout, mobileView])

  /**
   * The element injected into the container
   */
  return (
      <div>
        {
          map !== null
            ? (<div>
              { !embedded
                ? styledHeader
                : '' }

              {!embedded && !sidbarLess
                ? <Sidebar
                mobileView={mobileView}>
                <DatasetsView map={map} viewControls={viewControls} logout={logout} />
                <InfrastructureView map={map} viewControls={viewControls} logout={logout} />
                <ExportView viewControls={viewControls} />
              </Sidebar>
                : '' }

              {datasetPreLoader}

              { progress !== 100 ? progressbar : '' }

              <DetailsBox mobileView={mobileView}>
                <SegmentDetails mobileView={mobileView} map={map} logout={logout} />
                <DatasetDetails />
              </DetailsBox>

              <Legend ui={props.ui} mobileView={mobileView} zoomLevel={zoomLevel}/>

              <Footer left={mobileView ? '110px' : 'auto'} right={mobileView ? 'auto' : '270px'} />
            </div>)
            // Showing skeletons during initial loading of the map
            : (<div>
              { !embedded
                ? styledHeader
                : '' }

              {progressbar}

              { !embedded && !sidbarLess
                ? <Sidebar mobileView={mobileView}>
                <SidebarSkeleton/>
              </Sidebar>
                : '' }

              <Footer left={mobileView ? '110px' : 'auto'} right={mobileView ? 'auto' : '270px'} />
            </div>)
        }

        <Mapbox
          ref={(el) => { mapContainer.current = el }}
          embedded={embedded}
          headerHeight={props.style === Styles.RFR ? '100px' : '70px'}
          sidebarSize={mobileView ? '0px' : '400px'} />
      </div>
  )
}

/**
 * Styled Components
 */
const Mapbox = styled.div`
  position: fixed; // relative to the viewport
  bottom: 0;
  right: 0;
  // 100vh does not work on mobile devices with position absolute
  height: calc(100% - ${props => props.embedded ? '0px' : props.headerHeight});
  width: calc(100% - ${props => props.embedded ? '0px' : props.sidebarSize});
`

const DatasetPreloader = styled.div`
  z-index: 2;
  position: absolute;
  left: ${props => props.mobileView ? props.width / 2 : (props.width + 400) / 2}px;
  top:${props => props.height / 2}px;
`

const ProgressBarContainer = styled.div`
  z-index: 3;
  position: relative;
  left: ${props => props.mobileView ? (props.width / 2) - 50 : (props.width + 400) / 2}px;
  top:${props => props.height / 2}px;
  width: 100px
`

/**
 * Validates props' types
 */
Dashboard.propTypes = {
  accessToken: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,

  // Redux injections
  h3: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,

  measurements: PropTypes.object.isRequired,

  datasetLoading: PropTypes.bool.isRequired,
  ui: PropTypes.object.isRequired,
  datasetsView: PropTypes.object.isRequired
}

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
    h3: state.h3,
    segments: state.segments,
    measurements: state.measurements,
    datasetLoading: state.datasetsView.datasetLoading,

    ui: state.ui,
    datasetsView: state.datasetsView
  }
}

export default connect(mapStateToProps)(Dashboard)
