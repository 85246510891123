import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Button } from 'react-materialize'
import TimeFilter from '../TimeFilter.js'
import ControlButton from '../../ControlButton.js'
import { modalityToGerman, Modalities } from '../Modality'
import { areTagsEqual } from '../../TagHelpers'

const UNIQUE_PREFIX = 'datasetsFilter'

/**
 * Displays the filters in the DatasetsView.
 *
 * @author Oguz Oztoprak
 */
class DatasetsFilter extends Component {
  state = {
    // The currently selected from- and to-date
    fromDate: null, // none selected
    toDate: null // none selected
  }

  render () {
    const { modality, time, setModality, /* tags, */ disabled } = this.props
    // TODO [RFR-301]: const { availableTags } = tags
    return (
      <div>
        { /* modality filter */ }
        <Dropdown
          options={{ constrainWidth: false }}
          trigger={
            <Button
              style={{ backgroundColor: '#3F8730', color: '#FFFFFF', margin: '0px 5px 5px 0px' }}
              node="button"
              waves="light"
              disabled={disabled}
              small>
              {modalityToGerman(modality)}
            </Button>}>
          { Object.values(Modalities).map(x => {
            return (
              <a
                style={{ color: '#3F8730', fontSize: '14px' }}
                key={x} onClick={() => setModality(x)} >
              {modalityToGerman(x)}
              </a>
            )
          })
          }
        </Dropdown>

        { /* tag filter
        TODO [RFR-301]:
        <Dropdown
          options={{ constrainWidth: false }}
          trigger={
            <Button
              style={{ backgroundColor: '#3F8730', color: '#FFFFFF', margin: '0px 5px 5px 0px' }}
              node="button"
              waves="light"
              disabled={disabled}
              small>
              {'Tags'}
            </Button>}>
          { availableTags.map(tag => {
            return (
              <a
                style={{ color: '#3F8730', fontSize: '14px' }}
                key={Object.values(tag)[0]} onClick={() => this.checkAndAddActiveTag(tag)} >
              {`${Object.keys(tag)[0]}: ${Object.values(tag)[0]} `}
              </a>
            )
          })
          }
        </Dropdown> */ }

        { /* time filter */ }
        <TimeFilter
          uniquePrefix={UNIQUE_PREFIX}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          setFromDate={this.setFromDate}
          setToDate={this.setToDate}
          onClickClear={this.onClearFilters}
          time={this.props.time}
          disabled={disabled}
          setTime={this.props.setTime} />

        { /* reset filters */ }
        <ControlButton
          icon={'filter_list_off'}
          onClick={this.onClearFilters}
          disabled={disabled || (modality === Modalities.All &&
                                 !time.active &&
                                 this.props.tags.activeTags.length === 0)}
          tooltip='Filter zurücksetzen' />
      </div>
    )
  }

  checkAndAddActiveTag = (tag) => {
    const { addActiveTag, tags } = this.props
    const { activeTags } = tags
    if (!activeTags.some(activeTag => areTagsEqual(activeTag, tag))) {
      addActiveTag(tag)
    }
  }

  onClearFilters = () => {
    this.props.setModality(Modalities.All)
    this.onTimeFilterClear()
    this.props.setTags({ ...this.props.tags, activeTags: [] })
  }

  /**
   * Clears the time frame.
   *
   * @param e the triggered event from this handler.
   */
  onTimeFilterClear = (e) => {
    // Update internal state
    this.setState({
      fromDate: null,
      toDate: null
    })

    // Update UI manually
    document.getElementById(UNIQUE_PREFIX + 'FromDate').value = ''
    document.getElementById(UNIQUE_PREFIX + 'ToDate').value = ''

    // Update global state
    this.props.setTime({
      active: false,
      to: null,
      from: null
    })
  }

  // These functions below are not defined inline so that they
  // are not redefined at each render and cause child components to
  // also re-render. See RFR-309.
  setFromDate = (fromDate) => this.setState({ fromDate })
  setToDate = (toDate) => this.setState({ toDate })
}

/**
 * Validates props' types
 */
DatasetsFilter.propTypes = {
  modality: PropTypes.string.isRequired,
  setModality: PropTypes.func.isRequired,
  time: PropTypes.object.isRequired,
  setTime: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  addActiveTag: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default DatasetsFilter
