import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateUi } from '../../reducers/ui'
import InfoText from './InfoText'
import { Views } from '../constants/Views'
import ControlButton from '../ControlButton'

/**
 * Sidebar for the map which contains control elements and the segment detail view.
 *
 * @author Armin Schnabel
 */
class Sidebar extends Component {
  /**
   * Defines the element injected into the container
   */
  render () {
    const { mobileView, ui } = this.props
    const { view, sidebar } = ui
    return (
    <div>

      { /* Sidebar-Trigger for mobile view */ }
      <Trigger
        display={mobileView ? 'block' : 'none'}>
        <ControlButton
          icon='menu'
          onClick={this.onClickMenu} />
      </Trigger>

      <Container
        display={mobileView && !sidebar ? 'none' : 'flex'}
        background={mobileView && !sidebar ? 'transparent' : 'white'}
        mobileView={mobileView}>

        {this.props.children}

        <InfoText
          text={view === Views.Datasets
            ? "Im Bereich 'Datensätze' werden die von Ihnen hochgeladenen Messungen angezeigt." +
            'Neue Messungen können Sie über unserer Android und iPhone App aufzeichnen.'
            : view === Views.Infrastructure
              ? "Im Bereich 'Analysen/Infrastruktur' können Sie Analyseergebnisse, wie z.B. die" +
          ' Oberflächenbeschaffenheit, einsehen. Wählen Sie auf der Karte ein farblich' +
          ' hinterlegtes Straßensegment aus um Details einzusehen. Wechseln Sie in den Bereich' +
          " 'Datensätze' um zu sehen welche Messungen in die Analyse einbezogen wurden."
              : "Im Bereich 'Export' können Sie sich die Geo-Tracks Ihrer Fahrten und " +
              'die analysierten Straßensegmente herunterladen' } />

        <Overlay
          display={mobileView && sidebar ? 'block' : 'none'}
          opacity={mobileView && sidebar ? '1' : '0'}
          onClick={() => this.props.updateUi({ sidebar: !sidebar })} />

      </Container>
    </div>)
  }

  onClickMenu = () => {
    const { sidebar } = this.props.ui
    this.props.updateUi({ sidebar: !sidebar })
  }
}

const Trigger = styled.div`
  display:    ${props => props.display};
  position:   fixed; // relative to the viewport
  top:        18px;
  left:       20px;
`

const Container = styled.div`
  display:          ${props => props.display};
  flex-direction:   column;

  position:   fixed; // relative to the viewport
  top:        70px;
  z-index:    ${props => props.mobileView ? '999' : 'auto'}; // refer to RFR-665

  height:         calc(100vh - 70px - 52px);
  width:          400px;
  max-width:      80%;

  background:     ${props => props.background};

  font-family:    'Helvetica', 'Arial', sans-serif;
  color:          #222A35;

  a {
    color:    white;
  }
  a:hover, a:active, a:focus {
    color:    white;
  }

  border-right:       solid;
  border-right-width: 1px;
  border-color:       lightgrey;
`

/**
 * Attention: Transition does not work as the DOM is re-rendered upon sidebar-trigger.
 */
const Overlay = styled.div`
  display:    ${props => props.display};
  opacity:    ${props => props.opacity};
  transition: visibility 0s, opacity 0.5s linear;
  position:   fixed; // relative to the viewport
  top:        70px;
  right:      0px;
  z-index:    997;

  margin-top: 0px;
  height:     calc(100vh - 70px);
  width:      calc(100% - 400px);
  min-width:  calc(100% - 80%);

  background: rgba(0,0,0,0.5);
`

/**
 * Validates props' types
 */
Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  mobileView: PropTypes.bool.isRequired,

  // Redux injections
  ui: PropTypes.object.isRequired,
  updateUi: PropTypes.func.isRequired
}

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
    ui: state.ui
  }
}

/**
 * Injects functions into the props which update a Redux state.
 *
 * @param {*} dispatch redux store dispatcher which accepts actions and dispatches them to the
 *  registered reducer
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateUi: (update) => { dispatch(updateUi(update)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
