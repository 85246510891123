import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GenericIcon from '../GenericIcon'
import { isStatusOngoing, Status, isStatusUnsuccessful, isStatusSuccessful } from '../../JobHelpers'
import { Pipelines, Modes } from '../../sidebar/datasets/DatasetsAnalysis'
import JobsProgress from './JobsProgress'
import { supportEmail } from '../../login/utils'

/**
 * The jobs of a dataset shown as icons inside a dataset entry in the sidebar.
 *
 * @author Armin Schnabel
 */
class Jobs extends Component {
  render () {
    return (this.list(this.props.jobs))
  }

  /**
   * Returns a list of `GenericIcon`s.
   *
   * @param {*} jobs The jobs to return.
   * @returns The `GenericIcon`s.
   */
  list = (jobs) => {
    // Return one component per job
    return jobs.map(job => {
      // No icon or progress bar for segment history.
      if (job.mode === Modes.SegmentHistory) {
        return ''
      }
      return this.icon(job)
    })
  }

  /**
   * Generates an icon which represents a `job`.
   *
   * @param {*} job The job to visualize as icon.
   * @returns The `GenericIcon`.
   */
  icon = (job) => {
    const tooltip = this.tooltipText(job)
    const status = this.decideStatus(job)

    // We currently don't expect jobs to be in this state, so we don't show anything
    if (status === Status.Canceled || status === Status.Suspended) {
      return
    }
    // Show job progress for ongoing jobs
    if (isStatusOngoing(status)) {
      return <JobsProgress
                key={job.id}
                mode={job.mode}
                tooltip={tooltip}
                jobs={job.mode === Modes.Segment ? this.props.jobs : [job]}
                status={status}
                selected={this.props.selected}
                checkable={this.props.checkable}
                />
    }

    // Ignore jobs created before result data deletion
    const deprecatedH3Job = job.pipeline === Pipelines.Surface && job.mode === Modes.H3 &&
      new Date(job.created) <= this.props.lastH3Deletion
    const deprecatedSegmentJob = job.pipeline === Pipelines.Surface && job.mode === Modes.Segment &&
      new Date(job.created) <= this.props.lastSegmentDeletion
    if (deprecatedH3Job || deprecatedSegmentJob) {
      return
    }

    // Return icon for jobs in final state (e.g. unprocessable or finished)
    const statusText = this.statusText(status)
    const color = this.color(status)
    const icon = this.finalIcon(job)
    return <GenericIcon
      key={job.id}
      margin=''
      tooltip={tooltip + statusText}
      float="right"
      color={color}
      icon={icon} />
  }

  /**
   * Returns status string depending on the job pipeline mode and status.
   *
   * In case of a segment job, both segment and history job is considered.
   *
   * @param {*} job The job to decide the status for.
   * @returns The `Status`.
   */
  decideStatus = (job) => {
    const locationInfo = job.locations !== undefined && job.locations !== 'null'
    const rotated = locationInfo && job.locations === job.rotatedLocations
    const filteredLocations = parseInt(job.rotatedLocations, 10) +
      parseInt(job.invalidLocations, 10) + parseInt(job.nonInterpolatableLocations, 10)
    const filtered = locationInfo && parseInt(job.locations, 10) === filteredLocations

    // Non-segment job does not require special logic.
    if (job.mode !== Modes.Segment) {
      return this.internalStatus(rotated, filtered, job.status)
    }

    // All jobs of a dataset are injected via the `jobs` property
    const datasetJobs = this.props.jobs

    // Return unsuccessful status if any segment related job is unsuccessful
    const unsuccessfulJob = datasetJobs.find(j => isStatusUnsuccessful(j.status))
    if (unsuccessfulJob !== undefined) {
      return this.internalStatus(rotated, filtered, unsuccessfulJob.status)
    }

    // Return created status if a sub-job is not yet running
    const nonStartedJobExist = datasetJobs.some(j => j.status === Status.Created)
    if (nonStartedJobExist) {
      return Status.Created
    }

    // Return finished status when both jobs are finished
    if (datasetJobs.every(j => isStatusSuccessful(j.status))) {
      return Status.Finished
    }

    // Return running status for any other combination (containing a running sub-job)
    return Status.Running
  }

  /**
   * Applies the status types only used in the web-app to the types shared with the API.
   *
   * @param {*} rotated `true` if all locations were unprocessible because of calibration issues.
   * @param {*} filtered `true` if all locations were filtered due to some filter.
   * @param {*} status The status received from the API.
   */
  internalStatus = (rotated, filtered, status) => {
    return rotated ? Status.Rotated : filtered ? Status.Filtered : status
  }

  /**
   * Returns color string depending on the input status.
   *
   * @param {*} status The status to generate the color.
   * @returns Color string.
   */
  color = (status) => {
    const failed = status === Status.Failed
    const unknown = status === Status.Unknown
    const unprocessable = status === Status.Unprocessable
    const rotated = status === Status.Rotated
    const filtered = status === Status.Filtered
    const errorColor = '#ff1414'
    const warningColor = '#ffad17'
    const infoColor = '#76ddff'
    const defaultColor = '#c1c1c1'
    return failed
      ? errorColor
      : (unknown || unprocessable)
          ? warningColor
          : (rotated || filtered)
              ? infoColor
              : defaultColor
  }

  /**
   * Returns status text depending on the input status.
   *
   * @param {*} status The status to generate the text for.
   * @returns The generated text.
   */
  statusText = (status) => {
    const failed = status === Status.Failed
    const unknown = status === Status.Unknown
    const unprocessable = status === Status.Unprocessable
    const rotated = status === Status.Rotated
    const filtered = status === Status.Filtered
    return failed
      ? ' - Verarbeitung Fehlgeschlagen'
      : unprocessable
        ? ' - Verarbeitung nicht möglich. Bitte kontaktieren Sie ' + supportEmail()
        : rotated
          ? ' - Verarbeitung nicht möglich, Messung verwackelt.'
          : filtered
            ? ' - Verarbeitung nicht möglich, Positionsdaten nicht verarbeitbar.'
            : unknown
              ? ' - Verarbeitungsstatus unbekannt. Bitte versuchen Sie es erneut oder' +
          ' kontaktieren Sie ' + supportEmail()
              : ' - Verarbeitet' // finished job
  }

  /**
   * Returns tooltip text depending on the jobs mode and pipeline.
   *
   * @param {*} job The considered job.
   * @returns tooltip text string.
   */
  tooltipText = (job) => {
    return job.pipeline === Pipelines.Surface && job.mode === Modes.H3
      ? 'Oberflächenanalyse (Heatmap)'
      : job.pipeline === Pipelines.Surface && job.mode === Modes.Segment
        ? 'Oberflächenanalyse (Segmente)'
        : job.pipeline === Pipelines.MapMatching
          ? 'Map-Matching '
          : 'Unkannte Pipeline'
  }

  /**
   * Returns the icon which represents a job in a final state.
   *
   * @param {*} job The considered job.
   * @returns A GenericIcon.
   */
  finalIcon = (job) => {
    switch (job.pipeline) {
      case Pipelines.Surface:
        switch (job.mode) {
          case Modes.H3: return 'blur_on' // "apps", "grid_4x4" - all not really good
          case Modes.Segment: return 'reorder' // "dashboard", "view_week" - all not really good
          default: throw Error('Unexpected surface mode: ' + job.mode)
        }

      case Pipelines.MapMatching: return 'linear_scale'

      default: throw Error('Unexpected pipeline: ' + job.pipeline)
    }
  }
}

/**
 * Validates props' types
 */
Jobs.propTypes = {
  selected: PropTypes.bool.isRequired,
  checkable: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
  lastH3Deletion: PropTypes.object.isRequired,
  lastSegmentDeletion: PropTypes.object.isRequired
}

export default Jobs
