import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Datasets from './Datasets'
import DatasetsFilter from './DatasetsFilter'
import DatasetsAnalysis from './DatasetsAnalysis'
import DatasetsRemoval from './DatasetsRemoval'
import { Views } from '../../constants/Views'
import { Chip, Icon } from 'react-materialize'
import { areTagsEqual } from '../../TagHelpers'
// TODO [RFR-301]: import MeasurementTagging from './MeasurementTagging'
// TODO [RFR-301]: import ModifyTags from './ModifyTags.js'
import { States, selectDataset, unselectDataset, addExpanded, removeExpanded, updateDatasetsView, addActiveTag } from '../../../reducers/datasetsView'

/**
 * Control elements shown in the 'dataset' mode where the user can view and filter the
 * sdata analysis results.
 *
 * @author Armin Schnabel
 */
class DatasetsView extends Component {
  /**
   * Defines the element injected into the container
   */
  render () {
    const { ui, datasetsView, updateDatasetsView } = this.props
    const { view, visibleLayerId } = ui
    return (
      <Container view={view}>
        <Actions>
          {this.props.viewControls}

          <label>Filter</label>
          <DatasetsFilter
            modality={datasetsView.modality}
            setModality={this.setModality}
            setTags={this.setTags}
            addActiveTag={this.addActiveTag}
            time={datasetsView.time}
            tags={datasetsView.tags}
            disabled={!this.shouldRenderDatasetList()}
            setTime={this.setTime}/>

            {datasetsView.tags.activeTags.length !== 0 ? <label>Tag-Filter</label> : ''}

            <div>
              {datasetsView.tags.activeTags.map(tag => {
                const key = Object.keys(tag)[0]
                const value = Object.values(tag)[0]
                return <Chip key={key + value}
                             close closeIcon={<Icon onClick={() => {
                               updateDatasetsView({
                                 ...datasetsView,
                                 tags: {
                                   ...datasetsView.tags,
                                   activeTags: datasetsView.tags.activeTags.filter(activeTag =>
                                     !areTagsEqual(activeTag, tag))
                                 }
                               })
                             }} className="close">close</Icon>}>
                {`${key}: ${value} `}
                </Chip>
              })}
            </div>

          { /* Simple actions */ }
          <label>Aktionen</label>
          <DatasetsRemoval
            logout={this.props.logout}
            active={datasetsView.active}
            selected={datasetsView.selected}
            setActive={(active) => updateDatasetsView({ active })}
            clearSelected={() => updateDatasetsView({ selected: [] })} />
          {/* TODO [RFR-301]: <MeasurementTagging
            active={datasetsView.active}
            availableTags={datasetsView.tags.availableTags}
            setActive={(active) => updateDatasetsView({ active })}/>
          <ModifyTags
            active={datasetsView.active}
            tags={datasetsView.tags}
            setTags={(tags) => updateDatasetsView({ tags })}
            setActive={(active) => updateDatasetsView({ active })}
            logout={this.props.logout} /> */}

          { /* Actions with mode selection */ }
          <DatasetsAnalysis
             map={this.props.map}
             logout={this.props.logout}
             active={datasetsView.active}
             selected={datasetsView.selected}
             setActive={(active) => updateDatasetsView({ active })}
             clearSelected={() => updateDatasetsView({ selected: [] })} />
        </Actions>

      { this.shouldRenderDatasetList()
        ? <Scrollable>
            <Datasets
              map={this.props.map}
              logout={this.props.logout}
              active={datasetsView.active}
              modality={datasetsView.modality}
              time={datasetsView.time}
              tags={datasetsView.tags}
              expanded={datasetsView.expanded}
              addExpanded={this.addExpanded}
              removeExpanded={this.removeExpanded}
              selected={datasetsView.selected}
              addSelected={this.addSelected}
              removeSelected={this.removeSelected}
              visibleLayerId={visibleLayerId} />
          </Scrollable>
        : '' }

        { /* <AddDataset map={this.props.map} /> */ }
      </Container>
    )
  }

  shouldRenderDatasetList = () => {
    const active = this.props.datasetsView.active
    return active !== States.AddRemoveAvailableTags &&
            active !== States.RemoveAvailableTags &&
            active !== States.AddAvailableTags
  }

  // These functions below are not defined inline so that they
  // are not redefined at each render and cause child components to
  // also re-render. See RFR-309.
  setTime = (time) => {
    const { updateDatasetsView } = this.props
    updateDatasetsView({ time })
  }

  setModality = (modality) => {
    const { updateDatasetsView } = this.props
    updateDatasetsView({ modality })
  }

  setTags = (tags) => {
    const { updateDatasetsView } = this.props
    updateDatasetsView({ tags })
  }

  addActiveTag = (tag) => {
    const { addActiveTag } = this.props
    addActiveTag(tag)
  }

  addExpanded = (id) => {
    const { addExpanded } = this.props
    addExpanded(id)
  }

  removeExpanded = (id) => {
    const { removeExpanded } = this.props
    removeExpanded(id)
  }

  addSelected = (id) => {
    const { selectDataset } = this.props
    selectDataset(id)
  }

  removeSelected = (id) => {
    const { unselectDataset } = this.props
    unselectDataset(id)
  }
}

/**
 * Validates props' types
 */
DatasetsView.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  viewControls: PropTypes.node.isRequired,

  // Redux injections
  ui: PropTypes.object.isRequired,
  datasetsView: PropTypes.object.isRequired,
  updateDatasetsView: PropTypes.func.isRequired,
  addExpanded: PropTypes.func.isRequired,
  removeExpanded: PropTypes.func.isRequired,
  selectDataset: PropTypes.func.isRequired,
  unselectDataset: PropTypes.func.isRequired,
  addActiveTag: PropTypes.func.isRequired
}

// const COLLAPSIBLE_ITEM_HEIGHT_PIXEL = 79 somehow that's not enough
const Container = styled.div`
  display: ${props => props.view === Views.Datasets ? 'flex' : 'none'};
  flex-direction: column;
  align-items: stretch;
  position: static; // not relative
  height: calc(100%);
  color: #222;
`

const Actions = styled.div`
  padding: 0px 10px 10px 10px;
`

const Scrollable = styled.div`
  padding: 0px 10px 10px 10px;
  overflow-y: auto;
`

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
    ui: state.ui,
    datasetsView: state.datasetsView
  }
}

/**
 * Injects functions into the props which update a Redux state.
 *
 * @param {*} dispatch redux store dispatcher which accepts actions and dispatches them
 * to the registered reducer
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateDatasetsView: (update) => dispatch(updateDatasetsView(update)),
    selectDataset: (id) => dispatch(selectDataset(id)),
    unselectDataset: (id) => dispatch(unselectDataset(id)),
    addExpanded: (id) => dispatch(addExpanded(id)),
    removeExpanded: (id) => dispatch(removeExpanded(id)),
    addActiveTag: (id) => dispatch(addActiveTag(id))
  }
}

/**
 * Enhance this component with the higher-order-component 'connect' which binds the redux
 * data store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DatasetsView)
