import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

/**
 * This component displays placeholder "skeleton" while the map is loading
 *
 * @author Oguz Oztoprak
 */

const SidebarSkeleton = () => {
  return <Container>
           <Skeleton style={{ margin: '10px 5px 0px 0px' }}
                     inline={true}
                     count={3}
                     width={112}
                     height={32}/>
           <FilterLabelContainer>
                  <label>Filter</label>
           </FilterLabelContainer>

           <FilterSkeleton>
                    <Skeleton style={{ margin: '0px 5px 5px 0px' }} inline={true} width={60}
                                                                                  height={32}/>
                    <Skeleton style={{ margin: '0px 5px 5px 0px' }} inline={true} width={110}
                                                                                  height={32}/>
                    <Skeleton style={{ margin: '0px 5px 5px 0px' }} inline={true} width={45}
                                                                                  height={32}/>
           </FilterSkeleton>

           <ActionsLabelContainer>
              <label>Aktionen</label><br/>
           </ActionsLabelContainer>

           <Skeleton style={{ margin: '0px 5px 0px 0px' }} inline={true} width={210}
                                                                         height={32}/>

           <AnalysisLabelContainer>
              <label>Straßenqualität analysieren</label><br />
           </AnalysisLabelContainer>

           <AnalysisSkeleton>
             <Skeleton style={{ margin: '0px 5px 35px 0px' }} inline={true}
                   count={2}
                   width={125}
                   height={32} />
           </AnalysisSkeleton>

           <label>Messungen </label>
           <Skeleton style={{ margin: '0px 5px 5px 0px' }} count={5}
                     width='%100'
                     height={45} />
        </Container>
}

const Container = styled.div`
  display: flex;
  padding: 0px 10px 0px 10px;
  flex-direction: column;
  align-items: stretch;
  position: static; // not relative
  height: calc(100%);
  color: #222;
`

const FilterLabelContainer = styled.div`
  margin: 15px 0px -5px 0px;
`

const FilterSkeleton = styled.div`
`

const AnalysisSkeleton = styled.div`
`

const ActionsLabelContainer = styled.div`
  margin: 2px 0px -6px 0px;
`

const AnalysisLabelContainer = styled.div`
  margin: 3px 0px -5px 0px;
`

export default SidebarSkeleton
