import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  TokenRequest, BaseTokenRequestHandler, GRANT_TYPE_AUTHORIZATION_CODE,
  RedirectRequestHandler, AuthorizationNotifier, FetchRequestor, LocalStorageBackend,
  DefaultCrypto
} from '@openid/appauth'
import { NoHashQueryStringUtils, getConfig, setLocalStorage, getLocalStorage, storeTokens, debug, mockAuth } from './utils'
import { LocalStorage } from '../constants/LocalStorage'
import jwtDecode from 'jwt-decode'

/**
 * Component which handles the callback from the OAuth provider, redirecting logged-in users.
 *
 * @author Armin Schnabel
 */
export const Callback = () => {
  const [error, setError] = useState(null)
  const [code, setCode] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(function () {
    // Redirected after endSession (logout)?
    const loginFlow = location.search.includes('code=')
    if (!loginFlow) {
      // Forward user to login form to signal that he is now logged out
      navigate('/')
      return
    }

    // Redirected after auth (login)
    if (mockAuth()) {
      console.log('Mocking token request ...')
      setLocalStorage(LocalStorage.Username, 'test-user') // hardcoded in provider/Configuration
      setLocalStorage(LocalStorage.IdToken, 'eyMockIdToken')
      setLocalStorage(LocalStorage.UserId, '00000000-0000-0000-0000-000000000000')
      navigate('/') // Forward user back to LoginForms where autoLogin() is triggered
      return
    }
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    )
    const notifier = new AuthorizationNotifier()
    authorizationHandler.setAuthorizationNotifier(notifier)
    notifier.setAuthorizationListener((request, response, error) => {
      if (debug()) {
        console.log('Auth request complete.', request, response, error)
      } else {
        console.log('Auth request complete, error: ', error)
      }
      if (response) {
        let extras = null
        if (request && request.internal) {
          extras = {}
          extras.code_verifier = request.internal.code_verifier
        }

        const config = getConfig()
        const tokenRequest = new TokenRequest({
          client_id: config.clientId,
          redirect_uri: config.redirectUri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: response.code,
          refresh_token: undefined,
          extras
        })

        const authServiceConfiguration = getLocalStorage(LocalStorage.AuthServiceConfiguration)
        tokenHandler.performTokenRequest(authServiceConfiguration, tokenRequest)
          .then((tokenResponse) => {
            const idToken = jwtDecode(tokenResponse.idToken)
            setLocalStorage(LocalStorage.Username, idToken.preferred_username)
            setLocalStorage(LocalStorage.IdToken, tokenResponse.idToken)
            setLocalStorage(LocalStorage.UserId, idToken.sub)
            storeTokens(tokenResponse)
            navigate('/') // Forward user back to LoginForms where autoLogin() is triggered
          })
          .catch(e => { setError(e) })
      }
    })

    const params = new URLSearchParams(location.search)
    setCode(params.get('code'))

    if (!code) {
      setError('Auth code wurde nicht gefunden')
      return
    }
    authorizationHandler.completeAuthorizationRequestIfPossible()
  }, [code, location, navigate])

  /**
   * The element injected into the container
   */
  return (
    <div style={{ margin: '10px' }}>
      { code ? 'Loading...' : 'Fehler: ' + error }
    </div>
  )
}
