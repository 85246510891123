import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ControlButton from '../../ControlButton'
import { Dropdown, Button } from 'react-materialize'
import { modalityToGerman, Modalities } from '../Modality'
import { FILTER_MODALITY, FILTER_FORWARD, FILTER_BACKWARD } from '../MapBoxFilters'
import { Sources } from '../../constants/Sources'
import { Directions } from '../../constants/Directions'
import { Modes, Pipelines } from '../datasets/DatasetsAnalysis'
import { Views } from '../../constants/Views'
import AnalysisRemoval from './AnalysisRemoval'
import { Status, latestProcessedJobDate } from '../../JobHelpers'
import UnviewedJobsBadge from '../UnviewedJobsBadge'
import { selectAllJobs } from '../../../reducers/jobs'
import { setVisibleLayerId } from '../../../reducers/ui'
import { updateInfrastructureView } from '../../../reducers/infrastructureView'
// import { useUpdateFinishedJobMutation } from '../../../api/apiSlice'

/**
 * Control elements shown in the `Views.Infrastructure` mode where the user can view and
 * filter the data analysis results.
 *
 * @author Armin Schnabel
 */
const InfrastructureView = ({ logout, map, viewControls }) => {
  const { allJobs, infrastructureView, ui } = useSelector(state => ({
    allJobs: selectAllJobs(state),
    infrastructureView: state.infrastructureView,
    ui: state.ui
  }))

  const dispatch = useDispatch()

  const { mode, modality } = infrastructureView
  const { view, direction, visibleLayerId } = ui

  const selectMode = (map, mode) => {
    const { direction, visibleLayerId } = ui
    const { modality } = infrastructureView
    if (infrastructureView.mode === mode) {
      return
    }
    if (mode === '' || (mode !== Modes.H3 && mode !== Modes.Segment)) {
      throw new Error('Unknown mode: ' + mode)
    }

    dispatch(updateInfrastructureView({ mode }))

    // Switch data on map
    const layerId = mode === Modes.Segment
      ? Sources.Segment
      : mode === Modes.H3
        ? Sources.H3
        : null
    const newDirection = direction === Directions.Unknown ? Directions.Forward : direction
    dispatch(setVisibleLayerId(map, layerId, visibleLayerId, newDirection, direction, modality))

    updateFilters(modality, mode, map, direction)
  }

  const onClickSelectMode = (map, mode) => {
    return () => selectMode(map, mode)
  }

  const modeSelection = (/* map, mode */) => {
    return (
      <div>
        <label>Darstellung</label><br />
      <ButtonWithBadge>
        <ControlButton
          text="Segmente"
          onClick={onClickSelectMode(map, Modes.Segment)}
          active={mode === Modes.Segment} />
        <UnviewedJobsBadge
          left={mode !== Modes.Segment ? '36%' : '85%'}
          countFunction={(jobs) => {
            const modeToFilter = mode === Modes.Segment ? Modes.H3 : Modes.Segment
            // Count the number of jobs satisfying the predicate.
            return Object.values(jobs).reduce((count, item) =>
              count + (item.pipeline === Pipelines.Surface &&
                item.mode === modeToFilter &&
                item.status === Status.Finished &&
                !('viewed' in item)),
            0)
          }} />
        <ControlButton
          text="Heatmap"
          onClick={onClickSelectMode(map, Modes.H3)}
          active={mode === Modes.H3} />
      </ButtonWithBadge>
      </div>
    )
  }

  const detectOrphanSurfaces = (/* map, logout, mode, view, jobs */) => {
    if (view === Views.Infrastructure) {
      const jobs = allJobs
      const deletionJobs = jobs.filter(j =>
        j.pipeline === Pipelines.Delete && j.status === Status.Finished)
      const datasetDeletionJobs = deletionJobs.filter(j => j.mode === Modes.Measurement)
      const latestAnalysisDeletion = latestProcessedJobDate(
        deletionJobs.filter(j => j.mode === mode))

      // analysisJobs since last analysis deletion.
      const analysisJobs = jobs.filter(j => j.pipeline === Pipelines.Surface &&
                                            j.mode === mode &&
                                            j.status === Status.Finished &&
                                            new Date(j.created) > latestAnalysisDeletion)

      const orphanAnalysisJobs = analysisJobs.filter(analysisJob => {
        const deviceId = analysisJob.deviceId
        const measurementId = analysisJob.measurementId

        // Search for dataset deletion jobs that occured later than analysis job.
        const foundDatasetDeletionJobs = datasetDeletionJobs.filter(deletionJob => {
          return deletionJob.deviceId === deviceId &&
                 deletionJob.measurementId === measurementId &&
                 new Date(analysisJob.created) < new Date(deletionJob.created)
        })

        // If an analysis deletion already happened later in time, then no need to delete again.
        return foundDatasetDeletionJobs.some(foundDatasetDeletionJob =>
          new Date(foundDatasetDeletionJob.created) > latestAnalysisDeletion)
      })

      const deprecated = orphanAnalysisJobs.length === 0
        ? false
        : orphanAnalysisJobs.filter(j => j.mode === mode).length !== 0
      return <AnalysisRemoval
        mode={mode}
        logout={logout}
        deprecated={deprecated}
        map={map} />
    } else {
      return ''
    }
  }

  const directionFilter = (map/*, mode, modality, direction, visibleLayerId */) => {
    return (mode === Modes.Segment)
      ? <div>
          <label>Dargestellte Fahrtrichtung</label><br />
          <ControlButton
            text="Orientierungs-"
            onClick={() => dispatch(setVisibleLayerId(map, visibleLayerId, visibleLayerId,
              Directions.Forward, direction, modality))}
            active={direction === Directions.Forward}
            disabled={mode !== Modes.Segment} />
          <ControlButton
            text="Gegenrichtung"
            onClick={() => dispatch(setVisibleLayerId(map, visibleLayerId, visibleLayerId,
              Directions.Backward, direction, modality))}
            active={direction === Directions.Backward}
            disabled={mode !== Modes.Segment} /><br />
        </div>
      : ''
  }

  const modalityFilter = (/* modality, direction */) => {
    return <div>
      <label> Modalität </label><br />
      <Dropdown
          options={{ constrainWidth: false }}
          trigger={
            <Button
              style={{ backgroundColor: '#3F8730', color: '#FFFFFF' }}
              node="button"
              waves="light"
              small>
              {modalityToGerman(modality)}
            </Button>}>
          { Object.values(Modalities).map(modality => {
            return (
              <a
                style={{ color: '#3F8730', fontSize: '14px' }}
                key={modality}
                onClick={() => selectModality(map, infrastructureView, modality, direction) } >
              {modalityToGerman(modality)}
              </a>
            )
          })
          }
        </Dropdown>
      </div>
  }

  // Untested, disabled functionality
  // const [updateFinishedJob, result] = useUpdateFinishedJobMutation()

  // Untested, disabled functionality
  // const markJobsAsSeen = async (mode, jobs) => {
  //   const unseenFinishedSurfaceJobs = jobs.filter(job =>
  //     job.pipeline === Pipelines.Surface &&
  //     job.status === Status.Finished &&
  //     job.mode === mode &&
  //     !('viewed' in job))
  //   if (unseenFinishedSurfaceJobs.length !== 0) {
  //     await Promise.all(unseenFinishedSurfaceJobs.map(async job => {
  //       const body = [{ op: 'replace', path: 'viewed', value: new Date().toISOString() }]
  //       return updateFinishedJob({job.id, body})
  //     }))
  //   }
  // }

  const selectModality = (map, infrastructureView, modality, direction) => {
    if (infrastructureView.modality !== modality) {
      updateFilters(modality, infrastructureView.mode, map, direction)
      dispatch(updateInfrastructureView({ modality }))
    }
  }

  // Untested, disabled functionality
  /* if (view === Views.Infrastructure) {
     markJobsAsSeen(mode, allJobs)
   } */

  /**
   * Defines the element injected into the container
   */
  return (
    <Container view={view}>

      {viewControls}

      {modeSelection()}
      {detectOrphanSurfaces()}

      {directionFilter(map)}
      {modalityFilter()}
      {/* modeSelection(map, mode)}
      {detectOrphanSurfaces(map, logout, mode, view, allJobs)}

      {directionFilter(map, mode, modality, direction, visibleLayerId)}
    {modalityFilter(modality, direction) */}

    </Container>
  )
}

const Container = styled.div`
  display: ${props => props.view === Views.Infrastructure ? 'block' : 'none'};

  height: 100%;
  padding: 0px 10px 0px 10px;
  overflow-y: auto;
`

const ButtonWithBadge = styled.header`
  position: relative;
  display: inline;
`

const updateFilters = (modality, mode, map, direction) => {
  const MODALITY_FILTER = FILTER_MODALITY(modality)
  if (mode === Modes.Segment) {
    const COMBINED_FILTER = direction
      ? ['all', MODALITY_FILTER, FILTER_FORWARD]
      : ['all', MODALITY_FILTER, FILTER_BACKWARD]
    map.setFilter(direction === Directions.Forward
      ? Sources.ForwardArrows
      : Sources.BackwardArrows, MODALITY_FILTER)
    map.setFilter(Sources.Segment, COMBINED_FILTER)
    map.setFilter(Sources.Segment + '_overview', COMBINED_FILTER)
  } else {
    map.setFilter(Sources.H3, MODALITY_FILTER)
    map.setFilter(Sources.H3 + '_overview', MODALITY_FILTER)
  }
}

/**
 * Validates props' types
 */
InfrastructureView.propTypes = {
  logout: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
  viewControls: PropTypes.node.isRequired
}

export default InfrastructureView
