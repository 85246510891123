import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Icon, Preloader } from 'react-materialize'
import { getLocalStorage, showToast, supportEmail } from '../../login/utils.js'
import { getSegments, getH3, deleteSegments, deleteH3 } from '../../DataApi.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { Sources } from '../../constants/Sources'
import normalize from '@mapbox/geojson-normalize'
import { Status } from '../../JobHelpers'
import { Modes } from '../datasets/DatasetsAnalysis'
import { setH3 } from '../../../reducers/h3'
import { setSegments } from '../../../reducers/segments'
import { addJobs } from '../../../reducers/jobs'
import { updateUi } from '../../../reducers/ui'
import { overviewDataFromH3 } from '../../MapBoxHelpers'
import { LocalStorage } from '../../constants/LocalStorage.js'

/**
 * This function component handles deleting processed surfaces.
 *
 * @author Oguz Oztoprak
 * @param deprecated `true` when analysis is based on partly deleted data.
 */

const AnalysisRemoval = ({ deprecated, map, mode, logout }) => {
  /**
   * Used to dispatch calls to redux store.
   */
  const dispatch = useDispatch()

  /**
   * The "isDeleting" state is true while server processes deletion call.
   *
   * Defaults to false.
   */
  const [isDeleting, setIsDeleting] = useState(false)

  /**
   * Updates map and the redux store with new segments
   *
   * @params segments Array of segments.
   */
  const updateSegments = (segments) => {
    dispatch(setSegments(segments.features))
    map.getSource(Sources.Segment).setData(normalize(segments))
    const forwardData = segments.features.filter(feature => feature.properties.forward_moving)
    const backwardData = segments.features.filter(feature => !feature.properties.forward_moving)

    map.getSource(Sources.ForwardArrows).setData(normalize({
      type: 'FeatureCollection',
      features: forwardData
    }))
    map.getSource(Sources.BackwardArrows).setData(normalize({
      type: 'FeatureCollection',
      features: backwardData
    }))
  }

  /**
   * Updates map and the redux store with new points
   *
   * @params h3 Array of h3s.
   */
  const updateH3 = (h3) => {
    dispatch(setH3(h3.features))
    map.getSource(Sources.H3).setData(h3)
    map.getSource(Sources.H3Overview).setData(overviewDataFromH3(h3))
  }

  /**
   * Handles the responses from the surface deletion API by adding the response jobs
   * and updating the surfaces.
   *
   * @params responses The responses from surface deletion API.
   */
  const handleResponse = async (response) => {
    if (response.status === Status.Finished) {
      dispatch(addJobs([response]))

      if (response.mode === Modes.H3) {
        const h3 = await getH3(dispatch, defaultErrorHandling, logout)
        updateH3(h3)
      } else if (response.mode === Modes.Segment) {
        const segments = await getSegments(dispatch, defaultErrorHandling, logout)
        // Unselect selected feature to avoid crash in SegmentDetails [DAT-1436]
        dispatch(updateUi({
          selectedFeature: {
            id: null,
            layerId: null,
            sourceId: null,
            centerLat: null,
            centerLon: null
          }
        }))
        updateSegments(segments)
      }
    } else {
      showToast('Die Analyse konnte nicht gelöscht werden. Bitte kontaktieren Sie ' +
        supportEmail() + ' falls der Fehler wiederholt auftritt.')
    }
  }

  /**
   * Handler which is called when the user chooses to delete analysis based on deleted datasets.
   *
   * @param {*} e the corresponding event
   */
  const onClickDelete = async () => {
    const username = getLocalStorage(LocalStorage.Username)
    try {
      setIsDeleting(true)
      const response = mode === Modes.Segment
        ? await deleteSegments(dispatch, defaultErrorHandling, logout, username)
        : await deleteH3(dispatch, defaultErrorHandling, logout, username)
      setIsDeleting(false)
      handleResponse(response)
    } catch (error) {
      defaultErrorHandling(error, logout)
    } finally {
      setIsDeleting(false)
    }
  }

  const preloader =
    <PreloaderContainer>
      <Preloader active={true} color="green" flashing={false} size="small" />
    </PreloaderContainer>
  return (<div>
      <IconWrapper>
        <Icon small style={{ color: 'gray' }} >{deprecated ? 'warning' : 'delete'}</Icon>
      </IconWrapper>
      <Message>
        {isDeleting
          ? preloader
          : <div>
            <Info>
              {deprecated
                ? 'Analyse basiert auf gelöschten Messungen.'
                : 'Fehlerhaften Datensatz verarbeitet?' }
            </Info>
            <a style={{ cursor: 'pointer', color: '#3F8730' }}
              onClick={onClickDelete}> Analyse löschen</a>
          </div>}
      </Message>
      <div style={{ clear: 'both' }}></div>
    </div>)
}
const IconWrapper = styled.div`
  float: left;
  margin: 10px 10px 10px 10px;
`

const Message = styled.div`
  margin-top: 15px;
`

const Info = styled.div`
`

const PreloaderContainer = styled.div`
  float: left;
  transform: scale(0.8,0.8);
`

/**
 * Validates props' types
 */
AnalysisRemoval.propTypes = {
  deprecated: PropTypes.bool.isRequired,
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired
}

export default AnalysisRemoval
