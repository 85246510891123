import { updateObject } from './utils.js'
import { Modalities } from '../components/sidebar/Modality.js'
import { Modes } from '../components/sidebar/datasets/DatasetsAnalysis'
import { createSlice } from '@reduxjs/toolkit'

/**
 * Reducer code specific to the `infrastructureView`.
 *
 * @author Armin Schnabel
 */

/**
 * The current state of this part of the app.
 *
 * e.g. which data, elements and filters are selected.
 */
const initialState = {
  // The "mode" which is currently selected: "h3" | "segment"
  // Influences which layer to set as visibleLayerId when the infrastructureView is active.
  mode: Modes.H3,

  // use the same modality filter for all modes, as probably expected by the user
  modality: Modalities.All
}

const infrastructureViewSlicer = createSlice({
  name: 'infrastructureView',
  initialState,
  reducers: {
    updateInfrastructureView: (state, action) => {
      return updateObject(state, action.payload)
    }
  }
})

export const { updateInfrastructureView } = infrastructureViewSlicer.actions

export default infrastructureViewSlicer.reducer
