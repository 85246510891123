import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import GenericIcon from '../GenericIcon'
import './JobsProgress.css'
import { Status } from '../../JobHelpers'

/**
 * The default icon color.
 */
const iconColor = '#c1c1c1'

/**
 * Formats a duration in milliseconds into the human readable format `mm:ss`.
 *
 * @param {*} milliseconds The duration to format in milliseconds.
 * @returns The formatted duration.
 */
const humanReadable = (milliseconds) => {
  const seconds = Math.round(milliseconds / 1000)
  const minutes = Math.round(seconds / 60)
  const hours = Math.round(minutes / 60)
  if (hours > 0) {
    return '~ ' + hours + ' h ' + minutes + ' min'
  }
  if (minutes > 0) {
    return '~ ' + minutes + ' min'
  }
  if (seconds > 0) {
    return '< 1 min'
  }
  return ''
}

/**
 * A jobs progress property can be `undefined` if it's not yet running or already finished.
 */
const progress = (job) => {
  return job.progress !== undefined ? job.progress : job.status === Status.Finished ? 1 : 0
}

/**
 * This function component handles the progress of submitted jobs.
 *
 * @author Oguz Oztoprak
 */
const JobsProgress = ({ mode, selected, checkable, jobs, tooltip, status }) => {
  /**
   * `True` if the job is currently running.
   */
  const running = status === Status.Running

  /**
   * Icon which indicates that a newly created job exists which has no progress yet.
   */
  const pendingActionIcon = <GenericIcon
      margin=''
      tooltip={tooltip + (running
        ? ' - Verarbeitung läuft (kann je nach Last mehrere Stunden benötigen)'
        : ' - Verarbeitung eingereiht')}
      icon={running ? 'hourglass_top' : 'pending_actions'}
      float="right"
      color={iconColor} />

  /**
   * Merges the progress in case multiple sub-jobs are injected as `jobs` parameter.
   */
  const combinedProgress = jobs.reduce((mergedProgress, job) =>
    mergedProgress + (progress(job) / jobs.length), 0)

  /**
   * The remaining duration in milliseconds.
   *
   * In case multiple sub-jobs are injected via `jobs` property, the maximum duration is returned.
   */
  const remainingMillis = Math.max(...jobs.map(j => j.remaining !== undefined ? j.remaining : 0))

  /**
   * Return progress bar if a progress is already reported by the API, or a placehole icon instead.
   */
  return combinedProgress !== 0 && running
    ? <Container>
          <ProgressBar title={mode} className="progress jobs-progress-colors">
            <ProgressIndicator
              className={selected && !checkable ? 'determinate determinate-white' : 'determinate'}
              style={{ width: (combinedProgress * 100).toString() + '%' }}/>
          </ProgressBar>
          <TextContent>
            { /* remaining property can be undefined if a job is finished or not yet started */ }
            { humanReadable(remainingMillis)}
          </TextContent>
        </Container>
    : pendingActionIcon
}

const Container = styled.div`
  clear: both;
  display: flex;
  flex-wrap: wrap;
  order: 1;
`

const ProgressBar = styled.div`
  width: 210px;
  margin-right: 1.0em;
  flex: 1;
`

const ProgressIndicator = styled.div`
`

const TextContent = styled.div`
  color: #c1c1c1;
  float: right;
  font-size: 0.8em;
  margin-right: 1.3em;
`

/* const PreloaderContainer = styled.div`
  transform: scale(0.4);
  float: right;
` */

JobsProgress.propTypes = {
  mode: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  checkable: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  jobs: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired
}
export default JobsProgress
