import React, { Component } from 'react'
import DatePicker from './DatePicker'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Modal, Button, Row, Col } from 'react-materialize'
import PropTypes from 'prop-types'
import { showToast } from '../login/utils.js'
import ControlButton from '../ControlButton'

/**
 * Allows to define a time-based filter.
 *
 * @author Oguz Oztoprak
 */
class TimeFilter extends Component {
  render () {
    return (
      <Modal
        actions={[
          <Button key="closing" small waves="light" flat modal='close' node="button"
            style={{ margin: '5px', backgroundColor: '#3F8730', color: 'white' }}
            onClick={this.onAccept} >Zeitraum festlegen</Button>,
          <Button key="clear" small waves="light" flat modal='close' node="button"
            style={{ margin: '5px', backgroundColor: '#3F8730', color: 'white' }}>Abbrechen</Button>
        ]}
        style={{ width: '390px' }}
        options={{ endingTop: '1%' /* Default is 10% */ }}
        trigger={this.trigger()} >

        <TimeframeDefinition>
          <Row style={{ margin: '0px' }}>
            <Col s={12}>
              <Row style={{ margin: '0px' }}>
                <Col s={5}>
                  <DatePicker
                    // used by parent to reset value
                    id={this.props.uniquePrefix + 'FromDate'}
                    label="von"
                    container="body" // To avoid croppying by the <Modal /> container
                    onChange={this.props.setFromDate}
                    defaultDate={this.props.fromDate} />
                </Col>
                <Col s={1} style={{ marginTop: '2rem' }}>
                  -
                </Col>
                <Col s={5}>
                  <DatePicker
                    // used by parent to reset value
                    id={this.props.uniquePrefix + 'ToDate'}
                    label="bis"
                    container="body" // To avoid croppying by the <Modal /> container
                    onChange={this.handleToDateChanged}
                    defaultDate={this.props.toDate} />
                </Col>
              </Row>
            </Col>
          </Row>
        </TimeframeDefinition>

      </Modal>
    )
  }

  /**
   * Generates the name for the datepicker triggering button.
   *
   * @returns The generated name
   */
  currentTriggerText = () => {
    const { from, to } = this.props.time

    /* Check if both of them are not provided */
    const fromDateUnspecified = from === null
    const toDateUnspecified = to === null

    const toDateString = new Date(to).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })

    /* Offset the substracted 1 millisecond. */
    const fromDateString = new Date(from + 1).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    if (!fromDateUnspecified && !toDateUnspecified) {
      return fromDateString + ' - ' + toDateString
    } else if (!fromDateUnspecified && toDateUnspecified) {
      return 'ab ' + fromDateString
    } else if (fromDateUnspecified && !toDateUnspecified) {
      return 'bis ' + toDateString
    } else {
      return 'Zeitraum'
    }
  }

  trigger = () => {
    return (
      <ControlButton
        disabled={this.props.disabled}
        text={this.currentTriggerText()} />
    )
  }

  /**
   * Updates the to-date, e.g. when the user selects a new date.
   *
   * @param toDate the user-selected starting date
   */
  handleToDateChanged = (toDate) => {
    const lastSecondOfTheDay = new Date(toDate.setDate(toDate.getDate() + 1) - 1000)
    this.props.setToDate(lastSecondOfTheDay)
  }

  /**
   * Persists the newly selected time frame.
   *
   * @param e the triggered event from this handler.
   */
  onAccept = (e) => {
    const { fromDate, toDate } = this.props

    /* Check if both of them are not provided */
    const fromDateUnspecified = fromDate === null
    const toDateUnspecified = toDate === null
    if (fromDateUnspecified && toDateUnspecified) {
      showToast('Bitte wählen Sie einen Erfassungszeitraum aus.')
      return
    }

    /* Set time for the one that is provided. */
    const newFrom = fromDateUnspecified ? null : fromDate.getTime()
    const newTo = toDateUnspecified ? null : toDate.getTime()
    this.props.setTime({
      from: newFrom,
      to: newTo,
      active: true
    })
  }
}

const TimeframeDefinition = styled.div``

/**
 * Validates props' types
 */
TimeFilter.propTypes = {
  uniquePrefix: PropTypes.string.isRequired,
  fromDate: PropTypes.object, // can be null
  toDate: PropTypes.object, // can be null
  setFromDate: PropTypes.func.isRequired,
  setToDate: PropTypes.func.isRequired,
  onClickClear: PropTypes.func.isRequired,
  time: PropTypes.object.isRequired,
  setTime: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

/**
 * Describes how to transform the redux store state into the props of this component.
 *
 * @param state: The state stored in redux
 * @param ownProps: The props of this component before they are enhanced by this method
 */
const mapStateToProps = (state) => {
  return {
  }
}

/**
 * Injects functions into the props which update a Redux state.
 *
 * @param {*} dispatch redux store dispatcher which accepts actions and dispatches them
 * to the registered reducer
 */
const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeFilter)
