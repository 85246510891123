import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import Embedded from './components/Embedded'
import LoginForm from './components/login/LoginForm'
import RoutingError from './components/RoutingError'
import { Callback } from './components/login/Callback'
import { fetchConfig } from './config/config.js'
import { Styles } from './components/constants/Styles'

/**
 * Root component of this app. Handles the routing.
 *
 * @author Armin Schnabel
 */

const App = () => {
  const [style, setStyle] = useState(null)

  useEffect(() => {
    fetchConfig().then(config => {
      setStyle(config.REACT_APP_Style)
    })
  }, []) // executes only at the time of initial mounting

  useEffect(() => {
    if (style === Styles.RFR) {
      const favicon = document.getElementById('favicon')
      if (favicon) {
        document.title = 'Ready For Robots'
        favicon.setAttribute('href', process.env.PUBLIC_URL + '/favicon-rfr.png')
      }
    }
  }, [style])

  /**
   * The element injected into the container
   */
  return (
    style === null
      ? 'Loading config ...'
      : <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginForm style={style} />} />
          <Route path='/login' element={<LoginForm style={style} />} />
          <Route path="/login/:message" element={<LoginForm style={style} />} />
          <Route path="/callback" element={<Callback />} />
          <Route path='/map' element={<Dashboard
            accessToken={ process.env.REACT_APP_MAPBOX_TOKEN }
            style={style}
          />} />
          <Route path='/embedded/*' element={<Embedded />} />
          <Route path=':errorRoute' element={<RoutingError />} />
        </Routes>
      </BrowserRouter>
  )
}

export default App
