import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import LogoutButton from './LogoutButton'
import ControlButton from '../ControlButton'
import axios from 'axios'
import { asyncRefreshTokenIfRequired, getLocalStorage, getApiUrl, debug, showToast, supportEmail } from '../login/utils'
import { Endpoints } from '../constants/Endpoints'
import { LocalStorage } from '../constants/LocalStorage'

/**
 * The profile icon usually shown in the top right of the header.
 */
const Profile = ({ logout, backgroundColor, right, top }) => {
  const [showProfileIcon, setShowProfileIcon] = useState(true)
  const [showMenu, setShowMenu] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [showFinalWarning, setShowFinalWarning] = useState(false)
  const [deleting, setDeleting] = useState(false)
  // Navigate hook, to forward the user, e.g. upon authentication error
  const navigate = useNavigate()

  const username = getLocalStorage(LocalStorage.Username)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  const confirmDeleteAccount = () => {
    setShowProfileIcon(false)
    setShowMenu(false)
    setShowWarning(true)
  }
  const handleDeleteAccount = () => {
    setShowFinalWarning(true)
    setShowWarning(false)
    setShowMenu(false)
  }
  const cancelDeleteAccount = () => {
    setShowWarning(false)
    setShowMenu(false)
    setShowProfileIcon(true)
  }
  const cancelFinalDeleteAccount = () => {
    setShowFinalWarning(false)
    setShowProfileIcon(true)
  }
  const deleteAccountNow = async () => {
    setDeleting(true)

    // Send Account Deletion Request
    await asyncRefreshTokenIfRequired()
    const headers = { Authorization: 'Bearer ' + getLocalStorage(LocalStorage.AccessToken) }

    // Synchronous call to wait for the result
    const userId = getLocalStorage(LocalStorage.UserId)
    try {
      const response = await axios.delete(getApiUrl() + Endpoints.Users + '/' + userId, { headers })
      if (response.status === 202) {
        localStorage.clear()
        navigate('/')
        return true
      } else {
        throw Error('User deletion request not successful: ' + response.status)
      }
    } catch (error) {
      if (debug()) {
        console.log('User deletion failed: ' + error)
      }
      const message = 'Nutzer löschen fehlergeschlagen. Bitte laden Sie die Seite neu.  ' +
      'Falls der Zustand anhält bitte ' + supportEmail() + ' kontaktieren.'
      showToast(message)
      return false
    }
  }

  return (
    <div>
    {showProfileIcon && (
      <ControlButton
        onClick={ toggleMenu }
        icon="account_circle"
        active={showMenu}
        text="Profil"
        tooltip="Nutzerprofil" />
    )}
    {showMenu && (
      <Menu backgroundColor={backgroundColor} right={right} top={top}>
        <p>{username} (<a onClick={confirmDeleteAccount}>Account löschen</a>)</p>
        <LogoutButton logout={logout} />
      </Menu>
    )}
    {showWarning && (
      <Warning backgroundColor={backgroundColor} right={right} top={top}>
      <p>Sind Sie sich sicher, dass Sie ihren Account löschen wollen?</p>
      <ControlButton
        onClick={handleDeleteAccount}
        margin="-3px 10px 0 0"
        active={true}
        backgroundColor="red"
        text="Daten löschen" />
      <ControlButton
        onClick={cancelDeleteAccount}
        margin="-3px 0 0 0"
        active={false}
        text="Abbrechen" />
      </Warning>
    )}
    {showFinalWarning && (
      <FinalWarning backgroundColor={backgroundColor} right={right} top={top}>
      <ControlButton
        onClick={deleteAccountNow}
        margin="-3px 10px 0 0"
        active={!deleting}
        disabled={deleting}
        backgroundColor="red"
        text="Daten und Account unwiderruflich löschen" />
      <ControlButton
        onClick={cancelFinalDeleteAccount}
        margin="-3px 0 0 0"
        active={false}
        disabled={deleting}
        text="Abbrechen" />
      </FinalWarning>
    )}
    </div>
  )
}

const Menu = styled.div`
  color: #777777;
  position: absolute;
  background: ${({ backgroundColor }) => (backgroundColor || 'transparent')};
  top: ${({ top }) => (top || '-1px')};
  right: ${({ right }) => (right || '110px')};
  width: 650px;
  padding: 20px 10px 20px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    margin: 0;
    padding-right: 10px;
  }

  a {
    color: red;
    cursor: pointer;
  }
`

const Warning = styled.div`
  position: absolute;
  background: ${({ backgroundColor }) => (backgroundColor || 'transparent')};
  top: ${({ top }) => (top || '-5px')};
  right: ${({ right }) => (right || '-4px')};
  width: 700px;
  padding: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    margin: 0;
    padding-right: 10px;
    float: left;
  }
`

const FinalWarning = styled.div`
  position: absolute;
  background: ${({ backgroundColor }) => (backgroundColor || 'transparent')};
  top: ${({ top }) => (top || '-5px')};
  right: ${({ right }) => (right || '-4px')};
  width: 500px;
  padding: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    margin: 0;
    padding-right: 10px;
    float: left;
  }
`

/**
 * Validates props' types
 */
Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string
}

export default Profile
