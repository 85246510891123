import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TermsOfUse from './TermsOfUse'
import {
  setLocalStorage, showToast, autoLogin, getConfig, NoHashQueryStringUtils,
  termsAccepted, loggedIn, storeTokens, mockAuth, asyncFetchOAuthConfiguration
} from './utils.js'
import {
  RedirectRequestHandler, LocalStorageBackend, DefaultCrypto, AuthorizationRequest
} from '@openid/appauth'
import logo from '../../resources/logo_easy-riding.png'
import logoRfr from '../../resources/logo_small_rfr.svg'
import LoginButton from './LoginButton'
import Footer from '../Footer'
import { Row, Col } from 'react-materialize'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import { LocalStorage } from '../constants/LocalStorage'
import { Styles } from '../constants/Styles'

const STATUS_SUBMITTING = 'SUBMITTING'

const authorizationHandler = new RedirectRequestHandler(
  new LocalStorageBackend(),
  new NoHashQueryStringUtils(),
  window.location, new DefaultCrypto()
)

/**
 * Logs in automatically or offers a login form.
 *
 * @author Armin Schnabel
 */
const LoginForm = (props) => {
  const [showTerms, setShowTerms] = useState(false)
  const [status, setStatus] = useState(null)
  const navigate = useNavigate()
  const { message } = useParams()

  /**
   * Show terms or attempt auto-login when user enters page.
   */
  useEffect(() => {
    async function autoLoginSync () {
      const alreadyLoggedIn = await autoLogin(navigate, '/map', '/')
      // Skip login button on RFR style
      if (!alreadyLoggedIn && props.style === Styles.RFR) {
        login(null)
      }
    }

    // If user just logged in and was forwarded by Callback.js, show terms
    if (loggedIn() && !termsAccepted()) {
      setShowTerms(true)
    } else {
      autoLoginSync()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Handler called when user interacts with the terms page.
   *
   * @param {*} termsAccepted `True` if the user accepted the terms, `false` otherwise.
   */
  const termsHandling = (termsAccepted, errorTrackingAccepted) => {
    if (termsAccepted) {
      setLocalStorage(LocalStorage.TermsAccepted, termsAccepted)
      setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)
      navigate('/map')
    } else {
      showToast('Um diesen Service zu nutzen müssen Sie die die Datenschutzerklärung akzeptieren.')
      setShowTerms(false)
    }
  }

  /**
   * Authenticates with the API using the provided credentials from the login form.
   *
   * @param {*} e the form submit event containing the credentials
   */
  const login = async (e) => {
    if (e !== null) {
      e.preventDefault()
    }
    setStatus(STATUS_SUBMITTING)

    if (mockAuth()) {
      console.log('Mocking login ...')
      setLocalStorage(LocalStorage.AuthServiceConfiguration, { mocked: true })
      storeTokens({
        expiresIn: (new Date().getTime() / 1000.0) + 3600,
        accessToken: 'eyMockAccessToken',
        refreshToken: 'eyMockRefreshToken'
      })
      window.location.href = '/Callback?code=mock'
      return
    }

    try {
      const response = await asyncFetchOAuthConfiguration()

      // Authorize user by forwarding the user to the OAuth server
      const config = getConfig()
      await authorizationHandler
        .performAuthorizationRequest(response, new AuthorizationRequest({
          client_id: config.clientId,
          redirect_uri: config.redirectUri,
          scope: 'openid email profile',
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          state: undefined
        }))

      // After successful auth the user is forwarded to `Callback.js`
    } catch (error) {
      console.log(error)
      setStatus(null)
      defaultErrorHandling(error, () => {
        // No need to cleanup as the user is not logged in (dashboard not loaded)
        // This handler should never be called, as we handle case 401 a few lines above!
        throw new Error('Unexpected call of logout handler')
      })
    }
  }

  /**
   * The UI to be rendered.
   */
  return (
    <div>
      <Container>

        <form action="POST" onSubmit={login}>
          { props.style === Styles.Cyface
            ? <center><img src={logo} alt='Cyface Logo' style={{ marginBottom: '20px' }}
              width={160} height={48}/></center>
            : props.style === Styles.RFR
              ? <center><img src={logoRfr} alt='Ready for Robots Logo'
                style={{ marginBottom: '20px' }} width={200} /></center>
              : ''
          }

          <Row>
            <Col s={12}>
              <Success $display={message}>
                { 'Unbekannter Nachrichten-Code' }
              </Success>

              <LoginButton
                name="login-button"
                text="Anmelden"
                disabled={status === STATUS_SUBMITTING}
                width='100%' />
            </Col>
          </Row>
        </form>

      </Container>

      {showTerms ? <TermsOfUse handleTerms={termsHandling} /> : null}

      <Footer right="calc((100vw - 238px)/2)" />
    </div>
  )
}

/**
 * The width if aligned with the Captcha(removed) width (300 + 2*10.5 input-padding).
 */
const Container = styled.div`
  position: fixed; // relative to the viewport
  top: calc(50vh - 39px);
  left: 50vw;
  width: max(50vw, 321px);
  max-width: 500px;
  transform: translate(-50%, -50%);
  font-family: 'Helvetica', 'Arial', sans-serif;
  a { color: rgb(63, 135, 48) }
`

/**
 * Using transient props ($) to support boolean `display` value.
 */
const Success = styled.div`
  color: green;
  padding: 5px;
  display: ${props => props.$display ? 'block' : 'none'};
`

/**
 * Validates props' types
 */
LoginForm.propTypes = {
  style: PropTypes.string.isRequired
}

export default LoginForm
