import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Collapsible, CollapsibleItem, Icon } from 'react-materialize'

/**
 * Text which can be shown at the bottom of the sidebar for a quick information about
 * the currently shown view.
 *
 * @author Armin Schnabel
 */
const InfoText = ({ text }) => {
  /**
   * The element injected into the container
   */
  const icon = useMemo(() => <Icon>help_outline</Icon>, [])
  return (
    <Container>
      <Collapsible accordion={false}>
        <CollapsibleItem
          expanded={false}
          header="Hilfe zu dieser Ansicht"
          icon={icon}
          node="div">
          <Content>{text}</Content>
        </CollapsibleItem>
      </Collapsible>
    </Container>
  )
}

InfoText.propTypes = {
  text: PropTypes.string.isRequired
}

const SIDEBAR_ZINDEX = 1000
const Container = styled.div`
  width:        400px;
  max-width:    80%;

  position:     fixed; // relative to the viewport
  bottom:       0px;
  z-index:      calc(${SIDEBAR_ZINDEX} + 1);

  color:        grey;
  background:   white;
`

const Content = styled.div`
  padding: 20px;
`

export default InfoText
