import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'react-materialize'

/**
 * To turn different settings on or off.
 *
 * @author Armin Schnabel
 */
// stateless
const ControlButton = ({ id, text, onClick, icon, disabled /* destruct */ }) => {
  ControlButton.propTypes = {
    id: PropTypes.any,
    text: PropTypes.any,
    icon: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }

  /**
   * The element injected into the container
   */
  return (
    <Button
      style={{ marginLeft: '0px', marginBottom: '5px', backgroundColor: '#3F8730', width: '100%' }}
      onClick={onClick}
      disabled={disabled}
      node="button"
      small
      icon={icon !== undefined ? <Icon>{icon}</Icon> : false}
      waves="light">
      {text}</Button>
  )
}

export default ControlButton
