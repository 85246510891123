import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import GenericIcon from '../GenericIcon'
import modalityIcon, { Modalities, modalityToGerman } from '../Modality'
import Jobs from './Jobs'
import CustomCollectionItem from '../CustomCollectionItem'
import { showToast, supportEmail } from '../../login/utils.js'
import normalize from '@mapbox/geojson-normalize'
import { filterLatestJobs, latestProcessedJobDate, Status, isStatusFinal } from '../../JobHelpers'
import { States, updateDatasetsView } from '../../../reducers/datasetsView'
import { Views } from '../../constants/Views'
import { Preloader, Collection } from 'react-materialize'
import './Datasets.css'
import { Modes, Pipelines } from './DatasetsAnalysis'
import playStoreLogo from '../../../resources/play-store.png'
import appStoreLogo from '../../../resources/app-store.png'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { areTagKeysEqual } from '../../TagHelpers'
import { selectJobsById, selectAllJobs } from '../../../reducers/jobs'
import { selectMeasurementsById, addTagsToMeasurements, selectAllMeasurements } from '../../../reducers/measurements'
import { getGeoLocations, updateMeasurementTag } from '../../DataApi'
import { Sources } from '../../constants/Sources'
import { nbsp, todayIcon, groupItemsByDate, groupNameTranslater } from '../DatasetsGroupingHelpers.js'
// TODO [RFR-301]: import DatasetTags from './DatasetTags'

// State selector, has no dependencies so it's defined outside the component
const selectMeasurementById = (state, measurementId) => selectMeasurementsById(state, measurementId)

const ExpandIcon = <GenericIcon
      position="absolute"
      tooltip='einblenden'
      icon='expand_more'
      color='grey'/>
const UnexpandIcon = <GenericIcon
      position="absolute"
      tooltip='ausblenden'
      icon='expand_less'
      color='grey'/>

/**
 * The data sets shown in the `Views.Datasets` of the sidebar, which currently displays
 * measurements.
 *
 * @author Armin Schnabel
 */
const Datasets = ({
  map: initialMap, active,
  logout, modality, time, tags, expanded, addExpanded, removeExpanded,
  selected, addSelected, removeSelected, visibleLayerId
}) => {
  /**
   * We're defining 'map' as a state variable to manage it within this component. This way, we can
   * inject it into the 'showOnMap' function without causing an endless loop in the 'useEffect' that
   * has 'map' as a dependency.
   * Normally, if 'showOnMap' modifies 'map' and 'map' is a dependency for 'useEffect', it creates a
   * situation where the 'useEffect' endlessly triggers due to 'map' constantly changing. By using
   * 'useState', we can manage 'map' and control when and how it changes, thus avoiding this issue.
   */
  const [map] = useState(initialMap)

  // Contains UIDs of groups that are currently expanded
  const [expandedGroupUIDs, setExpandedGroupUIDs] = useState([])

  const dispatch = useDispatch()

  const state = useSelector(state => state)
  const getJobsById = useCallback((id) => selectJobsById(state, id), [state])

  const selectedMeasurementId = selected[selected.length - 1]
  const selectedDataset = useSelector(state => selectMeasurementById(state, selectedMeasurementId))

  const measurements = useSelector(selectAllMeasurements)

  const getAllJobs = useSelector(selectAllJobs)
  const ui = state.ui

  const modalityFilter = useCallback((d) => {
    return d.modality === modality || modality === Modalities.All
  }, [modality])

  const timeFilter = useCallback((d) => {
    const { from, to } = time
    if (from) {
      if (to) {
        return d.startTimestamp < to && d.endTimestamp > from
      } else {
        return d.endTimestamp > from
      }
    } else if (to) {
      return d.startTimestamp < to
    }
    return true
  }, [time])

  const tagFilter = useCallback((d) => {
    const activeTags = tags.activeTags
    const ret = activeTags.every(tag => {
      const possibleValues = activeTags.filter(otherTag =>
        areTagKeysEqual(otherTag, tag)).map(t => Object.values(t)[0])
      return d.tags !== undefined && possibleValues.includes(d.tags[Object.keys(tag)[0]])
    })
    return ret
  }, [tags.activeTags])

  const getCombinedFilter = useCallback(() => {
    const tagFilterActive = tags.activeTags.length !== 0
    if (time.active && tagFilterActive) {
      return (d) => modalityFilter(d) && timeFilter(d) && tagFilter(d)
    } else if (tagFilterActive) {
      return (d) => modalityFilter(d) && tagFilter(d)
    } else if (time.active) {
      return (d) => modalityFilter(d) && timeFilter(d)
    } else {
      return (d) => modalityFilter(d)
    }
  }, [modalityFilter, tagFilter, tags.activeTags.length, time.active, timeFilter])

  /**
   * Filters items based on the currently slected filters (like modality and time range).
   *
   * @param {*} items The items to filter.
   * @returns The filtered items.
   */
  const filter = useCallback((items) => {
    const combinedFilter = getCombinedFilter()
    return items.filter(combinedFilter)
  }, [getCombinedFilter])

  const filteredMeasurements = useMemo(() => {
    return filter(measurements)
  }, [filter, measurements])

  const userItems = useMemo(() => {
    const userItemsInternal = {}

    filteredMeasurements.forEach(measurement => {
      const user = measurement.user
      if (userItemsInternal[user] === undefined) {
        userItemsInternal[user] = [measurement]
      } else {
        userItemsInternal[user].push(measurement)
      }
    })
    return userItemsInternal
  }, [filteredMeasurements])

  /**
   * Checks if a dataset is selectable.
   *
   * As this method is passed down to a child component, it's wrapped in `useCallback`.
   *
   * @param {*} dataset The dataset to check.
   * @param {*} informUser `true` if the user should be informed when the dataset is not selectable.
   * @returns `true` if the dataset is selectable.
   */
  const selectable = useCallback((dataset, informUser = true) => {
    if (active === States.SelectRemoveDatasets) {
      const jobs = dataset.jobs.map(jobId => getJobsById(jobId))
      const latestJobs = filterLatestJobs(jobs)
      const nonFinalJobs = latestJobs.filter(j => !isStatusFinal(j.status))
      const hasNonFinalJobs = nonFinalJobs.length > 0
      if (hasNonFinalJobs) {
        if (informUser) {
          showToast('Messung besitzt noch laufende Aufträge und kann noch nicht gelöscht werden.')
        }
        return false
      }
      return true // triggers `CustomCollectionItem.setChecked()`
    } else if (active === States.SelectAnalyzeDatasets) {
      const modality = dataset.modality
      const deserialized = dataset.deserialized
      // backend only suports TRANSFER_FILE_FORMAT_VERSION = 3
      const unsupportedVersion = dataset.formatVersion !== 3
      if (!deserialized || unsupportedVersion) {
        if (informUser) {
          const message = unsupportedVersion
            ? 'Messung wurde in einem Format aufgezeichnet das nicht unterstützt wird. Bei Fragen '
            : 'Messung ist noch nicht bereit. Falls der Zustand anhält, '
          showToast(message + 'bitte ' + supportEmail() + ' kontaktieren.')
        }
        return false
      }
      if (modality === Modalities.Car || modality === Modalities.Bicycle) {
        return true // triggers `CustomCollectionItem.setChecked()`
      } else {
        if (informUser) {
          showToast(modalityToGerman(modality) + ' wird noch nicht unterstützt')
        }
        return false
      }
    }
  }, [active, getJobsById])

  /**
   * Unselects the specified dataset.
   *
   * @param {*} id The id of the item to hide.
   * @param unselect `True` if the items should be unselected (default behavior).
   */
  const hideItem = useCallback((map, selectedDataset, id, unselect = true) => {
    if (map.getLayer(id) && map.getSource(id)) {
      map.setLayoutProperty(id, 'visibility', 'none')
      map.removeLayer(id)
      map.removeSource(id)
    }
    // Unselect item not yet loaded into map
    // Reason: Another item was selected
    // before the previous one is loaded into map.
    // Unselect item if requested or not selectable (e.g. "on foot") [DAT-1459]
    if (unselect || !selectable(selectedDataset, false)) {
      removeSelected(id)
    }
  }, [removeSelected, selectable])

  /**
   * Unselects all currently selected items.
   *
   * Wrapped in `useCallback`: function is a event handler and a dependency of `useEffect`.
   *
   * @param e the map click event or `null` if called manually
   * @param unselect `True` if the items should be unselected (default behavior).
   */
  const resetMap = useCallback((e, unselect = true) => {
    if (ui.view === Views.Datasets && selected.length > 0) {
      selected.forEach(id => hideItem(map, selectedDataset, id, unselect))
    }
  }, [ui, map, selectedDataset, selected, hideItem])

  useEffect(() => {
    map.on('click', resetMap)

    // Return a cleanup function to be run when the effect is run again
    // or when the component unmounts
    return () => {
      map.off('click', resetMap)
    }
  }, [resetMap, map]) // only register this handler once

  /**
   * Is triggered when this component is re-rendered.
   */
  useEffect(() => {
    if (
      // Hide tracks from map when the analysis selection is active
      active === States.ChooseAnalyzeMode ||
      active === States.SelectAnalyzeDatasets ||
      active === States.SelectRemoveDatasets ||
      active === States.AddRemoveTagsFromMeasurements
    ) {
      resetMap(null, false) // clears map but does not unselect the items
    }
  }, [active, resetMap])

  /**
   * TODO
   *
   * As this method is passed down to a child component, it's wrapped in `useCallback`.
   */
  const addTagsToMeasurementsCallBack = useCallback(async (item, tag) => {
    dispatch(addTagsToMeasurements([item.id], tag))

    await updateMeasurementTag(dispatch, defaultErrorHandling, logout, item, tag)
  }, [dispatch, logout])

  /**
   * Wrapped with useCallbacks as this is a dependeny of another function which uses useCallback.
   */
  const showDatasetWithDashedLines = useCallback(async (dataset) => {
    const currentId = dataset.id.toString()
    const lineStringGeometry = dataset.geometry
    if (lineStringGeometry.coordinates.length === 0) {
      showToast('Messung enthält keine Geodaten!')
      removeSelected(dataset.id)
      return
    }

    map.addSource(currentId, {
      type: 'geojson',
      data: lineStringGeometry
    })
    map.addLayer({
      id: currentId,
      type: 'line',
      source: currentId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-width': 3,
        'line-color': '#3F8730',
        'line-dasharray': [2, 2]
      }
    })

    map.setLayoutProperty(currentId, 'visibility', 'visible')
    map.fitBounds(lineStringGeometry.coordinates,
      { padding: { top: 25, bottom: 25, left: 25, right: 25 }, maxZoom: 17 })
  }, [map, removeSelected])

  /**
   * Shows a specified dataset on the map.
   *
   * @param {*} dataset The dataset to show.
   */
  const showOnMap = useCallback(async (dataset, logout, map) => {
    if (!dataset.deserialized) {
      showDatasetWithDashedLines(dataset)
    } else {
      const currentId = dataset.id.toString()

      const paddingBottom = 25 + 275 + 30 // 25 track padding, details box size + box space bottom

      dispatch(updateDatasetsView({ datasetLoading: true }))

      // cancel any previous requests
      const locations = await getGeoLocations(dispatch, defaultErrorHandling, logout, dataset)

      if (!locations) {
        return
      }

      // If the dataset is already old(another dataset is selected already) and removed.
      /* if (!selected.some(id => id === dataset.id)) {
        dispatch(updateDatasetsView({ datasetLoading: false }))
        return
      } */

      if (locations.features.length !== 1) {
        showToast('Messung enthält keinen Track!')
        showDatasetWithDashedLines(dataset)
        dispatch(updateDatasetsView({ datasetLoading: false }))
        return
      }

      const normalizedData = normalize(locations)
      const coordinates = normalizedData.features[0].geometry.coordinates
      const coordinatesArray = [].concat.apply([], coordinates)

      const bounds = findBoundingBox(coordinatesArray)

      if (!map.getSource(currentId)) {
        map.addSource(currentId, {
          type: 'geojson',
          data: normalizedData
        })
      }

      if (!map.getLayer(currentId)) {
        map.addLayer({
          id: currentId,
          type: 'line',
          source: currentId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-width': 3,
            'line-color': '#3F8730'
          }
        })
      }

      // showOnMap is also called when switching away from dataset view.
      // i.e. check if we are still in SHOW_DATASETS view, if not hide.
      if (visibleLayerId === Sources.Datasets) {
        map.fitBounds(bounds,
          { padding: { top: 25, bottom: paddingBottom, left: 25, right: 25 }, maxZoom: 16 })
        map.setLayoutProperty(currentId, 'visibility', 'visible')
      } else {
        map.setLayoutProperty(currentId, 'visibility', 'none')
      }

      dispatch(updateDatasetsView({ datasetLoading: false }))
    }
  }, [visibleLayerId, dispatch, showDatasetWithDashedLines])

  const findBoundingBox = (coordinatesArray) => {
    // [[minLng, minLat],[maxLng, maxLat]]
    const initialMinMax = [
      [coordinatesArray[0][0], coordinatesArray[0][1]],
      [coordinatesArray[0][0], coordinatesArray[0][1]]
    ]
    const bounds = coordinatesArray.reduce(
      (previousMinMax, minMax) => {
        const minLng = minMax[0] < previousMinMax[0][0] ? minMax[0] : previousMinMax[0][0]
        const maxLng = minMax[0] > previousMinMax[1][0] ? minMax[0] : previousMinMax[1][0]

        const minLat = minMax[1] < previousMinMax[0][1] ? minMax[1] : previousMinMax[0][1]
        const maxLat = minMax[1] > previousMinMax[1][1] ? minMax[1] : previousMinMax[1][1]
        return [[minLng, minLat], [maxLng, maxLat]]
      }, initialMinMax)
    return bounds
  }

  useEffect(() => {
    if (selected.length > 0 && selectedDataset && active === States.ShowDatasets) {
      showOnMap(selectedDataset, logout, map)
    }
  }, [selected, map, showOnMap, logout, selectedDataset, active])

  /**
   * Selects the clicked dataset.
   *
   * As this method is passed down to a child component, it's wrapped in `useCallback`.
   *
   * @param {*} measurement The item to be selected.
   */
  const onDatasetClicked = useCallback((e, measurement) => {
    e.preventDefault()
    if (active !== States.AddRemoveTagsFromMeasurements) {
      if (selected.some(id => id === measurement.id)) {
        return
      }

      // Unselect items which are still active
      if (selected.length > 0) {
        selected.forEach(id => hideItem(map, selectedDataset, id))
      }

      // Select and render the dataset
      addSelected(measurement.id)
      // showOnMap(measurement) is called via useEffect as `selected` is updated asynchronously
    }
  }, [map, active, selected, addSelected, selectedDataset, hideItem])

  /**
   * Returns a list of `CustomCollectionItem`s, each element representing one dataset entry.
   *
   * @param {*} items The items to return.
   * @returns The `CustomCollectionItem`s.
   */
  const list = (items) => {
    if (items.length === 0) {
      return (<p className="center">Keine Datensätze gefunden.</p>)
    }
    const latestSegmentDeletionDate = latestProcessedJobDate(getAllJobs
      .filter(j => j.pipeline === Pipelines.Delete &&
        j.mode === Modes.Segment &&
        j.status === Status.Finished))

    const latestH3DeletionDate =
      latestProcessedJobDate(
        getAllJobs
          .filter(j => j.pipeline === Pipelines.Delete &&
            j.mode === Modes.H3 &&
            j.status === Status.Finished))

    // Return one component per dataset
    return items.map(dataset => {
      const formattedLength = Math.round((Math.round(dataset.length / 10) / 10)) / 10
      const isSelected = selected.some(id => id === dataset.id)
      const checkable = active === States.SelectAnalyzeDatasets ||
        active === States.SelectRemoveDatasets
      const selectedInShowDatasets = isSelected && active === States.ShowDatasets
      const disabled = active === States.ChooseAnalyzeMode ||
        active === States.DeleteDatasets ||
        active === States.SubmittingAnalyzeJobs
      const jobs = dataset.jobs.map(jobId => getJobsById(jobId))
      const latestJobs = filterLatestJobs(jobs)
      const segmentBasedJobs = latestJobs.filter(j => (j.mode === Modes.Segment ||
                                                       j.mode === Modes.SegmentHistory))
      const nonSegmentBasedJobs = latestJobs.filter(j => j.mode !== Modes.Segment &&
                                                         j.mode !== Modes.SegmentHistory)

      const content =
        <Content>
          { modalityIcon(dataset.modality, 'scale(1.0)', '-9px', '0px 0px 0px 0px',
            selectedInShowDatasets ? 'white' : '#c1c1c1') }

          <Text>
            { showMeasuredDuration(dataset) }
            {' '}
            { '(' + formattedLength + ' km)' }
          </Text>

          { /* Finished non-segment jobs */ }
          <Icons>
            { // Mark jobs of measurements which are not deserialized (yet)
              dataset.deserialized || dataset.length === 0
                ? ''
                : <GenericIcon
              margin='1px 0px -1px 0px'
              tooltip="Nicht zur Anzeige bereit"
              icon="priority_high"
              color={selectedInShowDatasets ? 'white' : '#c1c1c1'} /> }

            { // Mark jobs of measurements in a unsupported file format version
              // backend only suports TRANSFER_FILE_FORMAT_VERSION = 3
              dataset.formatVersion === 3
                ? ''
                : <GenericIcon
              margin='1px 0px -1px 0px'
              tooltip={'Datensatzversion nicht unterstützt: ' + dataset.formatVersion}
              icon="priority_high"
              color={selectedInShowDatasets ? 'white' : '#c1c1c1'} /> }

            { <Jobs
              selected={isSelected}
              checkable={checkable}
              jobs={ nonSegmentBasedJobs.filter(j => j.status !== 'RUNNING') }
              lastH3Deletion={latestH3DeletionDate}
              lastSegmentDeletion={latestSegmentDeletionDate} /> }
          </Icons>

          { /* Segment jobs */ }
            { <Jobs
                selected={isSelected}
                checkable={checkable}
                jobs={ segmentBasedJobs }
                lastH3Deletion={latestH3DeletionDate}
                lastSegmentDeletion={latestSegmentDeletionDate} /> }
          { /* Running non-segment jobs */ }
            { <Jobs
                selected={isSelected}
                checkable={checkable}
                jobs={ nonSegmentBasedJobs.filter(j => j.status === 'RUNNING') }
                lastH3Deletion={latestH3DeletionDate}
                lastSegmentDeletion={latestSegmentDeletionDate} /> }
            { active === States.DeleteDatasets && isSelected
              ? <DeletionJob>
                  <Preloader
                    active={true}
                    color="red"
                    flashing={false}
                    size="small" />
                </DeletionJob>
              : '' }
          {/* TODO [RFR-301]:
          const setTagsToMeasurements = dispatch(setTagsToMeasurements())
          <DatasetTags dataset={dataset}
                       measurement={measurementsById(dataset.id)}
                       active={active}
                       setTagsToMeasurements={setTagsToMeasurements}
                       logout={logout}/> */}

        </Content>

      return (
        <CustomCollectionItem
          active={ selectedInShowDatasets }
          selectable={selectable}
          checkable={ checkable }
          disabled={ disabled }
          onClick={onDatasetClicked}
          key={dataset.id}
          item={dataset}
          selected={isSelected}
          addSelected={addSelected}
          removeSelected={removeSelected}
          addTagsToMeasurements={addTagsToMeasurementsCallBack}
          text={content} />
      )
    })
  }
  const groupUserItems = (userItems, multiUser) => {
    const groupedItemsOfAllUsers = Object.entries(userItems).map(([user, items]) => {
      return groupItemsByDate(items)
    })
    const users = Object.keys(userItems)
    return groupedItemsOfAllUsers.map((groupedItemsOfUser, userIndex) => {
      // groupIndex = 0 is today, groupIndex = 1 is last week, ...
      return Array.from(groupedItemsOfUser).map(([name, itemsInAGroup], groupIndex) => {
        const groupUID = users[userIndex] + groupIndex
        const header = <Header>
                {todayIcon(multiUser)}
                {nbsp.repeat(5)}
                {groupNameTranslater[name] || name}
                {ExpandIcon}
              </Header>
        const unexpandedHeader = <Header>
                {todayIcon(multiUser)}
                {nbsp.repeat(5)}
                {groupNameTranslater[name] || name}
                {UnexpandIcon}
              </Header>
        return itemsInAGroup.length !== 0
          ? !expandedGroupUIDs.includes(groupUID)
              ? <ul key={groupIndex} className='collection with-header'>
          <li className='collection-header' style={{ cursor: 'pointer' }} onClick={() =>
            setExpandedGroupUIDs((prevExpandedGroupUIDs) => {
              return [...prevExpandedGroupUIDs, users[userIndex] + groupIndex]
            })}>
              <div>
                {unexpandedHeader}{nbsp}
                <Badge>{itemsInAGroup.length}</Badge>
              </div>
          </li>
        </ul>
              : <Collection
                key={groupUID}
                header={
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setExpandedGroupUIDs(
                      (prevExpandedGroupUIDs) => {
                        return prevExpandedGroupUIDs.filter((presentGroupUIDs) =>
                          presentGroupUIDs !== groupUID)
                      }
                    )}>
                    {header}
                    <Badge>{itemsInAGroup.length}</Badge>
                  </div>
                }>
        {list(itemsInAGroup)}
      </Collection>
          // for the case of it.length === 0
          : <div key={groupIndex}></div>
      })
    })
  }

  const userBased = (userItems, multiUser) => {
    if (userItems.length === 0) {
      return (<p className="center">Keine Messungen gefunden.</p>)
    }
    const groupedLists = groupUserItems(userItems, multiUser)
    /* Cannot use react-materialize `Collapsible`:
      - when a dataset is selected this class is re-rendered
      - this forces the sidebar-scrollbar to jump back to the top
      - the reason is probably that `Collapsible` is expanded asynchronously */
    return Object.entries(userItems).map(([user, items], index) => {
      return !expanded.includes(user)
        ? <ul key={user} className='collection with-header user-collection'>
          <li
            className='collection-header'
            style={{ cursor: 'pointer' }}
            onClick={() => addExpanded(user)}>
            <div className='user-collection-header'>
              <Header>{user}{ExpandIcon}</Header>{nbsp}
              <Badge>{items.length}</Badge>
            </div>
          </li>
        </ul>
        : <Collection
      /* Assigning a unique, static key to the child components to avoid
       unnecessary re-rendering [RFR-137] */
          key={user}
          className='user-collection'
          header={
            <div
              className='user-collection-header'
              style={{ cursor: 'pointer' }}
              onClick={() => removeExpanded(user)}>
              <Header>{user}{UnexpandIcon}</Header>
              <Badge>{items.length}</Badge>
            </div>
          }>
        { groupedLists[index] }
      </Collection>
    })
  }

  /**
   * Generates a text which represents the measurement duration.
   *
   * @param {*} dataset The dataset to generate the text for.
   * @returns The generated text.
   */
  const showMeasuredDuration = (dataset) => {
    const startDate = new Date(dataset.startTimestamp)
    const endDate = new Date(dataset.endTimestamp)
    const uploadDate = new Date(dataset.uploadDate)
    // When no GNSS data was collected
    if (dataset.startTimestamp === undefined) {
      return ('Uploaded: ' + uploadDate.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }))
    }
    // legal duration
    if (startDate <= endDate) {
      const startDateString = startDate.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
      return startDateString
    }
    throw Error('Unexpected dataset duration')
  }

  /**
   * The element injected into the container
   */
  const render = () => {
    const multiUser = Object.keys(userItems).length > 1

    return (active !== States.AddRemoveAvailableTags &&
            active !== States.RemoveAvailableTags &&
            active !== States.AddAvailableTags
      ? <Container>
        <div>
          <label>Messungen {multiUser ? '(Gruppiert nach Nutzer)' : '' }</label>
          <GenericIcon
            position="static"
            color="#c1c1c1"
            icon="help_outline"
            float='right'
            transform="scale(0.8)"
            tooltip={'Hier werden alle übertragenen Messungen aufgelistet und deren ' +
              'Verarbeitungsstatus angezeigt.'} />
        </div>
        { /* clear float from GenericIcon */ }
        <div style={{ clear: 'both' }}>
          {
            multiUser
              ? <div>{ userBased(userItems, multiUser) }</div>
              : <Collection>{ groupUserItems(userItems, multiUser) }</Collection>
          }
          {
            filteredMeasurements.length > 0
              ? ''
              : <center style={{ padding: '40px 0px 0px 0px' }}>
              Laden Sie unsere App herunter um Daten zu erfassen:
              <center style={{ margin: '30px 0px 0px 20px' }}>
                <a style={{ color: '#3F8730' }}
                  href="https://play.google.com/store/apps/details?id=de.cyface.app&hl=de&gl=DE"
                  target="_blank"
                  rel="noreferrer">
                  <img src={ playStoreLogo } alt='Cyface im Google Play Store' width='250px' />
                </a><br /><br />
                <a style={{ color: '#3F8730' }}
                  href="https://apps.apple.com/de/app/cyface/id1456291958?l=de"
                  target="_blank"
                  rel="noreferrer">
                  <img src={ appStoreLogo } alt='Cyface im Apple App Store' width='250px' />
                </a>
              </center>
            </center>
          }
        </div>
      </Container>
      : ''
    )
  }

  return render()
}

const Container = styled.div`
  margin-top: 10px;
`

const Content = styled.div`
  width: 100%;
`

const Text = styled.div`
  float: left;
  margin-top: 6px;
`

const Icons = styled.div`
  height: 32.39px; /* fix alignment of spinning icon when only spinning icon is shown */
  float: right;
`

const DeletionJob = styled.div`
  transform: scale(0.5);
  float: right;
`

const Header = styled.span`
  font-size: 1rem;
`

const Badge = styled.span`
  color: #9e9e9e;
  float: right;
  font-size: 1rem;
`

/**
 * Validates props' types
 */
Datasets.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  modality: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  expanded: PropTypes.array.isRequired,
  addSelected: PropTypes.func.isRequired,
  removeSelected: PropTypes.func.isRequired,
  addExpanded: PropTypes.func.isRequired,
  removeExpanded: PropTypes.func.isRequired,
  visibleLayerId: PropTypes.string.isRequired
}

export default Datasets
