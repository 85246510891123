import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import logo from '../../resources/logo_small.png'
import Profile from './Profile'

/**
 * The top bar of the dashboard.
 */
const Header = ({ mobileView, logout }) => {
  /**
   * Defines the element injected into the container
   */
  return (
    <Container>
      <Left>
        <Logo
          src={logo}
          alt='Logo'
          mobileView={mobileView}
        />
        <div>Cyface</div>
      </Left>

      <Right>
        <Profile logout={logout} />
      </Right>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Left = styled.div`
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background-color: #FFFFFF;

  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 25px;
  color: #000000;

  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: lightgrey;

  line-height: 50px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  float: left;
  margin-right: 10px;
  margin-left: ${({ mobileView }) => (mobileView ? '71px' : '0px')};
  width: 47px;
  height: 47px;
`

const Right = styled.div`
  position: fixed; // relative to the viewport
  top: 18px;
  right: 18px;
  display: flex;
  align-items: center;
`

/**
 * Validates props' types
 */
Header.propTypes = {
  logout: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired
}

export default Header
