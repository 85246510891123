import { Modes, Pipelines } from './sidebar/datasets/DatasetsAnalysis'

/**
 * Commonly used functions helping with job execution process.
 *
 * @author Oguz Oztoprak
 */

export const Status = {
  Failed: 'FAILED',
  Finished: 'FINISHED',
  Unprocessable: 'UNPROCESSABLE',
  Unknown: 'UNKNOWN',
  Created: 'CREATED',
  Running: 'RUNNING',
  Canceled: 'CANCELED', // currently not expected
  Suspended: 'SUSPENDED', // currently not expected
  Rotated: 'ROTATED', // Status only used in web-app, to identifiy "UNPROCESSABLE due to rotation"
  Filtered: 'FILTERED' // Status only used in web-app to identify when all locations were filtered
}

/**
 * Checks if a status is considered "final" (i.e. not expected to change its state automatically).
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered final.
 */
export const isStatusFinal = (status) => {
  return (isStatusUnsuccessful(status) || isStatusSuccessful(status))
}

/**
 * Checks if a status is considered "non final" (i.e. expected to change its state automatically).
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered final.
 */
export const isStatusOngoing = (status) => {
  return status === Status.Created || status === Status.Running
}

/**
 * Checks if a status is considered "final" but not successful.
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered unsuccessful.
 */
export const isStatusSuccessful = (status) => {
  return (status === Status.Finished)
}

/**
 * Checks if a status is considered "final" but not successful.
 *
 * @param {*} status The status to check.
 * @returns `True` if the status is considered unsuccessful.
 */
export const isStatusUnsuccessful = (status) => {
  return (status === Status.Failed ||
    status === Status.Unprocessable ||
    status === Status.Unknown)
}

/**
 * Only returns the jobs which are not considered `final`, i.e. created/running jobs.
 *
 * @param {*} jobsIds The jobs to filter.
 * @returns The filtered jobs.
 */
export const filterOngoingJobs = (jobs) => {
  return jobs.filter(j => isStatusOngoing(j.status))
}

/**
   * Creates a timer with a specified delay.
   *
   * @params ms The delay duration in ms.
   * @returns A `Promise` that can be awaited for the delay.
   */
export const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
   * Filters the jobs so for each pid/mid combination, only the latest submitted jobs are returned.
   *
   * @params jobs The jobs to be filtered.
   * @returns The filtered jobs.
   */
export const filterLatestJobs = (jobs) => {
  const jobsByTypeArray = jobs.reduce((acc, job) => {
    if (job.pipeline === Pipelines.Surface && job.mode === Modes.Segment) {
      acc.segment.push(job)
    } else if (job.pipeline === Pipelines.Surface && job.mode === Modes.SegmentHistory) {
      acc.segmentHistory.push(job)
    } else if (job.pipeline === Pipelines.Surface && job.mode === Modes.H3) {
      acc.h3.push(job)
    } else if (job.pipeline === Pipelines.MapMatching) {
      acc.mapMatching.push(job)
    }
    return acc
  }, { segment: [], segmentHistory: [], h3: [], mapMatching: [] })

  const latestJobs = Object.values(jobsByTypeArray)
    .map(jobArray => jobArray.reduce((prev, next) => {
      return new Date(prev.created) > new Date(next.created) ? prev : next
    }, jobArray[0])).filter(job => job !== undefined)

  return latestJobs
}

/**
   * Find the job that was created the latest.
   *
   * @params jobs.
   * @returns The date of the latest processed job.
   */
export const latestProcessedJobDate = (jobs) => {
  const latestDateJob = jobs.reduce((prevJob, currentJob) => {
    const prevDate = new Date(prevJob.created)
    const currentDate = new Date(currentJob.created)
    return prevDate < currentDate ? currentJob : prevJob
  }, { created: new Date(0).toISOString() })
  return new Date(latestDateJob.created)
}
