/**
 * Filter which only shows the data of the segments orientated into the formal direction.
 */
export const Sources = {
  Datasets: 'DATASETS',
  H3: 'H3',
  H3Overview: 'H3Overview',
  Segment: 'SEGMENT',
  ForwardArrows: 'FORWARD_ARROWS',
  BackwardArrows: 'BACKWARD_ARROWS',
  Export: 'EXPORT'
}
