import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Views } from '../../constants/Views'
/**
 * This function component shows a legend on the map.
 */

const Legend = ({ ui, mobileView, zoomLevel }) => {
  const { view } = ui
  if (view !== Views.Infrastructure) {
    return ''
  }
  return zoomLevel > 10
    ? <LegendBox left={mobileView ? '0%' : '400px'} >
      <Header>Oberflächenbeschaffenheit</Header>
        <LineWrapper>
          <Line height='0.25em' color='blue' />
          <Description>Glatt</Description>
        </LineWrapper>
        <LineWrapper>
          <Line height='0.25em' color='green' />
          <Description>Normal</Description>
        </LineWrapper>
        <LineWrapper>
          <Line height='0.25em' color='orange' />
          <Description>Mäßig</Description>
        </LineWrapper>
        <LineWrapper>
          <Line height='0.25em' color='red' />
          <Description>Rau</Description>
        </LineWrapper>
      </LegendBox>
    : <LegendBox left={mobileView ? '0%' : '400px'} >
      <Header>Oberflächenbeschaffenheit</Header>
        <PointWrapper>
            <Point/>
            <Description>für Details heranzoomen</Description>
        </PointWrapper>
    </LegendBox>
}

const LegendBox = styled.div`
  position:absolute; // relative to parent
  width: 14.5em;
  height: 10em;
  margin-left: 10px;
  background-color: hsla(0,0%,100%,.75);
  left: ${props => props.left};
  bottom: 5em;
  z-index: 3; // one below SegmentDetails (4)
`

const Header = styled.div`
  padding:0.6em;
`

const Line = styled.div`
  height: ${props => props.height};
  width: 4em;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right: 1.5em;
`

const LineWrapper = styled.div`
  margin-left: 0.7em;
`

const Description = styled.div`
  display: inline-block;
  font-size: 0.9em;
`

const Point = styled.div`
  display: inline-block;
  border-radius: 50%;
  height: 1em;
  width: 1em;
  background-color: green;
  margin-left: 0.7em;
  margin-right: 0.7em;
`

const PointWrapper = styled.div`
  margin-left: 0.7em;
`

/**
 * Validates props' types
 */
Legend.propTypes = {
  ui: PropTypes.object.isRequired,
  mobileView: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired
}

export default Legend
