import React, { lazy, Suspense, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Tabs, Tab } from 'react-materialize'
import GenericIcon from '../sidebar/GenericIcon'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import { surfaceType, highwayType } from './OsmHelper'
import { Views } from '../constants/Views'
import {
  IMAGE_WIDTH_PIXEL, MOBILE_IMAGE_WIDTH_PIXEL, SMALL_MOBILE_IMAGE_WIDTH_PIXEL,
  Categories, streetDetailsName, streetConditionName
} from './SegmentDetailsConstants'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetSegmentHistoryQuery } from '../../api/apiSlice'
import { Modes } from '../sidebar/datasets/DatasetsAnalysis'
import ImageDetails from './ImageDetails'

// Lazy import PlotlyChart to speed-up initial loading of the page.
const Chart = lazy(() => import('./PlotlyChart'))

/**
 * Details of a segment shown in the sidebar when a segment is selected.
 *
 * @author Armin Schnabel
 */
const SegmentDetails = ({ map, logout, mobileView }) => {
  const ui = useSelector((state) => state.ui)
  const infrastructureView = useSelector((state) => state.infrastructureView)
  const [activeTab, setActiveTab] = useState(streetDetailsName)
  const { selectedFeature } = ui
  const { view } = ui
  const { mode } = infrastructureView
  const properties = featureProperties(map, selectedFeature)
  const featureId = selectedFeature.id
  const { data: history, error, isLoading, isError } = useGetSegmentHistoryQuery(properties, {
  // When the feature set is reloaded by the app, it can happen, that the selectedFeature
  // does not exist anymore, so we catch this softly. [DAT-1491]
    skip: properties === null || view !== Views.Infrastructure
  })

  const segmentDetails = (featureId, properties) => {
    if (featureId == null) {
      return '' // no segment selected
    }
    return (
      <DetailsContainer>
        <InfoContainer mobileView={mobileView}>
          <label>Ausgewähltes Straßensegment:</label>
          <Content>
            <div>
              Name: { properties.tags.name }
              <br />
              Identifikatoren: Weg <a
                href={'https://www.openstreetmap.org/way/' + properties.way}
                target='_blank' rel="noopener noreferrer">{ properties.way }
              </a>
              &nbsp;vNK <a href={'https://www.openstreetmap.org/node/' + properties.vnk}
              target='_blank' rel="noopener noreferrer">{ properties.vnk }
              </a>&nbsp;nNK&nbsp;<a
                href={'https://www.openstreetmap.org/node/' + properties.nnk}
                target='_blank' rel="noopener noreferrer">{ properties.nnk }
              </a>
              <GenericIcon
                icon="help_outline"
                transform="scale(0.8)"
                tooltip={'Von-Netzknoten-ID (vNK) - Nach-Netzknoten-ID (nNK), das entspricht der' +
                ' `Orientierungsrichtung` in die der Weg definiert ist'} />
              <br />
              Abschnitt: { Math.round(properties.way_offset) } -&nbsp;
                {Math.round(Number(properties.way_offset) + Number(properties.length)) }m, &nbsp;
              { (properties.forward_moving ? 'in ' : 'gegen ') } Orientierungsrichtung
              <GenericIcon
                icon="help_outline"
                transform="scale(0.8)"
                tooltip={'`In Orientierungsrichtung` bedeutet die Fahrtrichtung während der ' +
                'Aufzeichnung entspricht der Richtung Von-Netzknoten -> Nach-Netzknoten.'} />
              <br />
              Typ: {/* Straßenklasse: */ highwayType(properties.tags.highway)},&nbsp;
              {/* Baustoff: */ surfaceType(properties.tags.surface) }
              <GenericIcon
                icon="help_outline"
                transform="scale(0.8)"
                tooltip="Laut OpenStreetMap" />
              <br />
              Oberflächenqualität: { properties.quality === 0
                ? 'Rau'
                : properties.quality === 1
                  ? 'Mäßig'
                  : properties.quality === 2
                    ? 'Normal'
                    : properties.quality === 3 ? 'Glatt' : 'Zuordnungsfehler' }
              <br />
              <Grey>Support-ID: {properties.oid}</Grey>
              <br />
            </div>
          </Content>
        </InfoContainer>

      {// Images are not shown on small screens
      }
       {window.innerWidth > SMALL_MOBILE_IMAGE_WIDTH_PIXEL
         ? <ImageDetails mobileView={mobileView} featureId={featureId}
                    view={view} properties={properties} />
         : '' }
      </DetailsContainer>
    )
  }

  if (isError) {
    defaultErrorHandling(error, logout)
  }

  const onTabClick = (e) => {
    if (e.target.text !== activeTab) {
      setActiveTab(e.target.text)
    }
  }

  const tabsContainer = (featureId, properties, history, view) => {
    if (history === null || view !== Views.Infrastructure || mode !== Modes.Segment) {
      return ''
    }
    const isStreetDetailsActive = activeTab === streetDetailsName
    const isStreetConditionActive = activeTab === streetConditionName
    return (history.features.length !== 0
      ? <TabsContainer>
          <Tabs
            onChange = {onTabClick}
            className="tab-demo z-depth-1 tabs-fixed-width">
            <Tab active={isStreetDetailsActive} title={streetDetailsName}>
              { isStreetDetailsActive ? segmentDetails(featureId, properties) : ''}
            </Tab>
            <Tab active={isStreetConditionActive} title={streetConditionName}>
              { isStreetConditionActive ? chart(featureId, history) : '' }
            </Tab>
          </Tabs>
      </TabsContainer>
      : <TabsContainer>
          <Tabs className="tab-demo z-depth-1 tabs-fixed-width">
            <Tab active title={streetDetailsName}>
              { segmentDetails(featureId, properties)}
            </Tab>
          </Tabs>
      </TabsContainer>
    )
  }

  /**
   * The element injected into the container
   */
  return properties !== null
    ? isLoading
      ? renderSkeleton()
      : tabsContainer(featureId, properties, history, view)
    : ''
}
/**
   * Shows details about the selected (or hovered) Segment.
   *
   * @param {*} featureId TODO
   * @param {*} properties TODO
   */
/**
   * Shows a container which contains the image data of a segment.
   *
   * @param {*} featureId TODO
   * @param {*} properties TODO
   */

const renderSkeleton = () => <Skeleton height={IMAGE_WIDTH_PIXEL - 50}/>

/**
   * Shows a container with the historical segment conditions.
   *
   * @param {*} featureId TODO
   * @param {*} history TODO
   */
const chart = (featureId, history) => {
  return (
      <center style={{ padding: '10px 10px 10px 10px' }}>
        <Suspense fallback={renderSkeleton()}>
          <Chart data={chartData(featureId, history)} />
        </Suspense>
      </center>
  )
}

/**
   * Returns the data for a selected feature which is required to fill the chart.
   *
   * @param {*} featureId TODO
   * @param {*} history TODO
   */
const chartData = (featureId, history) => {
  if (featureId == null) {
    return [] // no segment selected
  }

  const x = []
  const y = []
  history.features.forEach(f => {
    const properties = f.properties
    const quality = properties.quality
    const value = quality >= 2.5
      ? Categories.SMOOTH // 2.5 - 3.0
      : quality >= 1.5
        ? Categories.NORMAL
        : quality >= 0.5
          ? Categories.MODERATE
          : Categories.ROUGH // 0.0 - 0.5
    const date = new Date(properties.timestamp * 1000)
    x.push(date.toISOString())
    y.push(value)
  })
  const data = [
    {
      x,
      y,
      type: 'scatter'
      // this only sets static color for the first, second, etc. marker (wrong)
      // marker: { color: [Colors.RED, Colors.YELLOW, Colors.GREEN, Colors.BLUE] }
    }
  ]
  return data
}
/**
  * Loads the properties of a feature.
  *
  * @param {object} map to load the feature from
  * @param {object} feature to show properties for
  * @return the properties of the feature or null if no feature is selected, or when the feature
  * cannot be found anymore (e.g. when the dataset is reloaded from the API).
  */
const featureProperties = (map, feature) => {
  if (feature.id === null) {
    return null
  }

  const mapSource = map.getSource(feature.sourceId)
  const mapFeatures = mapSource._data.features
  const matchedFeatures = mapFeatures.filter(f => f.id === feature.id)
  return matchedFeatures.length === 1 ? matchedFeatures[0].properties : null
}

const TabsContainer = styled.div`
  min-width: 350px;
  padding: 0px 0px 0px 0px;
`

const DetailsContainer = styled.div`
  width: 100%;
  padding: 20px 0px 10px 0px;
`

const InfoContainer = styled.div`
  vertical-align: top;
  width: ${props => props.mobileView
? `calc(100% - ${MOBILE_IMAGE_WIDTH_PIXEL}px - 15px);`
      : `calc(100% - ${IMAGE_WIDTH_PIXEL}px - 50px);`}
  display: inline-block;
  padding: 0px 10px 0px 10px;
`

const Content = styled.div`
  padding: 5px 0px 0px 10px;
  line-height: 2;
`

const Grey = styled.span`
  color: grey;
`
export default SegmentDetails
