import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'react-materialize'

/**
 * A small '(?)' icon which can be used to show a quick-help tooltip e.g. next to a text.
 * <p>
 * To show a tooltip for a button just use the `tooltip` parameter of the `ControlButton` component.
 *
 * @author Oguz Oztoprak
 */
// stateless
const GenericIcon = ({
  tooltip, position, right, color, icon, left, top, bottom,
  transform, margin, float, padding
}) => {
  /**
   * The element injected into the container
   */
  return (
    <Button
      style={{
        padding: padding !== undefined ? padding : '0px 0px 0px 0px',
        margin: margin !== undefined ? margin : '-7px 0px 0px 3px',
        backgroundColor: 'transparent',
        color: color !== undefined ? color : '#c1c1c1',
        transform: transform !== undefined ? transform : 'scale(1.0)',
        position: position !== undefined ? position : 'static',
        right: right !== undefined ? right : 'auto',
        left: left !== undefined ? left : 'auto',
        top: top !== undefined ? top : 'auto',
        bottom: bottom !== undefined ? bottom : 'auto',
        float: float !== undefined ? float : 'none'
      }}
      node="button"
      waves="light"
      flat
      small
      icon={<Icon>{ icon }</Icon>}
      tooltip={ tooltip }
      tooltipOptions={{ enterDelay: 200 }} />
  )
}

/**
 * Validates props' types
 */
GenericIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  position: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  transform: PropTypes.string,
  margin: PropTypes.string,
  float: PropTypes.string,
  padding: PropTypes.string
}

export default GenericIcon
