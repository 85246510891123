import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import ControlButton from '../../ControlButton'
import { Button, Modal } from 'react-materialize'
import { States } from '../../../reducers/datasetsView'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { selectMeasurementsById } from '../../../reducers/measurements'
import { deleteMeasurement } from '../../DataApi'

const Container = styled.div``

/**
 * This component handles the removal of datasets from the UI and requests deletion from the db.
 *
 * @author Oguz Oztoprak
 */
const DatasetsRemoval = ({ logout, active, setActive, selected, clearSelected }) => {
  /**
   * Used to dispatch calls to redux store.
   */
  const dispatch = useDispatch()

  const measurementsById = useSelector(state => id => selectMeasurementsById(state, id))

  /**
   * Handler which is called when user confirms deletion of the selected datasets.
   *
   * @param {*} e the corresponding event
   */
  const onClickDeleteFinal = async () => {
    setActive(States.DeleteDatasets)
    await Promise.all(selected.map(async selectedId => {
      const dataset = measurementsById(selectedId)
      await deleteMeasurement(dispatch, defaultErrorHandling, logout, dataset)
    }))
    clearSelected()
    setActive(States.ShowDatasets)
  }

  /**
   * Handler which is called when the dataset removal mode is exited.
   *
   * @param {*} e the corresponding event
   */
  const onClickClose = (e) => {
    e.preventDefault()
    setActive(States.ShowDatasets)
    clearSelected()
  }

  /**
   * The container injected into the DOM.
   */
  return (
    <Container>
      <div style={{ display: active !== States.ShowDatasets ? 'none' : 'inline' }}>
        <ControlButton
          icon="playlist_remove"
          margin='0px 0px 0px 0px'
          onClick={() => setActive(States.SelectRemoveDatasets)}
          text="Datensätze Löschen" />
        <br />
      </div>
      { /* Choose analysis modes buttons */ }
      { active === States.ChooseRemovalMode || active === States.SelectRemoveDatasets
        ? (
        <div>
          <Modal
            actions={[
              <div key='confirmationBlock'>
                <Button
                  style={{
                    bottom: '20px',
                    position: 'absolute',
                    right: '130px',
                    backgroundColor: '#999999'
                  }}
                  small
                  onClick={onClickClose}
                  node="button" waves="light">
                  Abbrechen
                </Button>

                <Button
                  style={{
                    bottom: '20px',
                    position: 'absolute',
                    right: '10px',
                    backgroundColor: '#FF2600'
                  }}
                  small
                  onClick={onClickDeleteFinal}
                  modal="close" node="button" waves="green" >
                  bestätigen
                </Button>
              </div>
            ]}
            header="Endgültig Löschen?"
            style={{ width: '380px', height: '180px' }}
            trigger={
              <ControlButton
                margin='0px 5px 0px 0px'
                backgroundColor='#FF2600'
                text="Endgültig Löschen"
                disabled={selected.length === 0}/>
            }
          />
          <ControlButton
            margin='0px 5px 0px 0px'
            backgroundColor='#999999'
            text="Abbrechen"
            onClick={onClickClose}/>
        </div>)
        : '' }
    </Container>
  )
}

/**
 * Validates props' types
 */
DatasetsRemoval.propTypes = {
  logout: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  clearSelected: PropTypes.func.isRequired
}

export default DatasetsRemoval
