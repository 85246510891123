import React from 'react'
import PropTypes from 'prop-types'
import { CollectionItem, Checkbox } from 'react-materialize'
import './CustomCollectionItem.css'

/**
 * This component represents an item in a list of collection. Designed to have different
 * behaviour in dataset analysis mode.
 *
 * @author Oguz Oztoprak
 * @author Armin Schnabel
 */
const CustomCollectionItem = ({
  active, selected, selectable, onClick, item, text, checkable,
  addSelected, removeSelected, addTagsToMeasurements, disabled = false
}) => {
  /**
   * Handler which is called when an this collection item was marked or unmarked.
   *
   * @param {*} e The click event.
   */
  const onChangeCheckBox = (e, prevChecked) => {
    e.preventDefault()
    if (selectable(item)) {
      if (prevChecked) {
        removeSelected(item.id)
      } else {
        addSelected(item.id)
      }
    }
  }

  const green = '#3F8730'

  const onDrop = async (e) => {
    e.preventDefault()
    const tag = JSON.parse(e.dataTransfer.getData('text'))
    await addTagsToMeasurements(item, tag)
  }

  const onDragIgnore = (e) => {
    e.preventDefault()
  }

  const checked = checkable && selected
  return (
    <CollectionItem active={active}
      href='#'
      onClick={checkable
        ? (e) => onChangeCheckBox(e, checked)
        : disabled
          ? () => {}
          : (e) => onClick(e, item) }
      style= {{
        display: 'flex',
        color: active ? 'white' : 'rgb(70, 70, 70)',
        backgroundColor: active ? green : 'white',
        cursor: disabled ? 'default' : 'pointer'
      } }
      draggable='false'
      onDragOver={onDragIgnore}
      onDrop={onDrop}
      onDragEnter={onDragIgnore}
      onDragLeave={onDragIgnore} >

      <div style={{
        display: checkable
          ? 'inline'
          : 'none',
        marginTop: '5px',
        marginBottom: '-5px'
      }}>
        {/* React-materialize Checkbox component has no clear api to change the color of
        the check mark, manual css is used instead. */}
        <Checkbox
          className='cyface-color-checkbox'
          label=''
          value=''
          onChange={onChangeCheckBox}
          checked={checked} />
      </div>

      {text}

    </CollectionItem>
  )
}

/**
 * Validates props' types
 */
CustomCollectionItem.propTypes = {
  active: PropTypes.bool.isRequired,
  checkable: PropTypes.bool.isRequired,
  selectable: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.object,
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  addSelected: PropTypes.func.isRequired,
  removeSelected: PropTypes.func.isRequired,
  addTagsToMeasurements: PropTypes.func.isRequired
}

export default CustomCollectionItem
