import { combineReducers } from '@reduxjs/toolkit'
import ui from './ui.js'
import h3 from './h3.js'
import measurements from './measurements.js'
import jobs from './jobs.js'
import segments from './segments.js'
import datasetsView from './datasetsView.js'
import infrastructureView from './infrastructureView.js'
import exportView from './exportView.js'
import { apiSlice } from '../api/apiSlice'

/**
 * This file contains the handler for the entire app state.
 *
 * The reducers are structured like recommended here:
 * https://redux.js.org/usage/structuring-reducers/refactoring-reducer-example
 *
 * The state is not too deeply nested as recommended:
 * https://stackoverflow.com/a/51136076/5815054
 *
 * @author Armin Schnabel
 */

/**
 * The handler for the entire app state.
 *
 * Accepts actions and executes reducers based on the action type, which alter the Redux state.
 */
const rootReducer = combineReducers({
  // Domain data (data returned by the API)
  h3,
  segments,
  measurements,
  jobs,

  // App state
  datasetsView,
  infrastructureView,
  exportView,

  // UI state
  ui,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export default rootReducer
