import { Modalities } from '../components/sidebar/Modality.js'
import { addToArray, removeFromArray, updateObject } from './utils.js'
import { createSlice } from '@reduxjs/toolkit'

/**
 * Reducer code specific to the `datasetsView`.
 *
 * @author Armin Schnabel
 */

/**
 * The possible active states which indicates how the user can currently interact with the UI.
 */
export const States = {
  ShowDatasets: 'SHOW_DATASETS',
  DeleteDatasets: 'DELETE_DATASETS',

  ChooseAnalyzeMode: 'CHOOSE_ANALYZE_MODE',

  SelectAnalyzeDatasets: 'SELECT_ANALYZE_DATASETS',
  SelectRemoveDatasets: 'SELECT_REMOVE_DATASETS',

  SubmittingAnalyzeJobs: 'SUBMITTING_ANALYZE_JOBS',
  AddRemoveTagsFromMeasurements: 'ADD_REMOVE_TAGS_FROM_MEASUREMENTS',
  AddRemoveAvailableTags: 'ADD_REMOVE_AVAILABLE_TAGS',
  RemoveAvailableTags: 'REMOVE_AVAILABLE_TAGS',
  AddAvailableTags: 'ADD_AVAILABLE_TAGS'
}

/**
 * The current state of this part of the app.
 *
 * e.g. which data, elements and filters are selected.
 */
const initialState = {
  // Indicates how the user can currently interact with the UI
  active: States.ShowDatasets,

  // The "modality" filter selected in "datasetsView"
  modality: Modalities.All,

  // The "tags" filter selected in "datasetsView"
  tags: {
    activeTags: [],
    availableTags: []
  },

  // The "time" filter selected in "datasetsView"
  time: {
    active: false,
    from: null,
    to: null
  },

  // The ids of the datasets currently selected by the user
  selected: [],

  datasetLoading: false,

  // The usernames of the users currently expanded (in multi-user view)
  expanded: []
}

const datasetsViewSlice = createSlice({
  name: 'datasetsView',
  initialState,
  reducers: {
    updateDatasetsView: (state, action) => {
      return updateObject(state, action.payload)
    },
    selectDataset: (state, action) => {
      const selected = addToArray(state.selected, action.payload)
      return updateObject(state, { selected })
    },
    unselectDataset: (state, action) => {
      const selected = removeFromArray(state.selected, action.payload)
      return updateObject(state, { selected })
    },
    addExpanded: (state, action) => {
      const expanded = addToArray(state.expanded, action.payload)
      return updateObject(state, { expanded })
    },
    removeExpanded: (state, action) => {
      const expanded = removeFromArray(state.expanded, action.payload)
      return updateObject(state, { expanded })
    },
    addActiveTag: (state, action) => {
      const activeTags = addToArray(state.tags.activeTags, action.payload)
      const tags = updateObject(state.tags, { activeTags })
      return updateObject(state, { tags })
    }
  }
})

export const {
  updateDatasetsView,
  selectDataset,
  unselectDataset,
  addExpanded,
  removeExpanded,
  addActiveTag
} = datasetsViewSlice.actions

export default datasetsViewSlice.reducer
