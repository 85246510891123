import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Tabs, Tab } from 'react-materialize'
import { Views } from '../constants/Views'
import { States } from '../../reducers/datasetsView'
import { selectMeasurementsById } from '../../reducers/measurements'

/**
 * Details of a dataset is shown by DatasetDetails functional component
 * when a dataset is selected on the sidebar.
 *
 * @author Oguz Oztoprak
 */

/**
 * Human readable size format
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * SI and non-SI definitions from https://physics.nist.gov/cuu/Units/binary.html & https://en.wikipedia.org/wiki/Byte
 */
const formatBytes = (bytes, decimals = 1, isSI = true) => {
  if (!+bytes) return '0 Bytes'

  const k = isSI ? 1000 : 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = isSI
    ? ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Human readable date format
 */
const formattedUploadDate = (uploadDate) => {
  return new Date(uploadDate).toLocaleDateString(
    'de-DE', { day: '2-digit', year: 'numeric', month: '2-digit' })
}

/**
   * Generates a text which represents the measurement duration.
   *
   * @param {*} dataset The dataset to generate the text for.
   * @returns The generated text.
   */
const showMeasuredDuration = (dataset) => {
  const startDate = new Date(dataset.startTimestamp)
  const endDate = new Date(dataset.endTimestamp)
  const uploadDate = new Date(dataset.uploadDate)
  const durationInHours = (endDate.getTime() - startDate.getTime()) / (36e5)
  // When no GNSS data was collected
  if (dataset.startTimestamp === undefined) {
    return ('Uploaded: ' + uploadDate.toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }))
  } else if (startDate < endDate) {
    // A legal duration
    const startDateString = startDate.toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
    // Expected duration of a measurement is no longer than 24 hours
    if (durationInHours < 24) {
      return (startDateString + ' - ' +
        endDate.toLocaleString('de-DE', {
          hour: '2-digit',
          minute: '2-digit'
        })
      )
    // In general, this shouldn't be the case, it is the fallback case.
    } else {
      return (startDateString + ' - ' + endDate.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
      )
    }
  }
}

/**
   * Decides if the DatasetDetails should be rendered.
   *
   * @param {*} measurement The measurement to show the details.
   * @param {*} view The view of the UI.
   * @param {*} activeDatasetsView The current active state of the datasetsView.
   * @returns A bool to indicate whether datasetdetails are active or not.
   */
const isActive = (measurement, view, activeDatasetsView) => {
  return measurement !== undefined &&
        view === Views.Datasets &&
        activeDatasetsView === States.ShowDatasets
}

const DatasetDetails = () => {
  const ui = useSelector((state) => state.ui)
  const datasetsView = useSelector((state) => state.datasetsView)
  const selected = datasetsView.selected
  const measurement = useSelector((state) => selectMeasurementsById(state, selected[0]))
  return isActive(measurement, ui.view, datasetsView.active)
    ? <TabsContainer>
            <Tabs className="tab-demo z-depth-1 tabs-fixed-width">
              <Tab active title='Infos'>
                <DetailsContainer>
                  <InfoContainer>
                    <label>Ausgewählte Messung&nbsp;
                      <Small>ID: { measurement.id }</Small>
                    </label>
                     <Content>
                       <div>
                        Aufgezeichnet: {showMeasuredDuration(measurement)} Uhr
                        <br />
                         Länge: {Math.round((Math.round(measurement.length / 10) / 10)) / 10} km
                        <br />
                         Größe: {formatBytes(measurement.size)}
                        <br />
                          Hochgeladen am: {formattedUploadDate(measurement.uploadDate)}
                        <br />
                          Messgerät: &nbsp;
                            {measurement.deviceType} ({measurement.osVersion}),&nbsp;
                            {measurement.appVersion} (V{measurement.formatVersion})
                        <br />
                          <Grey>Support-ID: {measurement.oid}</Grey>
                       </div>
                     </Content>
                  </InfoContainer>
                </DetailsContainer>
              </Tab>
            </Tabs>
         </TabsContainer>

    : ''
}

const TabsContainer = styled.div`
  min-width: 350px;
  padding: 0px 0px 0px 0px;
`

const DetailsContainer = styled.div`
  width: 100%;
  padding: 15px 0px 10px 0px;
`

const InfoContainer = styled.div`
  vertical-align: top;
  width: 100%;
  display: inline-block;
  padding: 0px 5px 0px 10px;
`

const Content = styled.div`
  padding: 5px 0px 0px 10px;
  line-height: 2;
`

const Grey = styled.span`
  color: grey;
`

const Small = styled.span`
  font-size: x-small;
`

export default DatasetDetails
